/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Section1 = () => {
  return (
    <div className='py-20'>
      <div className='md:flex  justify-center space-x-16 md:mr-0 mr-10'>
        <div className='md:flex items-center  '>
          <div className='text-center'>
            <div className='flex gap-1 flex-wrap items-center '>
              <a
                target='_blank'
                rel='noreferrer'
                href='https://pancakeswap.finance/swap?outputCurrency=0x652000ba3c230d83279AEC84A49d41d706AFB0F1'
                className='flex btns max-w-xs items-center  font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out  relative'>
                <div className='flex gap-3 justify-center items-center'>
                  <span>
                    <img
                      src='assets/cake.svg'
                      className='w-8 h-8 img-fluid'
                      alt=''
                    />
                  </span>
                  <span className='text-xl'>Trade on PCS</span>
                </div>
              </a>
              <a
                target='_blank'
                rel='noreferrer'
                className='flex  max-w-xs gap-3 items-center font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out  relative'
                href='https://stake.y24.io/'>
                <span>
                  <img
                    src='assets/logo2.png'
                    className='w-8 h-8 img-fluid'
                    alt=''
                  />
                </span>
                <span className='text-xl'>Stake Y24</span>
              </a>
              <Link
                to='/airdrop'
                className='flex mt-10 max-w-sm items-center gap-2 text-lg text-md font-para font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                Y24 Yearning System
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5 rotate-180'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M11 19l-7-7 7-7m8 14l-7-7 7-7'
                  />
                </svg>
              </Link>
            </div>

            <div className='mt-5 flex gap-5 justify-center items-center flex-wrap'>
              <a
                href='https://www.pinksale.finance/launchpad/0x1682d7009f7d09bec51f0b79f5a7cda5025fe2a4?chain=BSC'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  src='assets/pinksale-1.svg'
                  className='w-48 img-fluid'
                  alt=''
                />
              </a>
              <a
                href='https://www.dexview.com/bsc/0x652000ba3c230d83279AEC84A49d41d706AFB0F1'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  src='assets/dexview.svg'
                  className='w-48 img-fluid'
                  alt=''
                />
              </a>
              <a
                href='https://pancakeswap.finance/swap?outputCurrency=0x652000ba3c230d83279AEC84A49d41d706AFB0F1'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  src='assets/full-pancakeswap-logo.svg'
                  className='w-48 img-fluid'
                  alt=''
                />
              </a>
              <a
                href='https://www.coingecko.com/en/coins/yield-24'
                target='_blank'
                rel='noopener noreferrer'>
                <img
                  src='assets/coingecko.svg'
                  className='w-48 img-fluid'
                  alt=''
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-5 px-3 md:px-0'>
        <h4 className=' font-semibold text-sm sm:text-xl text-center max-w-4xl m-auto font-para '>
          Y24 allows to optimise on chain assets such as BNB, ETH, BTC, and
          stable coins at the consensus layer and provide LRT native yield
          tokens that can be used in various defi applications, including RWA’s,
          securitization, trenches, staking and lending/borrowing protocols.
        </h4>
      </div>
      {/* <div className=''>
        <video
          className='h-full max-w-4xl m-auto mt-5 rounded-lg'
          controls={false}
          autoPlay
          loop
          playsInline
          muted>
          <source src='https://imgur.com/CuSrYqF.mp4' type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </div> */}
    </div>
  );
};

export default Section1;
