/** @format */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDDVnkSyq7nh6IdFjHMJKsZamtkCc2V-ZU",
  authDomain: "yield24site.firebaseapp.com",
  projectId: "yield24site",
  storageBucket: "yield24site.appspot.com",
  messagingSenderId: "160470927918",
  appId: "1:160470927918:web:aec792e166333641406dbf",
  measurementId: "G-NZZ6FPSWCF"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
