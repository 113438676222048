/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  getAuth,
  onAuthStateChanged,
  TwitterAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { RiCoinsFill } from "react-icons/ri";
import { ImSpinner11 } from "react-icons/im";
import { FaXTwitter } from "react-icons/fa6";
import { toast } from "react-toastify";
import { firebase } from "../components/utils/Firebase";
import { AiOutlineRight, AiTwotoneLock } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { RiLogoutCircleRFill } from "react-icons/ri";
import CountUp from "react-countup";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Timer5 from "../components/utils/Timer5";
import Timer1 from "../components/utils/Timer1";
import useBalance from "../components/Contracts/useBalance";
import useAllowance from "../components/Contracts/useAllowance";
import numbro from "numbro";
import { utils } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useSwitchNetwork, useEnsName, useNetwork } from "wagmi";
import Web3 from "web3";
import { ErcABI, ErcContractAddress } from "../components/Contracts/Abi";
import { DaiABI, DaiContractAddress } from "../components/Contracts/DAI";
import {
  DaiEarningABI,
  DaiEarningContractAddress,
} from "../components/Contracts/DAIEarning";
import {
  ETHEarningABI,
  ETHEarningContractAddress,
} from "../components/Contracts/ETHEarning";
import Faqs from "../components/utils/FaqsEarning";

const FarmingNew = () => {
  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text successfully copied to clipboard:", text);
      })
      .catch(err => {
        console.error("Unable to copy text to clipboard:", err);
      });
  }

  const useArrayRefs = length => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return Array.from({ length }, () => useRef(null));
  };
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { open, close } = useWeb3Modal();
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");
  const [twitter_id, setTwitterId] = useState("");
  const [bnbAmount, setBnbAmount] = useState(null);
  const [daiAmount, setDaiAmount] = useState(null);
  const [ETHAmount, setETHAmount] = useState(null);
  const [btn, setBtn] = useState(false);
  const [balance, setBalance] = useState(null);

  const getFullDisplayBalance = (balance, format = {}) => {
    return numbro(utils.formatEther(balance)).format(Object.assign({}, format));
  };

  const bal = () => {
    if (address) {
      web3.eth.getBalance(address).then(balance => {
        setBalance(getFullDisplayBalance(balance));
      });
    }
  };

  bal();
  const fromWei = (value, unit = "ether") => {
    return web3.utils.fromWei(value.toString(), unit);
  };

  // console.log(bnbAmount);
  function purchaseSuccessful() {
    setBtn(false);
    toast.success("You have successfully Deposit!");
    setBnbAmount("");
    setDaiAmount("");
  }
  const [balanceDai] = useBalance(
    "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
    18,
  );
  function calcPerc(amount) {
    setBnbAmount((balance / 100) * amount);
  }
  function calcPercDai(amount) {
    setDaiAmount((balanceDai / 100) * amount);
  }
  function purchaseFailed() {
    setBtn(false);
    toast.error("Unable to handle your request, try again!");
  }
  function truncate(str, n) {
    return str.length > n ? str.slice(0, 4) + "..." + str.slice(-4) : str;
  }
  const [selected, setSelected] = useState(1);
  const handleButton = buttonId => {
    setSelected(buttonId);
  };
  const handleDeposit = async () => {
    try {
      console.log(bnbAmount, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      linkWallet();
      if (
        !bnbAmount ||
        isNaN(bnbAmount) ||
        bnbAmount < 0.1 ||
        bnbAmount > 0.1
      ) {
        setBtn(false);
        return toast.error("Amount should be a valid number  0.1 BNB");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();
      console.log("gas ===>", rpcGasPrice);
      const contract = new web3.eth.Contract(ErcABI, ErcContractAddress);
      const invest = await contract.methods.deposit().send({
        from: address,
        value: web3.utils.toWei(String(bnbAmount), "ether"),
        // gas:rpcGasPrice,
        gasPrice: rpcGasPrice,
      });

      if (invest) {
        console.log(invest?.transactionHash);
        const depositHash = invest?.transactionHash;
        const depositAmount = Number(bnbAmount);
        if (twitter_id) {
          axios
            .put("https://yield24api.onrender.com/api/deposit", {
              twitter_id,
              depositAmount,
              depositHash,
            })
            .then(res => {
              console.log(res?.data?.message);
              setScore(res?.data?.data);
              // handleClose();
              // handleClose2();
              // handleClose1();
              // handleClose3();
              purchaseSuccessful();
            })
            .catch(error => {
              setBtn(false);
              purchaseFailed();
              console.log(error);
            });
        }
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };
  const handleDepositDai = async () => {
    try {
      console.log(daiAmount, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      linkWallet();
      if (!daiAmount || isNaN(daiAmount) || daiAmount < 32 || daiAmount > 32) {
        setBtn(false);
        return toast.error("Amount should be a valid number  32 DAI");
      }
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();

      const contract = new web3.eth.Contract(
        DaiEarningABI,
        DaiEarningContractAddress,
      );
      const invest = await contract.methods
        .deposit(web3.utils.toWei(String(daiAmount), "ether"))
        .send({
          from: address,
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });
      if (invest) {
        console.log(invest?.transactionHash);
        const depositHashDAI = invest?.transactionHash;
        const depositAmountDAI = Number(daiAmount);
        if (twitter_id) {
          axios
            .put("https://yield24api.onrender.com/api/depositDAI", {
              twitter_id,
              depositAmountDAI,
              depositHashDAI,
            })
            .then(res => {
              console.log(res?.data?.message);
              setScore(res?.data?.data);
              // handleClose();
              // handleClose2();
              // handleClose1();
              // handleClose3();
              purchaseSuccessful();
            })
            .catch(error => {
              setBtn(false);
              purchaseFailed();
              console.log(error);
            });
        }
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };
  const handleDepositETH = async () => {
    try {
      console.log(ETHAmount, address);
      if (!address) {
        setBtn(false);
        return toast.info(
          "Unable to detect any wallet, please connect Metamask or Trust Wallet",
        );
      }
      linkWallet();
      if (
        !ETHAmount ||
        isNaN(ETHAmount) ||
        ETHAmount < 0.01 ||
        ETHAmount > 0.01
      ) {
        setBtn(false);
        return toast.error("Amount should be a valid number  0.01 ETH");
      }
      setBtn(true);
      const contract = new web3.eth.Contract(
        ETHEarningABI,
        ETHEarningContractAddress,
      );
      const invest = await contract.methods.deposit().send({
        from: address,
        value: web3.utils.toWei(String(ETHAmount), "ether"),
        // gas: 1000000,
        // gasPrice: web3.utils.toWei("10", "gwei"),
      });
      if (invest) {
        console.log(invest?.transactionHash);
        const depositHashETH = invest?.transactionHash;
        const depositAmountETH = Number(ETHAmount);
        if (twitter_id) {
          axios
            .put("https://yield24api.onrender.com/api/depositETH", {
              twitter_id,
              depositAmountETH,
              depositHashETH,
            })
            .then(res => {
              console.log(res?.data?.message);
              setScore(res?.data?.data);
              // handleClose();
              // handleClose2();
              // handleClose1();
              // handleClose3();
              purchaseSuccessful();
            })
            .catch(error => {
              setBtn(false);
              purchaseFailed();
              console.log(error);
            });
        }
      }
    } catch (error) {
      console.error("Error during deposit:", error);
      setBtn(false);
      purchaseFailed();
    }
  };
  let allowanceWeb3 = useAllowance(
    DaiContractAddress,
    DaiEarningContractAddress,
  );
  const [allowance, setAllowance] = useState("0");
  const MAX_UINT =
    "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  //                             approve
  // ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
  useEffect(() => {
    setAllowance(allowanceWeb3);
  }, [allowanceWeb3]);

  const approve = async () => {
    try {
      setBtn(true);
      const rpcGasPrice = await web3.eth.getGasPrice();
      const contract = new web3.eth.Contract(DaiABI, DaiContractAddress);
      const approvalTransaction = await contract?.methods
        .approve(DaiEarningContractAddress, MAX_UINT)
        .send({
          from: address,
          value: web3.utils.toWei("0", "ether"),
          // gas: 1000000,
          gasPrice: rpcGasPrice,
        });
      if (approvalTransaction) {
        toast.success("Approval successful");
        console.log("Approval==>", approvalTransaction);
        setAllowance(MAX_UINT);
        setBtn(false);
      } else {
        toast.error("Approval transaction failed");
      }
    } catch (error) {
      console.error("Error during approval:", error);
      purchaseFailed();
      setBtn(false);
    }
  };

  const auth = getAuth(firebase);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);

  const [loading, setLoading] = useState(false);
  const [rewardBtn, setRewardBtn] = useState(true);
  const [user, setUser] = useState(null);
  const [userID, setUserID] = useState(false);

  const [score, setScore] = useState(null);
  const handleClose = () => setShowModal(false);
  const handleClose1 = () => setShowModal1(false);
  const handleClose2 = () => setShowModal2(false);
  const handleClose3 = () => setShowModal3(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const getRef = () => {
    const ref = query.get("ref");
    return ref;
  };
  const ref = getRef();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async authUser => {
      if (authUser) {
        setTwitterId(authUser?.uid);
        setUserID(true);
        const userName = authUser?.displayName;
        const imgUrl = authUser?.photoURL;
        if (twitter_id) {
          try {
            const getScoreResponse = await axios.post(
              "https://yield24api.onrender.com/api/linkx",
              { twitter_id, userName, imgUrl },
            );
            console.log(getScoreResponse?.data?.message);
            setScore(getScoreResponse?.data?.data);
            referred();
            if (!score?.codeAdded) {
              const secondApiResponse = await axios.put(
                "https://yield24api.onrender.com/api/add",
                { twitter_id },
              );
            }
            referred();
            setLoading(false);
            const getData = await axios.post(
              "https://yield24api.onrender.com/api/getScore",
              {
                twitter_id,
              },
            );
            setScore(getData?.data?.data);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.log(error);
          }
        }
        setUser(authUser);
      } else {
        // User is signed out.
        setLoading(false);
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth, twitter_id]);

  const twitterLogin = async () => {
    try {
      setLoading(true);
      const provider = new TwitterAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const credentials = TwitterAuthProvider.credentialFromResult(result);
      const token = credentials.accessToken;
      const secret = credentials.secret;
      const userLogin = result.user;
      setTwitterId(userLogin?.uid);
      axios.post("https://yield24api.onrender.com/api/getScore", {
        twitter_id,
      });
      if (twitter_id !== "") {
        console.log("user Login Id", twitter_id);
        const userName = userLogin?.displayName;
        const imgUrl = userLogin?.photoURL;
        try {
          const linkApiResponse = await axios.post(
            "https://yield24api.onrender.com/api/linkx",
            {
              twitter_id,
              userName,
              imgUrl,
            },
          );
          console.log("linlx----", linkApiResponse?.data?.message);
          setScore(linkApiResponse?.data?.data);
          axios.post("https://yield24api.onrender.com/api/getScore", {
            twitter_id,
          });
          // // First API call to store newCode

          //   const newCodeResponse = await axios.post(
          //     "https://yield24api.onrender.com/api/generate-new-code",
          //   );
          //   const storedCode = newCodeResponse?.data.newCode;
          //   console.log("New code generated and stored:", storedCode);
          //   setNewCode(storedCode);

          // // const code = newCode;
          // // if (twitter_id && code !== "") {
          // //   const secondApiResponse = await axios.put(
          // //     "https://yield24api.onrender.com/api/add",
          // //     {
          // //       twitter_id,
          // //       code,
          // //     },
          // //   );
          // //   console.log("Second API response:", secondApiResponse?.data);
          // // }

          // // setLoading(false);
          // // if (ref !== "" && twitter_id !== "") {
          // //   referred();
          // // } else {
          // //   console.log("ref not get and tw id");
          // // }
        } catch (error) {
          setLoading(false);
          console.log(error);
        }

        return;
      }

      // Continue with the rest of your code
      setUser(userLogin);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  const Logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
      });
  };

  const [data, setData] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Link: "",
    btn2: "",
    btn2Fun: null,
  });
  const [data1, setData1] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Fun: null,
    btn2Fun: null,
  });

  const [data2, setData2] = useState({
    paraText: "",
    tweetText: "",
    btn1: "",
    btn1Fun: null,
  });
  //  ========== shared ============
  const tell = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://yield24api.onrender.com/api/tell", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          axios.post("https://yield24api.onrender.com/api/getScore", {
            twitter_id,
          });
          handleClose();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== follow ============

  const follow = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://yield24api.onrender.com/api/follow", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          axios.post("https://yield24api.onrender.com/api/getScore", {
            twitter_id,
          });
          setScore(res?.data?.data);
          handleClose();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };
  //  ========== wallet ============

  const linkWallet = () => {
    setLoading(true);
    // console.log(twitter_id);
    if (twitter_id) {
      const walletAddress = address;
      axios
        .put("https://yield24api.onrender.com/api/linkwallet", {
          twitter_id,
          walletAddress,
        })
        .then(res => {
          console.log(res?.data?.message);
          if (res?.data?.status === 200) {
            toast.success(`Your wallet has been linked successfully!`);
            setScore(res?.data?.data);
            axios.post("https://yield24api.onrender.com/api/getScore", {
              twitter_id,
            });
            handleClose();
            handleClose2();
            handleClose1();
            handleClose3();
            setLoading(false);
          } else {
            toast.error(res?.data?.message);
            setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);

          console.log(error?.response);
        });
    }
  };

  //  ========== join ============

  const join = () => {
    setLoading(true);
    console.log(twitter_id);
    if (twitter_id) {
      axios
        .put("https://yield24api.onrender.com/api/join", { twitter_id })
        .then(res => {
          console.log(res?.data?.message);
          setScore(res?.data?.data);
          axios.post("https://yield24api.onrender.com/api/getScore", {
            twitter_id,
          });
          handleClose();
          handleClose2();
          handleClose1();
          handleClose3();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const [otpValues, setOtpValues] = useState(["", "", "", "", ""]);
  const inputRefs = useArrayRefs(5);
  const [pinLoading, setPinLoading] = useState(false);
  const [pinLoading1, setPinLoading1] = useState(true);

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  const handleChange = (index, value) => {
    setPinLoading(true);
    setOtpValues(prevOtpValues => {
      const newOtpValues = [...prevOtpValues];
      newOtpValues[index] = value;

      if (value !== "" && index < 4) {
        inputRefs[index + 1].current.focus();
        setPinLoading1(true);
      } else if (value !== "" && index === 4) {
        // If it's the last digit, submit the form
        setPinLoading1(false);
        setPinLoading(false);
      }

      return newOtpValues;
    });
  };

  const handleBackspace = index => {
    const newOtpValues = [...otpValues];
    setPinLoading1(true);
    if (index > 0 && newOtpValues[index] === "") {
      inputRefs[index - 1].current.focus();
    }

    newOtpValues[index] = "";
    setOtpValues(newOtpValues);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const code = otpValues.join("");
      console.log("Submitting OTP: ==>", code);
      const response = await axios.post(
        "https://yield24api.onrender.com/api/verify-code",
        {
          code,
        },
      );
      if (response.data.success) {
        console.log("code Verified. Moving to the next step.");
        toast.success("Code Verified. Moving to the next step.");
        // const newCodeResponse = await axios.post(
        //   "https://yield24api.onrender.com/api/generate-new-code",
        // );
        // console.log(
        //   "New code generated and stored:",
        //   newCodeResponse.data.newCode,
        // );
        // setNewCode(newCodeResponse.data.newCode);
        setUserID(true);
        setLoading(false);
      } else {
        console.log("Invalid code. Please try again.");
        toast.error("Invalid code. Please try again.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  //  ========== referred ============

  const referred = () => {
    setLoading(true);
    const code = otpValues.join("");
    if (code !== "" && twitter_id !== "") {
      console.log("code =>", code);
      console.log("tw =>", twitter_id);
      axios
        .put("https://yield24api.onrender.com/api/referred", {
          twitter_id,
          code,
        })
        .then(res => {
          console.log(res?.data.message);
          setScore(res?.data?.data);
          axios.post("https://yield24api.onrender.com/api/getScore", {
            twitter_id,
          });
          handleClose();
          handleClose2();
          handleClose1();
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (twitter_id) {
      referred();
      axios.post("https://yield24api.onrender.com/api/getScore", {
        twitter_id,
      });
    } else {
      // console.log("code not  get and tw id");
    }
  }, [twitter_id]);

  const allDone = false;
  React.useEffect(() => {
    document.title = " Y24 Earning System";
  }, []);

  //  ========== spin ============

  // console.log(spinAmount);
  const spin = () => {
    setLoading(true);
    const spinAmount = Math.floor(1 + Math.random() * 50);
    const referredBy = score?.referredBy;
    if (twitter_id) {
      axios
        .put("https://yield24api.onrender.com/api/spin1", {
          twitter_id,
          spinAmount,
          referredBy,
        })
        .then(response => {
          const responseData = response?.data;
          console.log(responseData?.message);
          // Assuming you want to update the score state with the new data
          setScore(responseData?.data);

          toast.success(`Spin updated. You received ${spinAmount} Y24 tokens!`);

          // Assuming you want to fetch the updated score after a successful spin
          axios.post("https://yield24api.onrender.com/api/getScore", {
            twitter_id,
          });

          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          console.error("Error spinning:", error);
          toast.error("Failed to spin. Please try again.");
        });
    } else {
      setLoading(false);
      toast.error("Invalid Twitter ID");
    }
  };

  // =============================================================================================================================================================

  return (
    <div className=' container-fluid relative   pb-20 overflow-hidden'>
      <div className='container '>
        <div className='farm-box m-auto position-relative px-3 py-5'>
          {userID ? (
            <React.Fragment>
              {user?.uid ? (
                <React.Fragment>
                  {score?.tell &&
                  score?.follow &&
                  score?.join &&
                  score?.linkWallet ? (
                    <div>
                      <div
                        className='position-absolute w-full start-0 top-0 text-light user px-5 py-3 bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]'
                        onClick={referred}>
                        <img
                          src={user?.photoURL}
                          alt=''
                          width={24}
                          height={24}
                          className='mx-2 rounded-circle '
                        />
                        {user?.displayName}
                      </div>
                      <div className='mt-5'>
                        <h5 className='bg-gradient-to-br  from-[#151418] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                          Yield 24 Earning System
                        </h5>
                      </div>
                      <h1 className='flex justify-center items-center font-head '>
                        {" "}
                        <CountUp delay={2} start={0} end={score?.spinAmount} />
                        <img
                          src='assets/logo2.png'
                          className='img-fluid w-8 ml-2'
                          alt=''
                        />
                        {/* <RiCoinsFill className='w-12 ml-2' /> */}
                      </h1>
                      <div className='codes m-auto mb-3'>
                        Your Referral:&nbsp; <strong>{score?.code} </strong>
                      </div>
                      <div className='Referred m-auto mb-3'>
                        {score?.referredCont - 1} Referred
                      </div>

                      <img
                        src='assets/spin.svg'
                        className='img-fluid w-1/4 m-auto'
                        id='spinner-container'
                        alt=''
                      />
                      <div className=''>
                        <div className=' flex items-center justify-center '>
                          <div className='flex gap-3 sm:gap-1 py-2 px-2 w-full sm:w-96 flex-row bg-[#9695A4]  rounded-lg overflow-hidden pt-2 pr-3 sm:pt-0 sm:pr-0'>
                            <div className='flex flex-col bg-[#9695A4]  '>
                              <div className=' flex justify-center text-center  bg-[#9695A4] overflow-hidden sm:text-xl text-xl text-[#151418] font-head'>
                                Spin & get up to 50 y24 points NOW!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mt-3'>
                        {!score?.spin ? (
                          <button
                            disabled={score?.spin}
                            onClick={score?.spin ? null : spin}
                            className='flex btn  w-3/4 sm:w-2/4 fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            {loading ? (
                              <React.Fragment>
                                <div
                                  className='spinner-border mr-3'
                                  role='status'>
                                  <span className='visually-hidden'>
                                    Loading...
                                  </span>
                                </div>
                                Loading
                              </React.Fragment>
                            ) : (
                              "Spin Now"
                            )}
                          </button>
                        ) : (
                          <button
                            onClick={() => setShowModal3(true)}
                            className='flex btn  w-3/4 sm:w-2/4 fill items-center space-x-3 font-para py-2 px-2 font-semibold i  text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                            Spin Again <ImSpinner11 className='ml-2' />
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className=''>
                      {/* <button
                        className='position-absolute end-0 top-0 logout bg-gradient-to-br  from-[#57546C] to-[#9d9d9e] '
                        onClick={Logout}>
                        <RiLogoutCircleRFill className='mx-2' />
                        Logout
                      </button> */}
                      <div
                        className='position-absolute w-full start-0 top-0 text-light user px-5 py-3 bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]'
                        onClick={referred}>
                        <img
                          src={user?.photoURL}
                          alt=''
                          width={24}
                          height={24}
                          className='mx-2 rounded-circle '
                        />
                        {user?.displayName}
                      </div>
                      <div className=' mt-5 pt-3'>
                        <h5 className='bg-gradient-to-br   from-[#151418] to-[#9d9d9e] text-transparent bg-clip-text font-head '>
                          Yield 24 Earning System
                        </h5>
                      </div>
                      <h1 className='flex justify-center items-center font-head '>
                        {" "}
                        <CountUp delay={2} start={0} end={score?.spinAmount} />
                        <img
                          src='assets/logo2.png'
                          className='img-fluid w-8 ml-2'
                          alt=''
                        />
                        {/* <RiCoinsFill className='w-12 ml-2' /> */}
                      </h1>
                      <div className='codes m-auto mb-3'>
                        Your Referral:&nbsp; <strong>{score?.code} </strong>
                      </div>
                      <div className='Referred m-auto mb-3'>
                        {score?.referredCont - 1}&nbsp; Referred
                      </div>

                      <div className='my-5'>
                        <ol className='grid grid-cols-1  overflow-hidden rounded-lg gap-0 pl-0  text-sm text-gray-500 sm:grid-cols-3'>
                          <li className='flex items-center justify-center  p-4 bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e]'>
                            <p className='leading-none'>
                              <strong className='block font-medium mb-2'>
                                {" "}
                                Step 1{" "}
                              </strong>
                              <small className=''>
                                {" "}
                                Access To Earning System.{" "}
                              </small>
                            </p>
                          </li>

                          <li className='relative flex items-center justify-center bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e] p-4'>
                            <span className='absolute -left-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border  ltr:border-b-0 ltr:border-s-0 ltr:bg-white rtl:border-e-0 rtl:border-t-0 rtl:bg-gray-50 sm:block'></span>
                            <span className='absolute -right-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border ltr:border-b-0 ltr:border-s-0 ltr:bg-gray-50 rtl:border-e-0 rtl:border-t-0 rtl:bg-white sm:block'></span>

                            <p className='leading-none'>
                              <strong className='block font-medium mb-2'>
                                {" "}
                                Step 2{" "}
                              </strong>
                              <small className='mt-1'> Complete Tasks </small>
                            </p>
                          </li>
                          <li className='flex items-center justify-center gap-0  p-4 bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e]'>
                            <p className='leading-none'>
                              <strong className='block font-medium mb-2'>
                                {" "}
                                Step 3{" "}
                              </strong>
                              <small className='mt-1'>
                                {" "}
                                Earn up to 50 Y24{" "}
                              </small>
                            </p>
                          </li>
                        </ol>
                      </div>

                      {/* ======= start tell ======== */}
                      <div
                        className={` quests-box m-auto  row  ${
                          score?.tell && "done"
                        }`}
                        onClick={() => {
                          !score?.tell && setShowModal(true);
                          setRewardBtn(true);
                          setData({
                            paraText:
                              "Yield 24, the first liquid ReStaking token (LRT) that enhances your idle crypto assets & share passive income of up to 86% APY.",
                            tweetText: `I am excited to join <span>@Y24io</span> earning system!  
                            <br />
                            <br />
                            Now you can earn <span>upto 50 Y24</span>    tokens every  <span>24 hours</span> are you ready to join this amazing journey before it gets too late. Y24.io/airdop
                           <br />
                           <br />
                          <span> #ETH #LDO #Stader #Yield24 #Y24 #airdrop #bounty #BNB #BSC #Binance #Aave </span>`,
                            btn1: `Share on &nbsp; 
                          <img src='assets/Xlogo.png'alt='' width="20"  />
                         `,
                            btn1Link: `http://twitter.com/share?text=I am excited to join @Y24io earning system! Now you can earn upto 50 Y24 tokens every 24 hours are you ready to join this amazing journey before it gets too late. Y24.io/airdrop &hashtags=Y24,ETH,LDO,Stader,airdrop,bounty,BNB,BSC,Binance,Aave,Yield24`,
                            btn2Fun: tell,
                          });
                        }}>
                        <div className='col-2 position-relative'>
                          <p className='fs-2 text-center'>
                            <RiCoinsFill className='text-black' />
                          </p>
                          <div className='point'>1</div>
                        </div>
                        <div className='col-8 '>
                          <p>Share with your friends</p>
                        </div>
                        <div className='col-1 '>
                          <p>
                            {score?.tell ? (
                              <IoCheckmarkDoneCircleSharp className='text-dark' />
                            ) : (
                              <AiOutlineRight className='text-dark' />
                            )}
                          </p>
                        </div>
                      </div>
                      {/* ======= start twitter follow ======== */}
                      <div
                        className={` quests-box m-auto  row mt-3 ${
                          score?.follow && "done"
                        }`}
                        onClick={() => {
                          !score?.follow && setShowModal(true);
                          setRewardBtn(true);

                          setData({
                            paraText:
                              "Y24 is disrupting liquid re staking tokens (LRT).",
                            tweetText: `Follow <span>@Y24io</span>`,
                            btn1: `Follow &nbsp;
                          <img src='assets/Xlogo.png'alt='' width="20" />`,
                            btn1Link: `https://twitter.com/intent/user?screen_name=Y24io`,
                            btn2Fun: follow,
                          });
                        }}>
                        <div className='col-2 position-relative'>
                          <p className='fs-2 text-center'>
                            <RiCoinsFill className='text-black' />
                          </p>
                          <div className='point'>2</div>
                        </div>
                        <div className='col-8 '>
                          <p>
                            Follow "
                            <FaXTwitter
                              className='nav-img'
                              style={{ width: 22, display: "inline-block" }}
                            />
                            " @Yield24
                          </p>
                        </div>
                        <div className='col-1  '>
                          <p>
                            {score?.follow ? (
                              <IoCheckmarkDoneCircleSharp className='text-dark' />
                            ) : (
                              <AiOutlineRight className='text-dark' />
                            )}
                          </p>
                        </div>
                      </div>

                      {/* ======= start join tg ======== */}
                      <div
                        className={` quests-box m-auto  row mt-3 ${
                          score?.join && "done"
                        }`}
                        onClick={() => {
                          !score?.join && setShowModal(true);
                          setRewardBtn(true);

                          setData({
                            paraText:
                              "Join official telegram community for the latest updates",
                            tweetText: `Join  <span>@y24io_official</span>`,
                            btn1: `Join Telegram`,
                            btn1Link: `https://t.me/y24io_official`,
                            btn2Fun: join,
                          });
                        }}>
                        <div className='col-2 position-relative'>
                          <p className='fs-2 text-center'>
                            <RiCoinsFill className='text-black' />
                          </p>
                          <div className='point'>3</div>
                        </div>
                        <div className='col-8 '>
                          <p>Join @Yield24 community</p>
                        </div>
                        <div className='col-1  '>
                          <p>
                            {score?.join ? (
                              <IoCheckmarkDoneCircleSharp className='text-dark' />
                            ) : (
                              <AiOutlineRight className='text-dark' />
                            )}
                          </p>
                        </div>
                      </div>
                      {/* ======= start referred ======== */}
                      {/* <div
                        className={` quests-box m-auto  row mt-3 ${
                          score?.referred && ""
                        }`}
                        onClick={() => {
                          setShowModal2(true);

                          setData2({
                            paraText:
                              "Use the unique referral code provided and earn extra  with Yield 24 Earning System! Don't miss out on this opportunity to boost your rewards.",
                            tweetText: `Your Code ${score?.code}`,
                            btn1: `Referral link`,
                            btn1Link: `${score?.code}`,
                          });
                        }}>
                        <div className='col-2 position-relative'>
                          <p className='fs-2 text-center'>
                            <RiCoinsFill className='text-black' />
                          </p>
                          <div className='point'>4</div>
                        </div>
                        <div className='col-8 '>
                          <p>Referred by friends?</p>
                        </div>
                        <div className='col-1  '>
                          <p>
                            {score?.referred ? (
                              <IoCheckmarkDoneCircleSharp className='text-dark' />
                            ) : (
                              <AiOutlineRight className='text-dark' />
                            )}
                          </p>
                        </div>
                      </div> */}

                      {/* ======= start link wallet ======== */}
                      <div
                        className={` quests-box m-auto  row mt-3 ${
                          score?.linkWallet && "done"
                        }`}
                        onClick={() => {
                          !score?.linkWallet && setShowModal1(true);
                          setRewardBtn(true);

                          setData1({
                            paraText:
                              "$Yield24 will be distributed to this wallet depending on your Earning System. ",
                            tweetText: `Please ensure that you connect the accurate NFT-holding wallet. Once linked, this cannot be altered. We also accept delegated wallets.`,
                            btn1: `Connect Wallet `,
                            btn1Fun: open,
                            btn2Fun: linkWallet,
                          });
                        }}>
                        <div className='col-2 position-relative'>
                          <p className='fs-2 text-center'>
                            <RiCoinsFill className='text-black' />
                          </p>
                          <div className='point'>4</div>
                        </div>
                        <div className='col-8 '>
                          <p>Connect wallet</p>
                        </div>
                        <div className='col-1  '>
                          <p>
                            {score?.linkWallet ? (
                              <IoCheckmarkDoneCircleSharp className='text-dark' />
                            ) : (
                              <AiOutlineRight className='text-dark' />
                            )}
                          </p>
                        </div>
                      </div>

                      {/* ======= start deposit ======== */}
                      {/* <div
                        className={` quests-box m-auto  row mt-3 ${
                          score?.deposit && "done"
                        }`}
                        onClick={() => {
                          !score?.deposit && setShowModal3(true);
                          setRewardBtn(true);
                        }}>
                        <div className='col-2 position-relative'>
                          <p className='fs-2 text-center'>
                            <RiCoinsFill />
                          </p>
                          <div className='point'>5</div>
                        </div>
                        <div className='col-8 '>
                          <p>Deposit (0.1) BNB / (0.01) ETH / (32) DAI Max</p>
                        </div>
                        <div className='col-1  '>
                          <p>
                            {score?.deposit ? (
                              <IoCheckmarkDoneCircleSharp className='text-dark' />
                            ) : (
                              <AiOutlineRight className='text-dark' />
                            )}
                          </p>
                        </div>
                      </div> */}

                      {/* ======= start Link profile======== */}
                      <div
                        className={` quests-box m-auto  row mt-3 ${
                          score?.linkX && "done"
                        }`}>
                        <div className='col-2 position-relative'>
                          <p className='fs-2 text-center'>
                            <RiCoinsFill />
                          </p>
                          <div className='point'>5</div>
                        </div>
                        <div className='col-8 '>
                          <p>Link your X profile</p>
                        </div>
                        <div className='col-1  '>
                          <p>
                            {score?.linkX ? (
                              <IoCheckmarkDoneCircleSharp className='text-dark' />
                            ) : (
                              <AiOutlineRight className='text-dark' />
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <img
                    src='assets/logo2.png'
                    className='w-32 mb-3 m-auto'
                    alt=''
                  />
                  <h4 className='bg-gradient-to-br  from-[#151418] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                    <span className='text-black block '>
                      Yield Earning System
                    </span>{" "}
                  </h4>
                  <div className='my-5'>
                    <ol className='grid grid-cols-1  overflow-hidden rounded-lg gap-0 pl-0  text-sm text-gray-500 sm:grid-cols-3'>
                      <li className='flex items-center justify-center  p-4 bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e]'>
                        <p className='leading-none'>
                          <strong className='block font-medium mb-2'>
                            {" "}
                            Step 1{" "}
                          </strong>
                          <small className=''>
                            {" "}
                            Access To Earning System.{" "}
                          </small>
                        </p>
                      </li>

                      <li className='relative flex items-center justify-center bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e] p-4'>
                        <span className='absolute -left-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border  ltr:border-b-0 ltr:border-s-0 ltr:bg-white rtl:border-e-0 rtl:border-t-0 rtl:bg-gray-50 sm:block'></span>
                        <span className='absolute -right-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border ltr:border-b-0 ltr:border-s-0 ltr:bg-gray-50 rtl:border-e-0 rtl:border-t-0 rtl:bg-white sm:block'></span>

                        <p className='leading-none'>
                          <strong className='block font-medium mb-2'>
                            {" "}
                            Step 2{" "}
                          </strong>
                          <small className='mt-1'> Complete Tasks </small>
                        </p>
                      </li>
                      <li className='flex items-center justify-center gap-0  p-4 bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e]'>
                        <p className='leading-none'>
                          <strong className='block font-medium mb-2'>
                            {" "}
                            Step 3{" "}
                          </strong>
                          <small className='mt-1'> Earn up to 50 Y24 </small>
                        </p>
                      </li>
                    </ol>
                  </div>
                  <div className='flex mt-10 w-64 items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                    <button
                      onClick={twitterLogin}
                      className='text-lg text-md font-para text-black'
                      disabled={loading}>
                      {loading ? (
                        <div className='spinner-border' role='status'>
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      ) : (
                        "Login with"
                      )}
                      &nbsp;
                      <FaXTwitter
                        className='nav-img'
                        style={{ width: 22, display: "inline-block" }}
                      />
                    </button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <div>
              <img src='assets/logo2.png' className='w-32 mb-3 m-auto' alt='' />
              <h4 className='bg-gradient-to-br  from-[#151418] to-[#9d9d9e] text-transparent bg-clip-text font-head '>
                <span className='text-black text-3xl sm:text-5xl'>Airdrop</span>{" "}
              </h4>
              <div className='my-5'>
                <ol className='grid grid-cols-1  overflow-hidden rounded-lg gap-0 pl-0  text-sm text-gray-500 sm:grid-cols-3'>
                  <li className='flex items-center justify-center  p-4 bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e]'>
                    <p className='leading-none'>
                      <strong className='block font-medium mb-2'>
                        {" "}
                        Step 1{" "}
                      </strong>
                      <small className=''> Access To Earning System. </small>
                    </p>
                  </li>

                  <li className='relative flex items-center justify-center bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e] p-4'>
                    <span className='absolute -left-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border  ltr:border-b-0 ltr:border-s-0 ltr:bg-white rtl:border-e-0 rtl:border-t-0 rtl:bg-gray-50 sm:block'></span>
                    <span className='absolute -right-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border ltr:border-b-0 ltr:border-s-0 ltr:bg-gray-50 rtl:border-e-0 rtl:border-t-0 rtl:bg-white sm:block'></span>

                    <p className='leading-none'>
                      <strong className='block font-medium mb-2'>
                        {" "}
                        Step 2{" "}
                      </strong>
                      <small className='mt-1'> Complete Tasks </small>
                    </p>
                  </li>
                  <li className='flex items-center justify-center gap-0  p-4 bg-gradient-to-br  from-[#7e7b86] to-[#9d9d9e]'>
                    <p className='leading-none'>
                      <strong className='block font-medium mb-2'>
                        {" "}
                        Step 3{" "}
                      </strong>
                      <small className='mt-1'> Earn up to 50 Y24 </small>
                    </p>
                  </li>
                </ol>
              </div>
              {/* =========================== */}
              <div className='w-full animate-appear-slide-up'>
                <div className='flex flex-col items-center justify-center gap-6'>
                  <h2 className='typography-brand-heading-4-caps text-lg sm:text-3xl w-full pb-4 text-center bg-gradient-to-br  from-[#151418] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                    Early Access Yield 24 Earning System
                  </h2>
                </div>

                <form className='flex h-max w-72 flex-col items-center justify-center m-auto'>
                  <fieldset>
                    <legend className='typography-brand-body-l max-w-[326px] text-center text-gray-500 font-head'>
                      Enter your invite code to access to Yield 24 Earning
                      System
                    </legend>
                    <div className='my-8 flex gap-2 justify-center items-center'>
                      {otpValues.map((value, index) => (
                        <div
                          key={index}
                          className='relative flex justify-center'>
                          <input
                            aria-label={`Enter the early access invite code Digit ${
                              index + 1
                            }`}
                            autoComplete='off'
                            className='typography-brand-heading-3-caps peer h-[62px] w-[50px] rounded-[15px] bor bg-transparent p-[10px] text-center text-black transition-colors focus:border-black disabled:text-camo-300'
                            maxLength={1}
                            value={value}
                            type='number'
                            onChange={e => handleChange(index, e.target.value)}
                            onKeyDown={e =>
                              e.key === "Backspace" && handleBackspace(index)
                            }
                            ref={inputRefs[index]}
                          />
                          <div className='absolute bottom-[10px] m-auto h-[2px] w-[24px] bg-[#2d2b3ad7] opacity-40 transition peer-focus:translate-y-[11px] peer-focus:scale-x-150 peer-focus:opacity-0' />
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </form>
                <div className='  m-0'>
                  <button
                    type='button'
                    disabled={pinLoading || loading}
                    onClick={handleSubmit}
                    className=' mt-10 btn flex items-center w-72 font-para py-3 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                    {loading ? (
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    ) : (
                      <span>
                        {pinLoading1
                          ? "Enter invite Code"
                          : "Redeem Invite Code"}{" "}
                      </span>
                    )}
                  </button>
                </div>
                <div className='text-center'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    className='flex  px-4 py-2 mt-3 text-sm  justify-center font-bold font-para text-gray-900  rounded-lg dark-mode: transition-transform transform hover:scale-110  dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900  focus:bg-gray-200 focus:outline-none focus:shadow-outline'
                    href='https://discord.gg/rDrSaKC7'>
                    Get Invitation Code &nbsp;{" "}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={16}
                      height={16}
                      fill='currentColor'
                      className='bi bi-box-arrow-up-right'
                      viewBox='0 0 16 16'>
                      <path
                        fillRule='evenodd'
                        d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5'
                      />
                      <path
                        fillRule='evenodd'
                        d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z'
                      />
                    </svg>
                  </a>
                </div>
              </div>
              {/* =========================== */}
              {/* <div className='flex mt-10 w-64 items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                <button
                  onClick={twitterLogin}
                  className='text-lg text-md font-para text-black'
                  disabled={loading}>
                  {loading ? (
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  ) : (
                    "Login with"
                  )}
                  &nbsp;
                  <FaXTwitter
                    className='nav-img'
                    style={{ width: 22, display: "inline-block" }}
                  />
                </button>
              </div> */}
            </div>
          )}
        </div>
        <p className='text-dark font-bold text-lg capitalize mt-3 text-center opacity-75  mb-5'>
          bots and suspicious actions will be filtered without prior notice.
        </p>
        <Faqs />
      </div>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <div className='header-m bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  shadow-xl font-head'>
            <h2>QUEST</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p
            className='mt-4'
            dangerouslySetInnerHTML={{ __html: data?.paraText }}
          />
          <div className='tweet mt-3 mb-3'>
            <p dangerouslySetInnerHTML={{ __html: data?.tweetText }} />
          </div>
          <div className='flex items-center justify-evenly'>
            <a
              href={data?.btn1Link}
              target='_blank'
              onClick={() => setRewardBtn(false)}
              rel='noopener noreferrer'
              className='flex mt-10 w-full items-center  font-para py-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
              <button
                className='  font-para text-black  fill btn  items-center '
                dangerouslySetInnerHTML={{
                  __html: loading
                    ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              `
                    : data?.btn1,
                }}
              />
            </a>

            <button
              disabled={rewardBtn}
              onClick={data?.btn2Fun}
              className='mb-3 outlines btn'>
              Yes! It's done
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showModal2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <div className='header-m bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  shadow-xl font-head'>
            <h2>REFERRED</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p
            className='mt-4'
            dangerouslySetInnerHTML={{ __html: data2?.paraText }}
          />
          <div className='tweet mt-3 mb-3'>
            <p dangerouslySetInnerHTML={{ __html: data2?.tweetText }} />
          </div>
          {/* <a
            href={data2?.btn1Link}
            target='_blank'
            rel='noopener noreferrer'
            className='flex mt-10 w-full items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
            <button
              className=' fill '
              dangerouslySetInnerHTML={{
                __html: loading
                  ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              `
                  : data2?.btn1,
              }}
            />
          </a> */}
          {/* {score?.referredCont > 1 && (
            <button onClick={referred} className='mb-3 outline btn'>
              CLAIM REWARD
            </button>
          )} */}
        </Modal.Body>
      </Modal>

      <Modal show={showModal1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <div className='header-m bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]  shadow-xl font-head'>
            <h2>QUEST</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p
            className='mt-4'
            dangerouslySetInnerHTML={{ __html: data1?.paraText }}
          />
          <div className='tweet mt-3 mb-3'>
            <p dangerouslySetInnerHTML={{ __html: data1?.tweetText }} />
          </div>
          {!isConnected && (
            <button
              onClick={() => {
                data1?.btn1Fun();
                handleClose();
                handleClose2();
                handleClose1();
                setRewardBtn(false);
              }}
              className='flex mt-10 w-full fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
              dangerouslySetInnerHTML={{
                __html: loading
                  ? `<div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              `
                  : data1?.btn1,
              }}
            />
          )}
          {isConnected && (
            <button
              onClick={data1?.btn2Fun}
              className='flex mt-10 w-full fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
              Yes! It's done
            </button>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showModal3} onHide={handleClose3} centered size='lg'>
        <Modal.Header closeButton>
          <div className='header-m bg-gradient-to-br  uppercase from-[#57546C] to-[#9d9d9e]  shadow-xl font-head'>
            <h2>Bridge</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h5 className='mt-2 font-bold font-head text-center'>Enjoy 8% APY</h5>
          <div className='flex items-center flex-col-wrap m-auto mt-3'>
            <div
              className={`btn_buy flex item-center ${
                selected === 1
                  ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                  : "not-selected bg-[#c1c1c7]"
              }`}
              onClick={() => handleButton(1)}>
              <img
                src='assets/bnb.svg'
                className='img-fluid w-8 mr-2 inline-block'
                alt=''
              />{" "}
              BNB
            </div>
            <div
              className={`btn_sell flex item-center ${
                selected === 2
                  ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                  : "not-selected bg-[#c1c1c7]"
              }`}
              onClick={() => handleButton(2)}>
              <img
                src='assets/ETHLanding.837632d3.svg'
                className='img-fluid w-8 mr-2 inline-block'
                alt=''
              />{" "}
              ETH
            </div>
            <div
              className={`btn_sell flex item-center ${
                selected === 3
                  ? "selected bg-gradient-to-br  from-[#57546C] to-[#9d9d9e]"
                  : "not-selected bg-[#c1c1c7]"
              }`}
              onClick={() => handleButton(3)}>
              <img
                src='assets/DAILanding.d66b3012.svg'
                className='img-fluid w-8 mr-2 inline-block'
                alt=''
              />{" "}
              DAI
            </div>
          </div>
          {selected === 1 ? (
            <React.Fragment>
              <div className='tweet flex-col mt-3 mb-3'>
                <div className='relative flex w-full max-w-[24rem] m-auto '>
                  <div className='relative h-12 w-full min-w-[200px] '>
                    <input
                      type='number'
                      className='peer h-full w-full rounded-[7px]  borde  border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-head text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                      placeholder=' '
                      value={bnbAmount || ""}
                      onChange={e => setBnbAmount(e.target.value)}
                    />
                    <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px]  leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Enter Amount
                    </label>
                  </div>

                  <button
                    onClick={() => calcPerc(100)}
                    className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                    type='button'>
                    Max
                  </button>
                </div>
                <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                  Balance:{" "}
                  <strong>
                    {" "}
                    {balance && chain?.id === 56
                      ? parseFloat(balance).toFixed(3) + " BNB"
                      : "0"}
                  </strong>
                </h5>
                {(bnbAmount < 0.1 || bnbAmount > 0.1) && (
                  <div className='text-black  mt-2 text-sm font-semibold'>
                    Max 0.1 BNB
                  </div>
                )}
              </div>
              {!isConnected && (
                <button
                  onClick={() => {
                    open();
                    handleClose();
                    handleClose2();
                    handleClose1();
                    handleClose3();
                    setRewardBtn(false);
                  }}
                  className='flex mt-10 w-full fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                  {loading ? (
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  ) : (
                    "Connect Wallet"
                  )}
                </button>
              )}
              {isConnected &&
                (chain?.id === 56 ? (
                  <button
                    onClick={() => {
                      handleDeposit();
                    }}
                    disabled={
                      btn || bnbAmount < 0.1 || bnbAmount > 0.1 ? true : false
                    }
                    className='flex mt-10 btn w-full fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                    {btn ? (
                      <>
                        <div
                          className='spinner-border text-dark spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                        &nbsp; Processing transaction...
                      </>
                    ) : (
                      <span className='mx-4'>Stake BNB</span>
                    )}
                  </button>
                ) : (
                  <div className='text-center'>
                    <button
                      disabled={!switchNetwork}
                      className='flex max-w-4xl btn font-para text-lg m-auto  border-0 fw-bold items-center  font-para py-2 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.("56")}>
                      switch BNB Smart Chain &nbsp;
                      {isLoading && pendingChainId === "56" && (
                        <div
                          className='spinner-border text-dark spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                ))}
            </React.Fragment>
          ) : selected === 2 ? (
            <React.Fragment>
              <div className='tweet flex-col mt-3 mb-3'>
                <div className='relative flex w-full max-w-[24rem] m-auto '>
                  <div className='relative h-12 w-full min-w-[200px] '>
                    <input
                      type='number'
                      className='peer h-full w-full rounded-[7px]  borde  border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-head text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                      placeholder=' '
                      value={ETHAmount || ""}
                      onChange={e => setETHAmount(e.target.value)}
                    />
                    <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px]  leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Enter Amount
                    </label>
                  </div>

                  <button
                    onClick={() => calcPerc(100)}
                    className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                    type='button'>
                    Max
                  </button>
                </div>
                <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                  Balance:{" "}
                  <strong>
                    {balance && chain?.id === 1
                      ? parseFloat(balance).toFixed(3) + " ETH"
                      : "0"}
                  </strong>
                </h5>
                {(ETHAmount < 0.01 || ETHAmount > 0.01) && (
                  <div className='text-black  mt-2 text-sm font-semibold'>
                    Max 0.01 ETH
                  </div>
                )}
              </div>
              {!isConnected && (
                <button
                  onClick={() => {
                    open();
                    handleClose();
                    handleClose2();
                    handleClose1();
                    handleClose3();
                    setRewardBtn(false);
                  }}
                  className='flex mt-10 w-full fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                  {loading ? (
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  ) : (
                    "Connect Wallet"
                  )}
                </button>
              )}
              {isConnected &&
                (chain?.id === 1 ? (
                  <button
                    onClick={() => {
                      handleDepositETH();
                    }}
                    disabled={
                      btn || ETHAmount < 0.01 || ETHAmount > 0.01 ? true : false
                    }
                    className='flex mt-10 btn w-full fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                    {btn ? (
                      <>
                        <div
                          className='spinner-border text-dark spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                        &nbsp; Processing transaction...
                      </>
                    ) : (
                      <span className='mx-4'>Stake ETH</span>
                    )}
                  </button>
                ) : (
                  <div className='text-center'>
                    <button
                      disabled={!switchNetwork}
                      className='flex max-w-4xl btn font-para text-lg m-auto   border-0 fw-bold items-center  font-para py-2 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.("1")}>
                      Switch Ethereum &nbsp;
                      {isLoading && pendingChainId === "1" && (
                        <div
                          className='spinner-border text-dark spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='tweet flex-col mt-3 mb-3'>
                <div className='relative flex w-full max-w-[24rem] m-auto '>
                  <div className='relative h-12 w-full min-w-[200px] '>
                    <input
                      type='number'
                      className='peer h-full w-full rounded-[7px]  borde  border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-head text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50'
                      placeholder=' '
                      value={daiAmount || ""}
                      onChange={e => setDaiAmount(e.target.value)}
                    />
                    <label className="before:content[' '] after:content[' '] pointer-events-none font-para font-bold absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[11px]  leading-tight text-gray-500 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Enter Amount
                    </label>
                  </div>

                  <button
                    onClick={() => calcPercDai(100)}
                    className='!absolute right-1 top-2 select-none rounded bg-blue-gray-500 py-2 px-4 text-center align-middle font-para text-xs font-bold uppercase text-gray-600 shadow-md shadow-blue-gray-500/20 transition-all hover:shadow-lg hover:shadow-blue-gray-500/40 focus:opacity-[0.85]  active:opacity-[0.85]  disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                    type='button'>
                    Max
                  </button>
                </div>
                <h5 className='text-sm w-full max-w-[24rem] m-auto'>
                  Balance:{" "}
                  <strong>
                    {" "}
                    {balanceDai && chain?.id === 56
                      ? parseFloat(balanceDai).toFixed(3) + " DAI"
                      : "0"}
                  </strong>
                </h5>
                {(daiAmount < 32 || daiAmount > 32) && (
                  <div className='text-black  mt-2 text-sm font-semibold'>
                    Max 32 DAI
                  </div>
                )}
              </div>
              {!isConnected && (
                <button
                  onClick={() => {
                    open();
                    handleClose();
                    handleClose2();
                    handleClose1();
                    handleClose3();
                    setRewardBtn(false);
                  }}
                  className='flex mt-10 w-full fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                  {loading ? (
                    <div className='spinner-border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  ) : (
                    "Connect Wallet"
                  )}
                </button>
              )}
              {isConnected &&
                (chain?.id === 56 ? (
                  allowance === "0" ? (
                    <div
                      onClick={approve}
                      className={`flex ${
                        btn ? "w-full" : "w-52 "
                      } items-center space-x-3 font-para py-2 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out`}>
                      <span className='text-center font-para text-xl text-black font-semibold z-10 pointer-events-none block m-auto'>
                        {btn ? (
                          <>
                            <div
                              class='spinner-border text-dark spinner-border-sm'
                              role='status'>
                              <span class='sr-only'>Loading...</span>
                            </div>
                            &nbsp; Processing transaction...
                          </>
                        ) : (
                          <span className='mx-5 space-x-3'>Approve</span>
                        )}
                      </span>
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        handleDepositDai();
                      }}
                      disabled={
                        btn || daiAmount < 32 || daiAmount > 32 ? true : false
                      }
                      className='flex mt-10 btn w-full fill items-center space-x-3 font-para py-2 px-2 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                      {btn ? (
                        <>
                          <div
                            className='spinner-border text-dark spinner-border-sm'
                            role='status'>
                            <span class='sr-only'>Loading...</span>
                          </div>
                          &nbsp; Processing transaction...
                        </>
                      ) : (
                        <span className='mx-4'>Stake DAI</span>
                      )}
                    </button>
                  )
                ) : (
                  <div className='text-center'>
                    <button
                      disabled={!switchNetwork}
                      className='flex max-w-4xl btn font-para text-lg m-auto  border-0 fw-bold items-center  font-para py-2 px-3  i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center   transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'
                      onClick={() => switchNetwork?.("56")}>
                      switch BNB Smart Chain &nbsp;
                      {isLoading && pendingChainId === "56" && (
                        <div
                          className='spinner-border text-dark spinner-border-sm'
                          role='status'>
                          <span class='sr-only'>Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                ))}
            </React.Fragment>
          )}
        </Modal.Body>
      </Modal>

      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        // xmlns:svgjs='http://svgjs.dev/svgjs'
        className=' absolute bottom-0 w-full -z-50'
        width={1440}
        height={250}
        preserveAspectRatio='none'
        viewBox='0 0 1440 250'>
        <g mask='url("#SvgjsMask1006")' fill='none'>
          <rect
            width={1440}
            height={250}
            x={0}
            y={0}
            fill='rgba(255, 255, 255, 0)'
          />
          <path
            d='M26 250L276 0L364.5 0L114.5 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M278.6 250L528.6 0L782.1 0L532.1 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M504.20000000000005 250L754.2 0L884.7 0L634.7 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M715.8000000000001 250L965.8000000000001 0L1261.3000000000002 0L1011.3000000000001 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M1401 250L1151 0L1092.5 0L1342.5 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1178.4 250L928.4000000000001 0L700.4000000000001 0L950.4000000000001 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M936.8 250L686.8 0L418.29999999999995 0L668.3 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M710.1999999999999 250L460.19999999999993 0L143.69999999999993 0L393.69999999999993 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1304.192611426263 250L1440 114.19261142626311L1440 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M0 250L135.8073885737369 250L 0 114.19261142626311z'
            fill='url("#SvgjsLinearGradient1008")'
          />
        </g>
        <defs>
          <mask id='SvgjsMask1006'>
            <rect width={1440} height={250} fill='#ffffff' />
          </mask>
          <linearGradient
            x1='0%'
            y1='100%'
            x2='100%'
            y2='0%'
            id='SvgjsLinearGradient1007'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
          <linearGradient
            x1='100%'
            y1='100%'
            x2='0%'
            y2='0%'
            id='SvgjsLinearGradient1008'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default FarmingNew;
