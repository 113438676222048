/** @format */

import React, { useState } from "react";

const Faqs = () => {
  const faqData = [
    {
      question: "What is Y24?",
      answer:
        "Y24 represents a suite of open-source peer-to-system software tools actively deployed on EVM-compatible chains such as BNB Chain and Ethereum, among other blockchain networks. This software empowers users to mint transferable utility tokens, garnering rewards tied to the validation activities associated with recording data on the blockchain. Furthermore, these tokens are versatile and can be utilized in various on-chain activities.",
    },
    {
      question: "How does Y24 work?",
      answer:
        "Although the functionality may vary across networks, the typical operation of Yield24 protocols involves aggregating user tokens for staking with validators and directing these staking packages to network staking contracts. Users generate yBNB in proportion to the tokens staked, earning corresponding staking rewards. Upon unstaking, users initiate the network-specific withdrawal process by burning yBNB, enabling them to withdraw both the stake balance and accrued rewards.",
    },
    {
      question: "What is yBNB?",
      answer:
        "yBNB stands as a transferable rebasing utility token, symbolizing a portion of the overall BNB staked within the protocol. This encompasses both user deposits and staking rewards. The daily rebasing nature of yBNB serves as a daily indicator, effectively communicating the current standing of the share.",
    },
    {
      question: "How can I use yBNB?",
      answer:
        "You can use your yBNB as collateral, for lending, investing in RWA's assets, trenches, staking and many other DeFi applications. ",
    },
    {
      question: "How can I unstake yBNB?",
      answer:
        "Utilize our Withdrawals Request and Claim tabs to unstake yBNB, obtaining BNB at a 1:1 ratio. Additionally, you can claim your ETH through the designated Claim tab.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleItem = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='pb-20'>
      <div className='px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl'>
        <div className='max-w-2xl mx-auto '>
          <h2 className='text-3xl font-bold leading-tight bg-gradient-to-br  from-[#27252e] to-[#9d9d9e] text-transparent bg-clip-text sm:text-xl font-head'>
            Frequently Asked Questions
          </h2>
        </div>
        <div className='max-w-3xl mx-auto mt-8 space-y-4 '>
          {faqData.map((item, index) => (
            <div
              key={index}
              className={`transition-all duration-200 faqs rounded-2xl shadow-lg cursor-pointer ${
                openIndex === index ? "open" : ""
              }`}>
              <button
                type='button'
                className='flex items-center justify-between w-full px-4 py-5 sm:p-6'
                onClick={() => toggleItem(index)}>
                <span className='flex text-lg font-semibold text-black'>
                  {item.question}
                </span>
                <svg
                  className={`w-6 h-6 text-gray-400 ${
                    openIndex === index ? "rotate-180" : ""
                  }`}
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M19 9l-7 7-7-7'
                  />
                </svg>
              </button>
              <div
                className={`px-4 pb-5 sm:px-6 sm:pb-6 ${
                  openIndex === index ? "block" : "hidden"
                }`}>
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
