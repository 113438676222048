/** @format */

import React from "react";

const CTASection = () => {
  return (
    <section className='py-6  text-gray-900 relative overflow-hidden'>
      <div className='container mx-auto flex flex-col justify-around p-4 text-center md:p-10 lg:flex-row'>
        <div className='flex flex-col justify-center lg:text-left'>
          <p className='mb-1 text-sm font-medium tracking-widest uppercase text-gray-600 font-para'>
            Y24 Mobile App is live
          </p>
          <h1 className='py-2 text-3xl font-medium leading-tight title-font font-head'>
            Yield24 Mobile APP
          </h1>
        </div>
        <div className='flex flex-col items-center justify-center flex-shrink-0 mt-6  space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 lg:ml-4 lg:mt-0 lg:justify-end'>
          <a
            href='https://github.com/Y24io/mobile-app/blob/main/Yield24.apk'
            target='_blank'
            rel='noopener noreferrer'>
            {" "}
            <button className='inline-flex items-center px-6 py-3 rounded-lg bg-gray-600 text-gray-50 '>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                className='fill-current w-8 h-8 text-gray-50'>
                <path d='M 5.4160156 2.328125 L 12.935547 10.158203 C 13.132547 10.363203 13.45925 10.363203 13.65625 10.158203 L 15.179688 8.5742188 C 15.405688 8.3392188 15.354312 7.956875 15.070312 7.796875 C 11.137313 5.571875 6.2620156 2.811125 5.4160156 2.328125 z M 3.140625 2.8476562 C 3.055625 3.0456562 3 3.2629063 3 3.5039062 L 3 20.591797 C 3 20.788797 3.044375 20.970625 3.109375 21.140625 L 11.576172 12.324219 C 11.762172 12.131219 11.762172 11.826813 11.576172 11.632812 L 3.140625 2.8476562 z M 17.443359 9.2578125 C 17.335484 9.2729375 17.233297 9.32375 17.154297 9.40625 L 15.015625 11.632812 C 14.829625 11.825812 14.829625 12.130219 15.015625 12.324219 L 17.134766 14.529297 C 17.292766 14.694297 17.546141 14.729188 17.744141 14.617188 C 19.227141 13.777188 20.226563 13.212891 20.226562 13.212891 C 20.725562 12.909891 21.007 12.443547 21 11.935547 C 20.992 11.439547 20.702609 10.981938 20.224609 10.710938 C 20.163609 10.676937 19.187672 10.124359 17.763672 9.3183594 C 17.664172 9.2623594 17.551234 9.2426875 17.443359 9.2578125 z M 13.296875 13.644531 C 13.165875 13.644531 13.034047 13.696328 12.935547 13.798828 L 5.4746094 21.566406 C 6.7566094 20.837406 11.328781 18.249578 15.050781 16.142578 C 15.334781 15.981578 15.386156 15.599281 15.160156 15.363281 L 13.65625 13.798828 C 13.55775 13.696328 13.427875 13.644531 13.296875 13.644531 z' />
              </svg>
              <span className='flex flex-col items-start ml-4 leading-none'>
                <span className='mb-1 text-xs'>GET IT ON</span>
                <span className='font-semibold title-font'>Google Play</span>
              </span>
            </button>
          </a>
          {/* <button className='inline-flex items-center px-5 py-3 rounded-lg bg-gray-600 text-gray-50'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 50 50'
              className='fill-current w-8 h-8 text-gray-50'>
              <path d='M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z' />
            </svg>
            <span className='flex flex-col items-start ml-4 leading-none'>
              <span className='mb-1 text-xs'>Download on the</span>
              <span className='font-semibold title-font'>App Store</span>
            </span>
          </button> */}
        </div>
      </div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        // xmlns:svgjs='http://svgjs.dev/svgjs'
        className=' absolute bottom-0 w-full -z-50'
        width={1440}
        height={250}
        preserveAspectRatio='none'
        viewBox='0 0 1440 250'>
        <g mask='url("#SvgjsMask1006")' fill='none'>
          <rect
            width={1440}
            height={250}
            x={0}
            y={0}
            fill='rgba(255, 255, 255, 0)'
          />
          <path
            d='M26 250L276 0L364.5 0L114.5 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M278.6 250L528.6 0L782.1 0L532.1 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M504.20000000000005 250L754.2 0L884.7 0L634.7 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M715.8000000000001 250L965.8000000000001 0L1261.3000000000002 0L1011.3000000000001 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M1401 250L1151 0L1092.5 0L1342.5 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1178.4 250L928.4000000000001 0L700.4000000000001 0L950.4000000000001 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M936.8 250L686.8 0L418.29999999999995 0L668.3 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M710.1999999999999 250L460.19999999999993 0L143.69999999999993 0L393.69999999999993 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1304.192611426263 250L1440 114.19261142626311L1440 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M0 250L135.8073885737369 250L 0 114.19261142626311z'
            fill='url("#SvgjsLinearGradient1008")'
          />
        </g>
        <defs>
          <mask id='SvgjsMask1006'>
            <rect width={1440} height={250} fill='#ffffff' />
          </mask>
          <linearGradient
            x1='0%'
            y1='100%'
            x2='100%'
            y2='0%'
            id='SvgjsLinearGradient1007'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
          <linearGradient
            x1='100%'
            y1='100%'
            x2='0%'
            y2='0%'
            id='SvgjsLinearGradient1008'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
        </defs>
      </svg>
    </section>
  );
};

export default CTASection;
