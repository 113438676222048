/** @format */

import React from "react";
import Hero from "../components/elements/Hero";
import Section1 from "../components/elements/Section1";
import Section4 from "../components/elements/Section4";
import Finance from "../components/elements/Finance";
import Integration from "../components/elements/Integration";
import Features from "../components/elements/Features";
import Faqs from "../components/utils/Faqs";
import CTASection from "../components/elements/CTASection";
import Play from "../components/elements/Play";
const Home = () => {
  React.useEffect(() => {
    document.title = "Yield 24 - Multiply Your Crypto Holdings";
  }, []);

  return (
    <React.Fragment>
      <Hero />
      <Section1 />
      <Features />
      <Finance />
      <Integration />
      <Faqs />
      {/* <Play /> */}
      <Section4 />
      <CTASection />
    </React.Fragment>
  );
};

export default Home;
