/** @format */

import React from "react";

const Integration = () => {
  return (
    <>
      <div className='integration_sec'>
        <div className='container'>
          <h2 className='font-head bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text'>
            Tranche's Integrations
          </h2>
          <div className='row'>
            <div className='col-md-7 col-12 mt-3'>
              <div className='card_main'>
                <h3 className='font-head bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text'>
                  Networks
                </h3>

                <div className='d-flex justify-content-between flex-wrap'>
                  <div className='d-flex align-items-center gap-2 mt-3 md:mt-0'>
                    <img
                      src='assets/bnb.svg'
                      style={{ width: "40px" }}
                      alt=''
                    />
                    <p>Binance </p>
                  </div>
                  <div className='d-flex align-items-center gap-2 mt-3 md:mt-0'>
                    <img src='assets/EthIconCard.ed8495d0.svg' alt='' />
                    <p>Mainnet </p>
                  </div>
                  <div className='d-flex align-items-center gap-2 mt-3 md:mt-0'>
                    <img
                      src='assets/arbitrum-arb-logo.svg'
                      style={{ width: "40px" }}
                      alt=''
                    />
                    <p>Arbitrum </p>
                  </div>
                  <div className='d-flex align-items-center gap-2 mt-3 md:mt-0'>
                    <img src='/assets/PolygonIconCard.c9f70269.svg' alt='' />
                    <p>Polygon </p>
                  </div>
                  <div className='d-flex align-items-center gap-2 mt-3 md:mt-0'>
                    <img src='/assets/FantomIconCard.c8f4ce4a.svg' alt='' />
                    <p>Fantom </p>
                  </div>
                  <div className='d-flex align-items-center gap-2 mt-3 md:mt-0'>
                    <img src='/assets/AVAX.2ec3f0d9.svg' alt='' />
                    <p>Avalanche </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-5 col-12  '>
              <div className='card_main mt-24 md:mt-4'>
                <h3 className='font-head bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text'>
                  Markets
                </h3>
                <div className='d-flex justify-content-between flex-wrap'>
                  <a
                    href='https://governance.aave.com/t/y24-io-liquid-restating-token-fastest-growing-protocol/15922'
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex align-items-center gap-3 mt-3 md:mt-0'>
                    <img
                      src='https://europe1.discourse-cdn.com/business20/uploads/aave/original/1X/551545d40f1dd9fd3d7a500022e3f492af23ea77.png'
                      style={{ width: "70px" }}
                      alt=''
                    />
                  </a>
                  <a
                    href='https://research.lido.fi/t/y24-lido-collaboration/6235'
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex align-items-center gap-3 mt-3 md:mt-0'>
                    <img
                      src='https://europe1.discourse-cdn.com/business20/uploads/lido/original/1X/5d951653caa6c4927d213859d314e5d2c933bdd8.png'
                      style={{ width: "120px" }}
                      alt=''
                    />
                  </a>

                  <a
                    href='https://gov.centrifuge.io/t/y24-io-centrifuge-potential-collaboration/5916'
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex align-items-center gap-3 mt-3 md:mt-0'>
                    <img
                      src='https://europe1.discourse-cdn.com/business20/uploads/centrifuge1/original/1X/f94f5839003944d8d8e646b0bc39bd2521c3d410.svg'
                      style={{ width: "70px" }}
                      alt=''
                    />
                  </a>
                  <a
                    href='https://forum.staderlabs.com/t/y24-io-stader-potential-collaboration/1021'
                    target='_blank'
                    rel='noreferrer'
                    className='d-flex align-items-center gap-3 mt-3 md:mt-0'>
                    <img
                      src='https://global.discourse-cdn.com/standard11/uploads/staderlabs/original/1X/ed5db537e73264458a32d1c4f7523cfc8ccb290c.svg'
                      style={{ width: "70px" }}
                      alt=''
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Integration;
