import React from 'react'

const HeroSvg = () => {
  return (
    <svg
        width={1400}
        height={800}
        viewBox='0 0 1400 800'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='HeaderMap'>
        <path
          d='M735.8 577H728.9L725.4 571L728.9 565H735.8L739.3 571L735.8 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 629.8H1237.9L1234.4 623.8L1237.9 617.7H1244.8L1248.3 623.8L1244.8 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1283.9 546.7H1277.1L1273.7 540.9L1277.1 535H1283.9L1287.3 540.9L1283.9 546.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 230.4H859.4L855.9 224.4L859.4 218.4H866.3L869.8 224.4L866.3 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 222.9H1081.3L1077.8 216.9L1081.3 210.8H1088.2L1091.7 216.9L1088.2 222.9Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-first'
        />
        <path
          d='M996.9 124.9H989.9L986.4 118.9L989.9 112.9H996.9L1000.3 118.9L996.9 124.9Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-second'
        />
        <path
          d='M644.4 207.7H637.5L634.1 201.8L637.5 195.8H644.4L647.9 201.8L644.4 207.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 57.1H715.8L712.3 51.1L715.8 45H722.8L726.3 51.1L722.8 57.1Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-third'
        />
        <path
          d='M513.9 57.1H507L503.5 51.1L507 45H513.9L517.4 51.1L513.9 57.1Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-fourth'
        />
        <path
          d='M696.7 403.7H689.7L686.2 397.7L689.7 391.7H696.7L700.1 397.7L696.7 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 411.3H676.7L673.2 405.2L676.7 399.2H683.6L687.1 405.2L683.6 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 418.8H689.7L686.2 412.8L689.7 406.7H696.7L700.1 412.8L696.7 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 411.3H702.8L699.3 405.2L702.8 399.2H709.7L713.2 405.2L709.7 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 396.2H702.8L699.3 390.2L702.8 384.1H709.7L713.2 390.2L709.7 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 388.7H689.7L686.2 382.6L689.7 376.6H696.7L700.1 382.6L696.7 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 396.2H676.7L673.2 390.2L676.7 384.1H683.6L687.1 390.2L683.6 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 418.8H663.6L660.1 412.8L663.6 406.7H670.6L674 412.8L670.6 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 426.3H676.7L673.2 420.3L676.7 414.3H683.6L687.1 420.3L683.6 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 433.9H689.7L686.2 427.8L689.7 421.8H696.7L700.1 427.8L696.7 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 426.3H702.8L699.3 420.3L702.8 414.3H709.7L713.2 420.3L709.7 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 418.8H715.8L712.3 412.8L715.8 406.7H722.8L726.3 412.8L722.8 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 403.7H715.8L712.3 397.7L715.8 391.7H722.8L726.3 397.7L722.8 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 388.7H715.8L712.3 382.6L715.8 376.6H722.8L726.3 382.6L722.8 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 381.1H702.8L699.3 375.1L702.8 369.1H709.7L713.2 375.1L709.7 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 373.6H689.7L686.2 367.6L689.7 361.5H696.7L700.1 367.6L696.7 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 381.1H676.7L673.2 375.1L676.7 369.1H683.6L687.1 375.1L683.6 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 388.7H663.6L660.1 382.6L663.6 376.6H670.6L674 382.6L670.6 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 403.7H663.6L660.1 397.7L663.6 391.7H670.6L674 397.7L670.6 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 426.3H650.6L647.1 420.3L650.6 414.3H657.5L661 420.3L657.5 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 433.9H663.6L660.1 427.8L663.6 421.8H670.6L674 427.8L670.6 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 441.4H676.7L673.2 435.4L676.7 429.4H683.6L687.1 435.4L683.6 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 448.9H689.7L686.2 442.9L689.7 436.9H696.7L700.1 442.9L696.7 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 441.4H702.8L699.3 435.4L702.8 429.4H709.7L713.2 435.4L709.7 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 433.9H715.8L712.3 427.8L715.8 421.8H722.8L726.3 427.8L722.8 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 426.3H728.9L725.4 420.3L728.9 414.3H735.8L739.3 420.3L735.8 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 411.3H728.9L725.4 405.2L728.9 399.2H735.8L739.3 405.2L735.8 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 396.2H728.9L725.4 390.2L728.9 384.1H735.8L739.3 390.2L735.8 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 381.1H728.9L725.4 375.1L728.9 369.1H735.8L739.3 375.1L735.8 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 373.6H715.8L712.3 367.6L715.8 361.5H722.8L726.3 367.6L722.8 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 366.1H702.8L699.3 360L702.8 354H709.7L713.2 360L709.7 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 358.5H689.7L686.2 352.5L689.7 346.5H696.7L700.1 352.5L696.7 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 366.1H676.7L673.2 360L676.7 354H683.6L687.1 360L683.6 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 373.6H663.6L660.1 367.6L663.6 361.5H670.6L674 367.6L670.6 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 381.1H650.6L647.1 375.1L650.6 369.1H657.5L661 375.1L657.5 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 396.2H650.6L647.1 390.2L650.6 384.1H657.5L661 390.2L657.5 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 411.3H650.6L647.1 405.2L650.6 399.2H657.5L661 405.2L657.5 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 433.9H637.5L634 427.8L637.5 421.8H644.5L647.9 427.8L644.5 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 441.4H650.6L647.1 435.4L650.6 429.4H657.5L661 435.4L657.5 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 448.9H663.6L660.1 442.9L663.6 436.9H670.6L674 442.9L670.6 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 456.5H676.7L673.2 450.5L676.7 444.4H683.6L687.1 450.5L683.6 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 464H689.7L686.2 458L689.7 452H696.7L700.1 458L696.7 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 456.5H702.8L699.3 450.5L702.8 444.4H709.7L713.2 450.5L709.7 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 448.9H715.8L712.3 442.9L715.8 436.9H722.8L726.3 442.9L722.8 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 441.4H728.9L725.4 435.4L728.9 429.4H735.8L739.3 435.4L735.8 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 433.9H741.9L738.4 427.8L741.9 421.8H748.9L752.4 427.8L748.9 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 418.8H741.9L738.4 412.8L741.9 406.7H748.9L752.4 412.8L748.9 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 403.7H741.9L738.4 397.7L741.9 391.7H748.9L752.4 397.7L748.9 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 388.7H741.9L738.4 382.6L741.9 376.6H748.9L752.4 382.6L748.9 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 373.6H741.9L738.4 367.6L741.9 361.5H748.9L752.4 367.6L748.9 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 366.1H728.9L725.4 360L728.9 354H735.8L739.3 360L735.8 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 358.5H715.8L712.4 352.5L715.8 346.5H722.8L726.2 352.5L722.8 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 350.9H702.8L699.4 345L702.8 339H709.7L713.1 345L709.7 350.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 343.4H689.7L686.2 337.4L689.7 331.4H696.7L700.1 337.4L696.7 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 351H676.7L673.2 345L676.7 338.9H683.6L687.1 345L683.6 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 358.5H663.6L660.1 352.5L663.6 346.5H670.6L674 352.5L670.6 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 366.1H650.6L647.1 360L650.6 354H657.5L661 360L657.5 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 373.6H637.5L634 367.6L637.5 361.5H644.5L647.9 367.6L644.5 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 388.7H637.5L634 382.6L637.5 376.6H644.5L647.9 382.6L644.5 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 403.7H637.5L634 397.7L637.5 391.7H644.5L647.9 397.7L644.5 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 418.8H637.5L634 412.8L637.5 406.7H644.5L647.9 412.8L644.5 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 441.4H624.4L621 435.4L624.4 429.4H631.4L634.9 435.4L631.4 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 448.9H637.5L634 442.9L637.5 436.9H644.5L647.9 442.9L644.5 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 456.5H650.6L647.1 450.5L650.6 444.4H657.5L661 450.5L657.5 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 464H663.6L660.1 458L663.6 452H670.6L674 458L670.6 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 471.6H676.7L673.2 465.5L676.7 459.5H683.6L687.1 465.5L683.6 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 479.1H689.7L686.2 473.1L689.7 467H696.7L700.1 473.1L696.7 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 471.6H702.8L699.3 465.5L702.8 459.5H709.7L713.2 465.5L709.7 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 464H715.8L712.3 458L715.8 452H722.8L726.3 458L722.8 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 456.5H728.9L725.4 450.5L728.9 444.4H735.8L739.3 450.5L735.8 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 448.9H741.9L738.4 442.9L741.9 436.9H748.9L752.4 442.9L748.9 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 441.4H755L751.5 435.4L755 429.4H761.9L765.4 435.4L761.9 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 426.3H755L751.5 420.3L755 414.3H761.9L765.4 420.3L761.9 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 411.3H755L751.5 405.2L755 399.2H761.9L765.4 405.2L761.9 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 396.2H755L751.5 390.2L755 384.1H761.9L765.4 390.2L761.9 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 381.1H755L751.5 375.1L755 369.1H761.9L765.4 375.1L761.9 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 366.1H755L751.5 360L755 354H761.9L765.4 360L761.9 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.5 358H742.2L739.1 352.5L742.2 347H748.5L751.7 352.5L748.5 358Z'
          fill='#DFDFDF'
        />
        <path
          d='M707.2 331.6H705.3L704.3 329.9L705.3 328.2H707.2L708.2 329.9L707.2 331.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M695.3 326.1H691L688.9 322.4L691 318.6H695.3L697.5 322.4L695.3 326.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.5 335.7H676.8L673.4 329.9L676.8 324.1H683.5L686.8 329.9L683.5 335.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 343.4H663.6L660.1 337.4L663.6 331.4H670.6L674 337.4L670.6 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 351H650.6L647.1 345L650.6 338.9H657.5L661 345L657.5 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 358.5H637.5L634 352.5L637.5 346.5H644.5L647.9 352.5L644.5 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 366.1H624.4L621 360L624.4 354H631.4L634.9 360L631.4 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 381.1H624.4L621 375.1L624.4 369.1H631.4L634.9 375.1L631.4 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 396.2H624.4L621 390.2L624.4 384.1H631.4L634.9 390.2L631.4 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 411.3H624.4L621 405.2L624.4 399.2H631.4L634.9 405.2L631.4 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 426.3H624.4L621 420.3L624.4 414.3H631.4L634.9 420.3L631.4 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M618.4 448.9H611.4L607.9 442.9L611.4 436.9H618.4L621.8 442.9L618.4 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 456.5H624.4L621 450.5L624.4 444.4H631.4L634.9 450.5L631.4 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 464H637.5L634 458L637.5 452H644.5L647.9 458L644.5 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 471.6H650.6L647.1 465.5L650.6 459.5H657.5L661 465.5L657.5 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 479.1H663.6L660.1 473.1L663.6 467H670.6L674 473.1L670.6 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 486.6H676.7L673.2 480.6L676.7 474.6H683.6L687.1 480.6L683.6 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M694.8 490.9H691.6L690 488.1L691.6 485.3H694.8L696.4 488.1L694.8 490.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 486.6H702.8L699.3 480.6L702.8 474.6H709.7L713.2 480.6L709.7 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 479.1H715.8L712.3 473.1L715.8 467H722.8L726.3 473.1L722.8 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 471.6H728.9L725.4 465.5L728.9 459.5H735.8L739.3 465.5L735.8 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 464H741.9L738.4 458L741.9 452H748.9L752.4 458L748.9 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 456.5H755L751.5 450.5L755 444.4H761.9L765.4 450.5L761.9 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 448.9H768L764.5 442.9L768 436.9H775L778.5 442.9L775 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 433.9H768L764.5 427.8L768 421.8H775L778.5 427.8L775 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 418.8H768L764.5 412.8L768 406.7H775L778.5 412.8L775 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 403.7H768L764.5 397.7L768 391.7H775L778.5 397.7L775 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 388.7H768L764.5 382.6L768 376.6H775L778.5 382.6L775 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 373.6H768L764.5 367.6L768 361.5H775L778.5 367.6L775 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M774.8 358.2H768.2L764.9 352.5L768.2 346.7H774.8L778.1 352.5L774.8 358.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M760 347.7H756.9L755.3 345L756.9 342.2H760L761.6 345L760 347.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M720.2 324H718.4L717.4 322.4L718.4 320.7H720.2L721.1 322.4L720.2 324Z'
          fill='#DFDFDF'
        />
        <path
          d='M695.1 310.6H691.3L689.4 307.3L691.3 304H695.1L697 307.3L695.1 310.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M656.9 334.8H651.2L648.4 329.9L651.2 325H656.9L659.7 329.9L656.9 334.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.4 343.4H637.5L634.1 337.4L637.5 331.5H644.4L647.9 337.4L644.4 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 351H624.4L621 345L624.4 338.9H631.4L634.9 345L631.4 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M617 356.1H612.8L610.7 352.5L612.8 348.9H617L619 352.5L617 356.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M618.3 373.4H611.5L608.1 367.6L611.5 361.7H618.3L621.6 367.6L618.3 373.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M618.4 388.7H611.4L607.9 382.6L611.4 376.6H618.4L621.8 382.6L618.4 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M618.4 403.7H611.4L607.9 397.7L611.4 391.7H618.4L621.8 397.7L618.4 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M618.4 418.8H611.4L607.9 412.8L611.4 406.7H618.4L621.8 412.8L618.4 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M618.4 433.9H611.4L607.9 427.8L611.4 421.8H618.4L621.8 427.8L618.4 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M605.3 456.5H598.3L594.9 450.5L598.3 444.4H605.3L608.8 450.5L605.3 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M618.4 464H611.4L607.9 458L611.4 452H618.4L621.8 458L618.4 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 471.6H624.4L621 465.5L624.4 459.5H631.4L634.9 465.5L631.4 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 479.1H637.5L634 473.1L637.5 467H644.5L647.9 473.1L644.5 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.1 485.9H650.9L647.9 480.6L650.9 475.3H657.1L660.2 480.6L657.1 485.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M668.1 489.9H666.1L665.1 488.1L666.1 486.4H668.1L669.1 488.1L668.1 489.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M694.5 505.4H691.9L690.6 503.2L691.9 501H694.5L695.8 503.2L694.5 505.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 501.7H702.8L699.3 495.7L702.8 489.6H709.7L713.2 495.7L709.7 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 494.2H715.8L712.3 488.1L715.8 482.1H722.8L726.3 488.1L722.8 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 486.6H728.9L725.4 480.6L728.9 474.6H735.8L739.3 480.6L735.8 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 479.1H741.9L738.4 473.1L741.9 467H748.9L752.4 473.1L748.9 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 471.6H755L751.5 465.5L755 459.5H761.9L765.4 465.5L761.9 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 464H768L764.5 458L768 452H775L778.5 458L775 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 456.5H781.1L777.6 450.5L781.1 444.4H788L791.5 450.5L788 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 441.4H781.1L777.6 435.4L781.1 429.4H788L791.5 435.4L788 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 426.3H781.1L777.6 420.3L781.1 414.3H788L791.5 420.3L788 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 411.3H781.1L777.6 405.2L781.1 399.2H788L791.5 405.2L788 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 396.2H781.1L777.6 390.2L781.1 384.1H788L791.5 390.2L788 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 381.1H781.1L777.6 375.1L781.1 369.1H788L791.5 375.1L788 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 366.1H781.1L777.6 360L781.1 354H788L791.5 360L788 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M760.6 333.7H756.3L754.1 329.9L756.3 326.1H760.6L762.8 329.9L760.6 333.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M747.5 326.1H743.3L741.1 322.4L743.3 318.6H747.5L749.7 322.4L747.5 326.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M720.4 309.1H718.2L717.2 307.3L718.2 305.4H720.4L721.4 307.3L720.4 309.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M707.2 301.5H705.2L704.2 299.7L705.2 298H707.2L708.3 299.7L707.2 301.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M695.7 296.5H690.7L688.2 292.2L690.7 287.9H695.7L698.2 292.2L695.7 296.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.3 320.5H650.8L647.5 314.8L650.8 309.2H657.3L660.6 314.8L657.3 320.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M643.6 326.9H638.3L635.7 322.4L638.3 317.8H643.6L646.3 322.4L643.6 326.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M628.6 331.1H627.2L626.6 329.9L627.2 328.7H628.6L629.3 329.9L628.6 331.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M603.8 378.5H599.9L597.9 375.1L599.9 371.7H603.8L605.7 375.1L603.8 378.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M605.3 396.2H598.3L594.9 390.2L598.3 384.1H605.3L608.8 390.2L605.3 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M605.3 411.3H598.3L594.9 405.2L598.3 399.2H605.3L608.8 405.2L605.3 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M605.3 426.3H598.3L594.9 420.3L598.3 414.3H605.3L608.8 420.3L605.3 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M605.3 441.4H598.3L594.9 435.4L598.3 429.4H605.3L608.8 435.4L605.3 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M589.5 459.2H588.1L587.4 458L588.1 456.8H589.5L590.1 458L589.5 459.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M605.3 471.5H598.4L595 465.5L598.4 459.6H605.3L608.7 465.5L605.3 471.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M618.4 479.1H611.4L607.9 473.1L611.4 467H618.4L621.8 473.1L618.4 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 486.6H624.4L621 480.6L624.4 474.6H631.4L634.9 480.6L631.4 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M643.4 492.3H638.6L636.1 488.1L638.6 483.9H643.4L645.8 488.1L643.4 492.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.1 523.3H690.3L687.4 518.3L690.3 513.3H696.1L699 518.3L696.1 523.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 516.8H702.8L699.3 510.7L702.8 504.7H709.7L713.2 510.7L709.7 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 509.2H715.8L712.3 503.2L715.8 497.2H722.8L726.3 503.2L722.8 509.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 501.7H728.9L725.4 495.7L728.9 489.6H735.8L739.3 495.7L735.8 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 494.2H741.9L738.4 488.1L741.9 482.1H748.9L752.4 488.1L748.9 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 486.6H755L751.5 480.6L755 474.6H761.9L765.4 480.6L761.9 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 479.1H768L764.5 473.1L768 467H775L778.5 473.1L775 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 471.6H781.1L777.6 465.5L781.1 459.5H788L791.5 465.5L788 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 464H794.1L790.6 458L794.1 452H801.1L804.6 458L801.1 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 448.9H794.1L790.6 442.9L794.1 436.9H801.1L804.6 442.9L801.1 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 433.9H794.1L790.6 427.8L794.1 421.8H801.1L804.6 427.8L801.1 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 418.8H794.1L790.6 412.8L794.1 406.7H801.1L804.6 412.8L801.1 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 403.7H794.1L790.6 397.7L794.1 391.7H801.1L804.6 397.7L801.1 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 388.7H794.1L790.6 382.6L794.1 376.6H801.1L804.6 382.6L801.1 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 373.6H794.1L790.6 367.6L794.1 361.5H801.1L804.6 367.6L801.1 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M800 356.7H795.2L792.7 352.5L795.2 348.3H800L802.5 352.5L800 356.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M798.2 338.4H797L796.5 337.4L797 336.4H798.2L798.7 337.4L798.2 338.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M786.4 333H782.7L780.9 329.9L782.7 326.7H786.4L788.2 329.9L786.4 333Z'
          fill='#DFDFDF'
        />
        <path
          d='M774.8 328.1H768.2L764.9 322.4L768.2 316.6H774.8L778.1 322.4L774.8 328.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M759.7 317H757.2L755.9 314.8L757.2 312.6H759.7L761 314.8L759.7 317Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.8 313.1H742L738.7 307.3L742 301.4H748.8L752.1 307.3L748.8 313.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M734.4 303.3H730.3L728.2 299.7L730.3 296.2H734.4L736.4 299.7L734.4 303.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M721 295.1H717.6L715.9 292.2L717.6 289.3H721L722.6 292.2L721 295.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 290.7H702.8L699.3 284.7L702.8 278.6H709.7L713.2 284.7L709.7 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 283.2H689.7L686.2 277.1L689.7 271.1H696.7L700.1 277.1L696.7 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M683 289.6H677.3L674.5 284.7L677.3 279.8H683L685.8 284.7L683 289.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M669.7 296.7H664.5L661.9 292.2L664.5 287.7H669.7L672.2 292.2L669.7 296.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 305.8H650.6L647.1 299.7L650.6 293.7H657.5L661 299.7L657.5 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 313.3H637.5L634 307.3L637.5 301.3H644.5L647.9 307.3L644.5 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 320.8H624.4L621 314.8L624.4 308.8H631.4L634.9 314.8L631.4 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M590.2 400.2H587.3L585.9 397.7L587.3 395.2H590.2L591.7 397.7L590.2 400.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M591.9 418.3H585.6L582.4 412.8L585.6 407.3H591.9L595.1 412.8L591.9 418.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M591.9 433.3H585.6L582.4 427.8L585.6 422.4H591.9L595.1 427.8L591.9 433.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M591.9 448.4H585.6L582.4 442.9L585.6 437.4H591.9L595.1 442.9L591.9 448.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M602.4 481.6H601.3L600.7 480.6L601.3 479.6H602.4L603 480.6L602.4 481.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M616.1 490.2H613.7L612.5 488.1L613.7 486H616.1L617.3 488.1L616.1 490.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 531.8H702.8L699.3 525.8L702.8 519.8H709.7L713.2 525.8L709.7 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 524.3H715.8L712.3 518.3L715.8 512.2H722.8L726.3 518.3L722.8 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 516.8H728.9L725.4 510.7L728.9 504.7H735.8L739.3 510.7L735.8 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 509.2H741.9L738.4 503.2L741.9 497.2H748.9L752.4 503.2L748.9 509.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 501.7H755L751.5 495.7L755 489.6H761.9L765.4 495.7L761.9 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 494.2H768L764.5 488.1L768 482.1H775L778.5 488.1L775 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 486.6H781.1L777.6 480.6L781.1 474.6H788L791.5 480.6L788 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 479.1H794.1L790.6 473.1L794.1 467H801.1L804.6 473.1L801.1 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 471.6H807.2L803.7 465.5L807.2 459.5H814.1L817.6 465.5L814.1 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 456.5H807.2L803.7 450.5L807.2 444.4H814.1L817.6 450.5L814.1 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 441.4H807.2L803.7 435.4L807.2 429.4H814.1L817.6 435.4L814.1 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 426.3H807.2L803.7 420.3L807.2 414.3H814.1L817.6 420.3L814.1 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 411.1H807.2L803.8 405.2L807.2 399.3H814.1L817.5 405.2L814.1 411.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M811.8 392.1H809.5L808.4 390.2L809.5 388.2H811.8L812.9 390.2L811.8 392.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 381.1H807.2L803.7 375.1L807.2 369.1H814.1L817.6 375.1L814.1 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 366.1H807.2L803.7 360L807.2 354H814.1L817.6 360L814.1 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 351H807.2L803.7 345L807.2 338.9H814.1L817.6 345L814.1 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 335.9H807.2L803.7 329.9L807.2 323.9H814.1L817.6 329.9L814.1 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M801 328.3H794.2L790.8 322.4L794.2 316.4H801L804.4 322.4L801 328.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 320.8H781.1L777.6 314.8L781.1 308.8H788L791.5 314.8L788 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 313.3H768L764.5 307.3L768 301.3H775L778.5 307.3L775 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.8 305.6H755.1L751.7 299.7L755.1 293.9H761.8L765.2 299.7L761.8 305.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 298.2H741.9L738.4 292.2L741.9 286.2H748.9L752.4 292.2L748.9 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 290.7H728.9L725.4 284.7L728.9 278.6H735.8L739.3 284.7L735.8 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.6 282.9H715.9L712.6 277.1L715.9 271.3H722.6L726 277.1L722.6 282.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 275.6H702.8L699.3 269.6L702.8 263.6H709.7L713.2 269.6L709.7 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 268.1H689.7L686.2 262.1L689.7 256H696.7L700.1 262.1L696.7 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 275.6H676.7L673.2 269.6L676.7 263.6H683.6L687.1 269.6L683.6 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 283.2H663.6L660.1 277.1L663.6 271.1H670.6L674 277.1L670.6 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.2 290.2H650.8L647.6 284.7L650.8 279.1H657.2L660.4 284.7L657.2 290.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 298.2H637.5L634 292.2L637.5 286.2H644.5L647.9 292.2L644.5 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M631.4 305.8H624.4L621 299.7L624.4 293.7H631.4L634.9 299.7L631.4 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.4 546.4H703.1L699.9 540.9L703.1 535.4H709.4L712.6 540.9L709.4 546.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 539.4H715.8L712.3 533.3L715.8 527.3H722.8L726.3 533.3L722.8 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 531.8H728.9L725.4 525.8L728.9 519.8H735.8L739.3 525.8L735.8 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 524.3H741.9L738.4 518.3L741.9 512.2H748.9L752.4 518.3L748.9 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 516.8H755L751.5 510.7L755 504.7H761.9L765.4 510.7L761.9 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 509.2H768L764.5 503.2L768 497.2H775L778.5 503.2L775 509.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 501.7H781.1L777.6 495.7L781.1 489.6H788L791.5 495.7L788 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 494.2H794.1L790.6 488.1L794.1 482.1H801.1L804.6 488.1L801.1 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 486.6H807.2L803.7 480.6L807.2 474.6H814.1L817.6 480.6L814.1 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 479.1H820.2L816.7 473.1L820.2 467H827.2L830.7 473.1L827.2 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 464H820.2L816.7 458L820.2 452H827.2L830.7 458L827.2 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 448.9H820.2L816.7 442.9L820.2 436.9H827.2L830.7 442.9L827.2 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M826.1 432H821.3L818.9 427.8L821.3 423.7H826.1L828.5 427.8L826.1 432Z'
          fill='#DFDFDF'
        />
        <path
          d='M824.4 413.9H823L822.4 412.8L823 411.6H824.4L825 412.8L824.4 413.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 403.7H820.2L816.8 397.7L820.2 391.7H827.2L830.6 397.7L827.2 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 388.7H820.2L816.7 382.6L820.2 376.6H827.2L830.7 382.6L827.2 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 373.6H820.2L816.7 367.6L820.2 361.5H827.2L830.7 367.6L827.2 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 358.5H820.2L816.7 352.5L820.2 346.5H827.2L830.7 352.5L827.2 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 343.4H820.2L816.7 337.4L820.2 331.4H827.2L830.7 337.4L827.2 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 328.4H820.2L816.7 322.4L820.2 316.3H827.2L830.7 322.4L827.2 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 320.8H807.2L803.7 314.8L807.2 308.8H814.1L817.6 314.8L814.1 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 313.3H794.1L790.6 307.3L794.1 301.3H801.1L804.6 307.3L801.1 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 305.8H781.1L777.6 299.7L781.1 293.7H788L791.5 299.7L788 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 298.2H768L764.5 292.2L768 286.2H775L778.5 292.2L775 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 290.7H755L751.5 284.7L755 278.6H761.9L765.4 284.7L761.9 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 283.2H741.9L738.4 277.1L741.9 271.1H748.9L752.4 277.1L748.9 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 275.6H728.9L725.4 269.6L728.9 263.6H735.8L739.3 269.6L735.8 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 268.1H715.8L712.3 262.1L715.8 256H722.8L726.3 262.1L722.8 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 260.6H702.8L699.3 254.5L702.8 248.5H709.7L713.2 254.5L709.7 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 253H689.7L686.2 247L689.7 241H696.7L700.1 247L696.7 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 260.6H676.7L673.2 254.5L676.7 248.5H683.6L687.1 254.5L683.6 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 268.1H663.6L660.1 262.1L663.6 256H670.6L674 262.1L670.6 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.4 275.4H650.7L647.3 269.6L650.7 263.8H657.4L660.7 269.6L657.4 275.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M628.9 286.3H627L626 284.7L627 283H628.9L629.8 284.7L628.9 286.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M563.3 428.9H562.1L561.5 427.8L562.1 426.8H563.3L563.9 427.8L563.3 428.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M708.5 559.9H704L701.7 555.9L704 552H708.5L710.8 555.9L708.5 559.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 554.4H715.8L712.3 548.4L715.8 542.4H722.8L726.3 548.4L722.8 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 546.9H728.9L725.4 540.9L728.9 534.8H735.8L739.3 540.9L735.8 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 539.4H741.9L738.4 533.3L741.9 527.3H748.9L752.4 533.3L748.9 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 531.8H755L751.5 525.8L755 519.8H761.9L765.4 525.8L761.9 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 524.3H768L764.5 518.3L768 512.2H775L778.5 518.3L775 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 516.8H781.1L777.6 510.7L781.1 504.7H788L791.5 510.7L788 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 509.2H794.1L790.6 503.2L794.1 497.2H801.1L804.6 503.2L801.1 509.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 501.7H807.2L803.7 495.7L807.2 489.6H814.1L817.6 495.7L814.1 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 494.2H820.2L816.7 488.1L820.2 482.1H827.2L830.7 488.1L827.2 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 486.6H833.3L829.8 480.6L833.3 474.6H840.2L843.7 480.6L840.2 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 471.6H833.3L829.8 465.5L833.3 459.5H840.2L843.7 465.5L840.2 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 456.4H833.3L829.9 450.5L833.3 444.5H840.2L843.6 450.5L840.2 456.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M837.9 437.4H835.6L834.4 435.4L835.6 433.3H837.9L839.1 435.4L837.9 437.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M839.9 425.7H833.7L830.6 420.3L833.7 414.9H839.9L843 420.3L839.9 425.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 411.3H833.3L829.8 405.2L833.3 399.2H840.2L843.7 405.2L840.2 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 396.2H833.3L829.8 390.2L833.3 384.1H840.2L843.7 390.2L840.2 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 381.1H833.3L829.8 375.1L833.3 369.1H840.2L843.7 375.1L840.2 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 366.1H833.3L829.8 360L833.3 354H840.2L843.7 360L840.2 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 351H833.3L829.8 345L833.3 338.9H840.2L843.7 345L840.2 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 335.9H833.3L829.8 329.9L833.3 323.9H840.2L843.7 329.9L840.2 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 320.8H833.3L829.8 314.8L833.3 308.8H840.2L843.7 314.8L840.2 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 313.3H820.2L816.7 307.3L820.2 301.3H827.2L830.7 307.3L827.2 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 305.8H807.2L803.7 299.7L807.2 293.7H814.1L817.6 299.7L814.1 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 298.2H794.1L790.6 292.2L794.1 286.2H801.1L804.6 292.2L801.1 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 290.7H781.1L777.6 284.7L781.1 278.6H788L791.5 284.7L788 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 283.2H768L764.5 277.1L768 271.1H775L778.5 277.1L775 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 275.6H755L751.5 269.6L755 263.6H761.9L765.4 269.6L761.9 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 268.1H741.9L738.4 262.1L741.9 256H748.9L752.4 262.1L748.9 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 260.6H728.9L725.4 254.5L728.9 248.5H735.8L739.3 254.5L735.8 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 253H715.8L712.3 247L715.8 241H722.8L726.3 247L722.8 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 245.5H702.8L699.3 239.5L702.8 233.4H709.7L713.2 239.5L709.7 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 238H689.7L686.2 231.9L689.7 225.9H696.7L700.1 231.9L696.7 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.6 245.5H676.7L673.2 239.5L676.7 233.4H683.6L687.1 239.5L683.6 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M670.6 253H663.6L660.1 247L663.6 241H670.6L674 247L670.6 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 260.5H650.6L647.1 254.5L650.6 248.6H657.5L660.9 254.5L657.5 260.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M644 267.3H638L635 262.1L638 256.9H644L647 262.1L644 267.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 569.5H715.8L712.3 563.5L715.8 557.5H722.8L726.3 563.5L722.8 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 562H728.9L725.4 555.9L728.9 549.9H735.8L739.3 555.9L735.8 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 554.4H741.9L738.4 548.4L741.9 542.4H748.9L752.4 548.4L748.9 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 546.9H755L751.5 540.9L755 534.8H761.9L765.4 540.9L761.9 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 539.4H768L764.5 533.3L768 527.3H775L778.5 533.3L775 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 531.8H781.1L777.6 525.8L781.1 519.8H788L791.5 525.8L788 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 524.3H794.1L790.6 518.3L794.1 512.2H801.1L804.6 518.3L801.1 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 516.8H807.2L803.7 510.7L807.2 504.7H814.1L817.6 510.7L814.1 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 509.2H820.2L816.7 503.2L820.2 497.2H827.2L830.7 503.2L827.2 509.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 501.7H833.3L829.8 495.7L833.3 489.6H840.2L843.7 495.7L840.2 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 494.2H846.3L842.8 488.1L846.3 482.1H853.3L856.8 488.1L853.3 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 479.1H846.3L842.8 473.1L846.3 467H853.3L856.8 473.1L853.3 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 464H846.3L842.9 458L846.3 452H853.3L856.7 458L853.3 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M853 448.5H846.6L843.3 442.9L846.6 437.3H853L856.3 442.9L853 448.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 433.9H846.3L842.8 427.8L846.3 421.8H853.3L856.8 427.8L853.3 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 418.8H846.3L842.8 412.8L846.3 406.7H853.3L856.8 412.8L853.3 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 403.7H846.3L842.8 397.7L846.3 391.7H853.3L856.8 397.7L853.3 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 388.7H846.3L842.8 382.6L846.3 376.6H853.3L856.8 382.6L853.3 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 373.6H846.3L842.8 367.6L846.3 361.5H853.3L856.8 367.6L853.3 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 358.5H846.3L842.8 352.5L846.3 346.5H853.3L856.8 352.5L853.3 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 343.4H846.3L842.8 337.4L846.3 331.4H853.3L856.8 337.4L853.3 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 328.4H846.3L842.8 322.4L846.3 316.3H853.3L856.8 322.4L853.3 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 313.3H846.3L842.8 307.3L846.3 301.3H853.3L856.8 307.3L853.3 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 305.8H833.3L829.8 299.7L833.3 293.7H840.2L843.7 299.7L840.2 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 298.2H820.2L816.7 292.2L820.2 286.2H827.2L830.7 292.2L827.2 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 290.7H807.2L803.7 284.7L807.2 278.6H814.1L817.6 284.7L814.1 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 283.2H794.1L790.6 277.1L794.1 271.1H801.1L804.6 277.1L801.1 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 275.6H781.1L777.6 269.6L781.1 263.6H788L791.5 269.6L788 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 268.1H768L764.5 262.1L768 256H775L778.5 262.1L775 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 260.6H755L751.5 254.5L755 248.5H761.9L765.4 254.5L761.9 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 253H741.9L738.4 247L741.9 241H748.9L752.4 247L748.9 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 245.5H728.9L725.4 239.5L728.9 233.4H735.8L739.3 239.5L735.8 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 238H715.8L712.3 231.9L715.8 225.9H722.8L726.3 231.9L722.8 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 230.4H702.8L699.3 224.4L702.8 218.4H709.7L713.2 224.4L709.7 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.4 222.4H690L686.7 216.9L690 211.3H696.4L699.6 216.9L696.4 222.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M682 227.7H678.3L676.4 224.4L678.3 221.1H682L683.9 224.4L682 227.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M667.6 232.9H666.5L666 231.9L666.5 231H667.6L668.2 231.9L667.6 232.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 245.4H650.6L647.1 239.5L650.6 233.5H657.5L660.9 239.5L657.5 245.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M641.7 248.2H640.3L639.5 247L640.3 245.7H641.7L642.4 247L641.7 248.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M708.3 589.7H704.2L702.1 586.1L704.2 582.5H708.3L710.4 586.1L708.3 589.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 584.6H715.8L712.3 578.6L715.8 572.5H722.8L726.3 578.6L722.8 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 569.5H741.9L738.4 563.5L741.9 557.5H748.9L752.4 563.5L748.9 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 562H755L751.5 555.9L755 549.9H761.9L765.4 555.9L761.9 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 554.4H768L764.5 548.4L768 542.4H775L778.5 548.4L775 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 546.9H781.1L777.6 540.9L781.1 534.8H788L791.5 540.9L788 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 539.4H794.1L790.6 533.3L794.1 527.3H801.1L804.6 533.3L801.1 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 531.8H807.2L803.7 525.8L807.2 519.8H814.1L817.6 525.8L814.1 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 524.3H820.2L816.7 518.3L820.2 512.2H827.2L830.7 518.3L827.2 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M840 516.4H833.5L830.3 510.7L833.5 505.1H840L843.3 510.7L840 516.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M851.7 506.6H847.9L845.9 503.2L847.9 499.8H851.7L853.7 503.2L851.7 506.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M865.9 485.8H859.9L856.9 480.6L859.9 475.4H865.9L868.9 480.6L865.9 485.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 471.6H859.4L855.9 465.5L859.4 459.5H866.3L869.8 465.5L866.3 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M864.4 453.1H861.3L859.8 450.5L861.3 447.8H864.4L865.9 450.5L864.4 453.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 441.4H859.4L856 435.4L859.4 429.4H866.3L869.8 435.4L866.3 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 426.3H859.4L855.9 420.3L859.4 414.3H866.3L869.8 420.3L866.3 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 411.3H859.4L855.9 405.2L859.4 399.2H866.3L869.8 405.2L866.3 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 396.2H859.4L855.9 390.2L859.4 384.1H866.3L869.8 390.2L866.3 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 381.1H859.4L855.9 375.1L859.4 369.1H866.3L869.8 375.1L866.3 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 366.1H859.4L855.9 360L859.4 354H866.3L869.8 360L866.3 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 351H859.4L855.9 345L859.4 338.9H866.3L869.8 345L866.3 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 335.9H859.4L855.9 329.9L859.4 323.9H866.3L869.8 329.9L866.3 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 320.8H859.4L855.9 314.8L859.4 308.8H866.3L869.8 314.8L866.3 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 305.8H859.4L855.9 299.7L859.4 293.7H866.3L869.8 299.7L866.3 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 298.2H846.3L842.8 292.2L846.3 286.2H853.3L856.8 292.2L853.3 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 290.7H833.3L829.8 284.7L833.3 278.6H840.2L843.7 284.7L840.2 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 283.2H820.2L816.7 277.1L820.2 271.1H827.2L830.7 277.1L827.2 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 275.6H807.2L803.7 269.6L807.2 263.6H814.1L817.6 269.6L814.1 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 268.1H794.1L790.6 262.1L794.1 256H801.1L804.6 262.1L801.1 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 260.6H781.1L777.6 254.5L781.1 248.5H788L791.5 254.5L788 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 253H768L764.5 247L768 241H775L778.5 247L775 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 245.5H755L751.5 239.5L755 233.4H761.9L765.4 239.5L761.9 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 238H741.9L738.4 231.9L741.9 225.9H748.9L752.4 231.9L748.9 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 230.4H728.9L725.4 224.4L728.9 218.4H735.8L739.3 224.4L735.8 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M721.6 220.9H717L714.6 216.9L717 212.8H721.6L724 216.9L721.6 220.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M708 212.4H704.4L702.6 209.3L704.4 206.2H708L709.8 209.3L708 212.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M695.4 205.5H691L688.8 201.8L691 198H695.4L697.5 201.8L695.4 205.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M657.5 230.4H650.6L647.1 224.4L650.6 218.4H657.5L661 224.4L657.5 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M644.5 238H637.5L634 231.9L637.5 225.9H644.5L647.9 231.9L644.5 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.4 606.6H703.1L700 601.2L703.1 595.8H709.4L712.5 601.2L709.4 606.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 599.7H715.8L712.3 593.6L715.8 587.6H722.8L726.3 593.6L722.8 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 592.1H728.9L725.4 586.1L728.9 580.1H735.8L739.3 586.1L735.8 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 584.6H741.9L738.4 578.6L741.9 572.5H748.9L752.4 578.6L748.9 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 577H755L751.5 571L755 565H761.9L765.4 571L761.9 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 569.5H768L764.5 563.5L768 557.5H775L778.5 563.5L775 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 562H781.1L777.6 555.9L781.1 549.9H788L791.5 555.9L788 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 554.4H794.1L790.6 548.4L794.1 542.4H801.1L804.6 548.4L801.1 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 546.9H807.2L803.7 540.9L807.2 534.8H814.1L817.6 540.9L814.1 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.1 539.3H820.3L816.9 533.3L820.3 527.4H827.1L830.6 533.3L827.1 539.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M877.6 460.9H874.2L872.6 458L874.2 455.1H877.6L879.3 458L877.6 460.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 433.9H872.4L869 427.8L872.4 421.8H879.4L882.9 427.8L879.4 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 418.8H872.4L869 412.8L872.4 406.7H879.4L882.9 412.8L879.4 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 403.7H872.4L869 397.7L872.4 391.7H879.4L882.9 397.7L879.4 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 388.6H872.4L869 382.6L872.4 376.6H879.4L882.8 382.6L879.4 388.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M876.9 369.2H875L874 367.6L875 365.9H876.9L877.8 367.6L876.9 369.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 358.5H872.4L869 352.5L872.4 346.5H879.4L882.9 352.5L879.4 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 343.4H872.4L869 337.4L872.4 331.4H879.4L882.9 337.4L879.4 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 328.4H872.4L869 322.4L872.4 316.3H879.4L882.9 322.4L879.4 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 313.3H872.4L869 307.3L872.4 301.3H879.4L882.9 307.3L879.4 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 298.2H872.4L869 292.2L872.4 286.2H879.4L882.9 292.2L879.4 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 290.7H859.4L855.9 284.7L859.4 278.6H866.3L869.8 284.7L866.3 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 283.2H846.3L842.8 277.1L846.3 271.1H853.3L856.8 277.1L853.3 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 275.6H833.3L829.8 269.6L833.3 263.6H840.2L843.7 269.6L840.2 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 268.1H820.2L816.7 262.1L820.2 256H827.2L830.7 262.1L827.2 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 260.6H807.2L803.7 254.5L807.2 248.5H814.1L817.6 254.5L814.1 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 253H794.1L790.6 247L794.1 241H801.1L804.6 247L801.1 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 245.5H781.1L777.6 239.5L781.1 233.4H788L791.5 239.5L788 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 238H768L764.5 231.9L768 225.9H775L778.5 231.9L775 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 230.4H755L751.5 224.4L755 218.4H761.9L765.4 224.4L761.9 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 222.9H741.9L738.4 216.9L741.9 210.8H748.9L752.4 216.9L748.9 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 207.8H715.8L712.4 201.8L715.8 195.8H722.8L726.2 201.8L722.8 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 200.2H702.8L699.4 194.2L702.8 188.3H709.7L713.1 194.2L709.7 200.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 192.7H689.7L686.2 186.7L689.7 180.7H696.7L700.1 186.7L696.7 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M681.4 196.4H678.9L677.7 194.2L678.9 192.1H681.4L682.6 194.2L681.4 196.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M656.1 213H651.9L649.8 209.3L651.9 205.7H656.1L658.2 209.3L656.1 213Z'
          fill='#DFDFDF'
        />
        <path
          d='M642.9 220.2H639.1L637.1 216.9L639.1 213.5H642.9L644.8 216.9L642.9 220.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M630.9 229.5H625L622.1 224.4L625 219.3H630.9L633.8 224.4L630.9 229.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M616.3 234.4H613.5L612.1 231.9L613.5 229.5H616.3L617.7 231.9L616.3 234.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 614.7H715.8L712.3 608.7L715.8 602.7H722.8L726.3 608.7L722.8 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 607.2H728.9L725.4 601.2L728.9 595.1H735.8L739.3 601.2L735.8 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 599.7H741.9L738.4 593.6L741.9 587.6H748.9L752.4 593.6L748.9 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 592.1H755L751.5 586.1L755 580.1H761.9L765.4 586.1L761.9 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 584.6H768L764.5 578.6L768 572.5H775L778.5 578.6L775 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 577H781.1L777.6 571L781.1 565H788L791.5 571L788 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 569.5H794.1L790.6 563.5L794.1 557.5H801.1L804.6 563.5L801.1 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 562H807.2L803.7 555.9L807.2 549.9H814.1L817.6 555.9L814.1 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.1 554.4H820.3L816.8 548.4L820.3 542.5H827.1L830.6 548.4L827.1 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.3 426.2H885.6L882.2 420.3L885.6 414.4H892.3L895.7 420.3L892.3 426.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 411.3H885.5L882 405.2L885.5 399.2H892.4L895.9 405.2L892.4 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.3 396H885.6L882.2 390.2L885.6 384.3H892.3L895.7 390.2L892.3 396Z'
          fill='#DFDFDF'
        />
        <path
          d='M891.8 380H886.1L883.3 375.1L886.1 370.2H891.8L894.6 375.1L891.8 380Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 366.1H885.5L882 360L885.5 354H892.4L895.9 360L892.4 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 351H885.5L882 345L885.5 338.9H892.4L895.9 345L892.4 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 335.9H885.5L882 329.9L885.5 323.9H892.4L895.9 329.9L892.4 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 320.8H885.5L882 314.8L885.5 308.8H892.4L895.9 314.8L892.4 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 305.8H885.5L882 299.7L885.5 293.7H892.4L895.9 299.7L892.4 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 290.7H885.5L882 284.7L885.5 278.6H892.4L895.9 284.7L892.4 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 283.2H872.4L869 277.1L872.4 271.1H879.4L882.9 277.1L879.4 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 275.6H859.4L855.9 269.6L859.4 263.6H866.3L869.8 269.6L866.3 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 268.1H846.3L842.8 262.1L846.3 256H853.3L856.8 262.1L853.3 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 260.6H833.3L829.8 254.5L833.3 248.5H840.2L843.7 254.5L840.2 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 253H820.2L816.7 247L820.2 241H827.2L830.7 247L827.2 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 245.5H807.2L803.7 239.5L807.2 233.4H814.1L817.6 239.5L814.1 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 238H794.1L790.6 231.9L794.1 225.9H801.1L804.6 231.9L801.1 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 230.4H781.1L777.6 224.4L781.1 218.4H788L791.5 224.4L788 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 222.9H768L764.5 216.9L768 210.8H775L778.5 216.9L775 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 215.3H755L751.5 209.3L755 203.3H761.9L765.4 209.3L761.9 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.7 207.5H742.1L738.8 201.8L742.1 196.1H748.7L752 201.8L748.7 207.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M733 195.4H731.7L731 194.2L731.7 193.1H733L733.7 194.2L733 195.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 192.7H715.8L712.3 186.7L715.8 180.7H722.8L726.3 186.7L722.8 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 185.2H702.8L699.3 179.2L702.8 173.1H709.7L713.2 179.2L709.7 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 177.7H689.7L686.2 171.6L689.7 165.6H696.7L700.1 171.6L696.7 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M683.1 184.3H677.2L674.2 179.2L677.2 174.1H683.1L686 179.2L683.1 184.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M498.7 528H496.1L494.9 525.8L496.1 523.6H498.7L500 525.8L498.7 528Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.7 629.7H715.9L712.5 623.8L715.9 617.9H722.7L726.1 623.8L722.7 629.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 622.3H728.9L725.4 616.2L728.9 610.2H735.8L739.3 616.2L735.8 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 614.7H741.9L738.4 608.7L741.9 602.7H748.9L752.4 608.7L748.9 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 607.2H755L751.5 601.2L755 595.1H761.9L765.4 601.2L761.9 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 599.7H768L764.5 593.6L768 587.6H775L778.5 593.6L775 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 592.1H781.1L777.6 586.1L781.1 580.1H788L791.5 586.1L788 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 584.6H794.1L790.6 578.6L794.1 572.5H801.1L804.6 578.6L801.1 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 577H807.2L803.7 571L807.2 565H814.1L817.6 571L814.1 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 569.5H820.2L816.7 563.5L820.2 557.5H827.2L830.7 563.5L827.2 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.2 418.2H898.9L895.7 412.8L898.9 407.3H905.2L908.3 412.8L905.2 418.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.1 403.1H898.9L895.8 397.7L898.9 392.3H905.1L908.2 397.7L905.1 403.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M904.8 387.5H899.2L896.4 382.6L899.2 377.8H904.8L907.6 382.6L904.8 387.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 373.6H898.5L895.1 367.6L898.5 361.5H905.5L909 367.6L905.5 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 358.5H898.5L895.1 352.5L898.5 346.5H905.5L909 352.5L905.5 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 343.4H898.5L895.1 337.4L898.5 331.4H905.5L909 337.4L905.5 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 328.4H898.5L895.1 322.4L898.5 316.3H905.5L909 322.4L905.5 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 313.3H898.5L895.1 307.3L898.5 301.3H905.5L909 307.3L905.5 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 298.2H898.5L895.1 292.2L898.5 286.2H905.5L909 292.2L905.5 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 283.2H898.5L895.1 277.1L898.5 271.1H905.5L909 277.1L905.5 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 275.6H885.5L882 269.6L885.5 263.6H892.4L895.9 269.6L892.4 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 268.1H872.4L869 262.1L872.4 256H879.4L882.9 262.1L879.4 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 260.6H859.4L855.9 254.5L859.4 248.5H866.3L869.8 254.5L866.3 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 253H846.3L842.8 247L846.3 241H853.3L856.8 247L853.3 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 245.5H833.3L829.8 239.5L833.3 233.4H840.2L843.7 239.5L840.2 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 238H820.2L816.7 231.9L820.2 225.9H827.2L830.7 231.9L827.2 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 230.4H807.2L803.7 224.4L807.2 218.4H814.1L817.6 224.4L814.1 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 222.9H794.1L790.6 216.9L794.1 210.8H801.1L804.6 216.9L801.1 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 215.3H781.1L777.6 209.3L781.1 203.3H788L791.5 209.3L788 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 207.8H768L764.5 201.8L768 195.8H775L778.5 201.8L775 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 200.3H755L751.5 194.2L755 188.2H761.9L765.4 194.2L761.9 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 177.7H715.8L712.3 171.6L715.8 165.6H722.8L726.3 171.6L722.8 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 170.1H702.8L699.3 164.1L702.8 158.1H709.7L713.2 164.1L709.7 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M695.7 161H690.6L688.1 156.6L690.6 152.2H695.7L698.3 156.6L695.7 161Z'
          fill='#DFDFDF'
        />
        <path
          d='M680.8 165.3H679.5L678.8 164.1L679.5 162.9H680.8L681.5 164.1L680.8 165.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M641.7 187.9H640.3L639.6 186.7L640.3 185.5H641.7L642.3 186.7L641.7 187.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 531.8H467.8L464.3 525.8L467.8 519.8H474.8L478.3 525.8L474.8 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 539.4H480.9L477.4 533.3L480.9 527.3H487.8L491.3 533.3L487.8 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.9 546.9H493.9L490.5 540.9L493.9 534.8H500.9L504.4 540.9L500.9 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M722 643.6H716.5L713.8 638.8L716.5 634.1H722L724.8 638.8L722 643.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 637.3H728.9L725.4 631.3L728.9 625.3H735.8L739.3 631.3L735.8 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 629.8H741.9L738.4 623.8L741.9 617.7H748.9L752.4 623.8L748.9 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 622.3H755L751.5 616.2L755 610.2H761.9L765.4 616.2L761.9 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 614.7H768L764.5 608.7L768 602.7H775L778.5 608.7L775 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 607.2H781.1L777.6 601.2L781.1 595.1H788L791.5 601.2L788 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 599.7H794.1L790.6 593.6L794.1 587.6H801.1L804.6 593.6L801.1 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 592.1H807.2L803.7 586.1L807.2 580.1H814.1L817.6 586.1L814.1 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 584.5H820.3L816.8 578.6L820.3 572.6H827.2L830.6 578.6L827.2 584.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M916.1 391.9H914.1L913 390.2L914.1 388.4H916.1L917.1 390.2L916.1 391.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 381.1H911.6L908.1 375.1L911.6 369.1H918.5L922 375.1L918.5 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 366.1H911.6L908.1 360L911.6 354H918.5L922 360L918.5 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 351H911.6L908.1 345L911.6 338.9H918.5L922 345L918.5 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 335.9H911.6L908.1 329.9L911.6 323.9H918.5L922 329.9L918.5 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 320.8H911.6L908.1 314.8L911.6 308.8H918.5L922 314.8L918.5 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 305.8H911.6L908.1 299.7L911.6 293.7H918.5L922 299.7L918.5 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 290.7H911.6L908.1 284.7L911.6 278.6H918.5L922 284.7L918.5 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 275.6H911.6L908.1 269.6L911.6 263.6H918.5L922 269.6L918.5 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 268.1H898.5L895.1 262.1L898.5 256H905.5L909 262.1L905.5 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 260.6H885.5L882 254.5L885.5 248.5H892.4L895.9 254.5L892.4 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 253H872.4L869 247L872.4 241H879.4L882.9 247L879.4 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 245.5H859.4L855.9 239.5L859.4 233.4H866.3L869.8 239.5L866.3 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 238H846.3L842.8 231.9L846.3 225.9H853.3L856.8 231.9L853.3 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 230.4H833.3L829.8 224.4L833.3 218.4H840.2L843.7 224.4L840.2 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 222.9H820.2L816.7 216.9L820.2 210.8H827.2L830.7 216.9L827.2 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 215.3H807.2L803.7 209.3L807.2 203.3H814.1L817.6 209.3L814.1 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 207.8H794.1L790.6 201.8L794.1 195.8H801.1L804.6 201.8L801.1 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 200.3H781.1L777.6 194.2L781.1 188.2H788L791.5 194.2L788 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M774.9 192.6H768.1L764.6 186.7L768.1 180.8H774.9L778.3 186.7L774.9 192.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 185.1H755L751.6 179.2L755 173.2H761.9L765.3 179.2L761.9 185.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 177.7H741.9L738.4 171.6L741.9 165.6H748.9L752.4 171.6L748.9 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M734.3 167.5H730.4L728.4 164.1L730.4 160.7H734.3L736.3 164.1L734.3 167.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 162.6H715.8L712.3 156.6L715.8 150.5H722.8L726.3 156.6L722.8 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.5 154.8H702.9L699.6 149L702.9 143.3H709.5L712.8 149L709.5 154.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 539.4H454.8L451.3 533.3L454.8 527.3H461.7L465.2 533.3L461.7 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 546.9H467.8L464.3 540.9L467.8 534.8H474.8L478.3 540.9L474.8 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 554.4H480.9L477.4 548.4L480.9 542.4H487.8L491.3 548.4L487.8 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.6 561.6H494.2L490.9 555.9L494.2 550.3H500.6L503.9 555.9L500.6 561.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M720.5 656.1H718L716.8 653.9L718 651.8H720.5L721.8 653.9L720.5 656.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 652.4H728.9L725.4 646.4L728.9 640.3H735.8L739.3 646.4L735.8 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 644.9H741.9L738.4 638.8L741.9 632.8H748.9L752.4 638.8L748.9 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 637.3H755L751.5 631.3L755 625.3H761.9L765.4 631.3L761.9 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 629.8H768L764.5 623.8L768 617.7H775L778.5 623.8L775 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 622.3H781.1L777.6 616.2L781.1 610.2H788L791.5 616.2L788 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 614.7H794.1L790.6 608.7L794.1 602.7H801.1L804.6 608.7L801.1 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M813.5 606H807.9L805.1 601.2L807.9 596.3H813.5L816.2 601.2L813.5 606Z'
          fill='#DFDFDF'
        />
        <path
          d='M824.7 595.3H822.7L821.7 593.6L822.7 591.9H824.7L825.7 593.6L824.7 595.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M865.8 576.1H859.9L856.9 571L859.9 565.9H865.8L868.8 571L865.8 576.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 388.6H924.7L921.2 382.6L924.7 376.6H931.6L935.1 382.6L931.6 388.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 373.6H924.6L921.2 367.6L924.6 361.5H931.6L935.1 367.6L931.6 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 358.5H924.6L921.2 352.5L924.6 346.5H931.6L935.1 352.5L931.6 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 343.4H924.6L921.2 337.4L924.6 331.4H931.6L935.1 337.4L931.6 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 328.4H924.6L921.2 322.4L924.6 316.3H931.6L935.1 322.4L931.6 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 313.3H924.6L921.2 307.3L924.6 301.3H931.6L935.1 307.3L931.6 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 298.2H924.6L921.2 292.2L924.6 286.2H931.6L935.1 292.2L931.6 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 283.2H924.6L921.2 277.1L924.6 271.1H931.6L935.1 277.1L931.6 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 268.1H924.6L921.2 262.1L924.6 256H931.6L935.1 262.1L931.6 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 260.6H911.6L908.1 254.5L911.6 248.5H918.5L922 254.5L918.5 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 253H898.5L895.1 247L898.5 241H905.5L909 247L905.5 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 245.5H885.5L882 239.5L885.5 233.4H892.4L895.9 239.5L892.4 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 238H872.4L869 231.9L872.4 225.9H879.4L882.9 231.9L879.4 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 222.9H846.3L842.8 216.9L846.3 210.8H853.3L856.8 216.9L853.3 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 215.3H833.3L829.8 209.3L833.3 203.3H840.2L843.7 209.3L840.2 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 207.8H820.2L816.7 201.8L820.2 195.8H827.2L830.7 201.8L827.2 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 200.3H807.2L803.7 194.2L807.2 188.2H814.1L817.6 194.2L814.1 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 192.7H794.1L790.6 186.7L794.1 180.7H801.1L804.6 186.7L801.1 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 185.2H781.1L777.6 179.2L781.1 173.1H788L791.5 179.2L788 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 177.7H768L764.5 171.6L768 165.6H775L778.5 171.6L775 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 170.1H755L751.5 164.1L755 158.1H761.9L765.4 164.1L761.9 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M747.1 159.6H743.6L741.9 156.6L743.6 153.5H747.1L748.9 156.6L747.1 159.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 155.1H728.9L725.4 149L728.9 143H735.8L739.3 149L735.8 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 147.5H715.8L712.3 141.5L715.8 135.5H722.8L726.3 141.5L722.8 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.1 523.2H455.4L452.6 518.3L455.4 513.4H461.1L463.9 518.3L461.1 523.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 546.9H441.7L438.2 540.9L441.7 534.8H448.7L452.2 540.9L448.7 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 554.4H454.8L451.3 548.4L454.8 542.4H461.7L465.2 548.4L461.7 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 562H467.8L464.3 555.9L467.8 549.9H474.8L478.3 555.9L474.8 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 569.5H480.9L477.4 563.5L480.9 557.5H487.8L491.3 563.5L487.8 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 667.5H728.9L725.4 661.4L728.9 655.4H735.8L739.3 661.4L735.8 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 659.9H741.9L738.4 653.9L741.9 647.9H748.9L752.4 653.9L748.9 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 652.4H755L751.5 646.4L755 640.3H761.9L765.4 646.4L761.9 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 644.9H768L764.5 638.8L768 632.8H775L778.5 638.8L775 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 637.3H781.1L777.6 631.3L781.1 625.3H788L791.5 631.3L788 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 629.8H794.1L790.6 623.8L794.1 617.7H801.1L804.6 623.8L801.1 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M811.2 617.2H810.1L809.6 616.2L810.1 615.3H811.2L811.7 616.2L811.2 617.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 599.7H846.3L842.8 593.6L846.3 587.6H853.3L856.8 593.6L853.3 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 592.1H859.4L855.9 586.1L859.4 580.1H866.3L869.8 586.1L866.3 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M943.4 394.1H938.9L936.6 390.2L938.9 386.2H943.4L945.7 390.2L943.4 394.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 381.1H937.7L934.2 375.1L937.7 369.1H944.7L948.1 375.1L944.7 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 366.1H937.7L934.2 360L937.7 354H944.7L948.1 360L944.7 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 351H937.7L934.2 345L937.7 338.9H944.7L948.1 345L944.7 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 335.9H937.7L934.2 329.9L937.7 323.9H944.7L948.1 329.9L944.7 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 320.8H937.7L934.2 314.8L937.7 308.8H944.7L948.1 314.8L944.7 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 305.8H937.7L934.2 299.7L937.7 293.7H944.7L948.1 299.7L944.7 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 290.7H937.7L934.2 284.7L937.7 278.6H944.7L948.1 284.7L944.7 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 275.6H937.7L934.2 269.6L937.7 263.6H944.7L948.1 269.6L944.7 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 260.6H937.7L934.2 254.5L937.7 248.5H944.7L948.1 254.5L944.7 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 253H924.6L921.2 247L924.6 241H931.6L935.1 247L931.6 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 245.5H911.6L908.1 239.5L911.6 233.4H918.5L922 239.5L918.5 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 238H898.5L895.1 231.9L898.5 225.9H905.5L909 231.9L905.5 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 230.4H885.5L882 224.4L885.5 218.4H892.4L895.9 224.4L892.4 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 222.9H872.4L869 216.9L872.4 210.8H879.4L882.9 216.9L879.4 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 215.3H859.4L855.9 209.3L859.4 203.3H866.3L869.8 209.3L866.3 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 207.8H846.3L842.8 201.8L846.3 195.8H853.3L856.8 201.8L853.3 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 200.3H833.3L829.8 194.2L833.3 188.2H840.2L843.7 194.2L840.2 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 192.7H820.2L816.7 186.7L820.2 180.7H827.2L830.7 186.7L827.2 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 185.2H807.2L803.7 179.2L807.2 173.1H814.1L817.6 179.2L814.1 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M801.1 177.7H794.1L790.6 171.6L794.1 165.6H801.1L804.6 171.6L801.1 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 170.1H781.1L777.6 164.1L781.1 158.1H788L791.5 164.1L788 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 162.6H768L764.5 156.6L768 150.5H775L778.5 156.6L775 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 155.1H755L751.5 149L755 143H761.9L765.4 149L761.9 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.8 147.5H742L738.5 141.5L742 135.5H748.8L752.3 141.5L748.8 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.8 140H728.9L725.4 134L728.9 127.9H735.8L739.3 134L735.8 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M722 131H716.6L714 126.4L716.6 121.8H722L724.6 126.4L722 131Z'
          fill='#DFDFDF'
        />
        <path
          d='M447.3 514.4H443.1L441 510.7L443.1 507.1H447.3L449.4 510.7L447.3 514.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 531.8H441.7L438.2 525.8L441.7 519.8H448.7L452.2 525.8L448.7 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 554.4H428.7L425.2 548.4L428.7 542.4H435.6L439.1 548.4L435.6 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 562H441.7L438.2 555.9L441.7 549.9H448.7L452.2 555.9L448.7 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 569.5H454.8L451.3 563.5L454.8 557.5H461.7L465.2 563.5L461.7 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 577H467.8L464.3 571L467.8 565H474.8L478.3 571L474.8 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.2 583.5H481.5L478.6 578.6L481.5 573.6H487.2L490.1 578.6L487.2 583.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.4 681.7H729.3L726.3 676.5L729.3 671.3H735.4L738.4 676.5L735.4 681.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 675H741.9L738.4 669L741.9 663H748.9L752.4 669L748.9 675Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 667.5H755L751.5 661.4L755 655.4H761.9L765.4 661.4L761.9 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 659.9H768L764.5 653.9L768 647.9H775L778.5 653.9L775 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 652.4H781.1L777.6 646.4L781.1 640.3H788L791.5 646.4L788 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M798.2 639.9H797L796.3 638.8L797 637.7H798.2L798.9 638.8L798.2 639.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M838.1 618.5H835.4L834.1 616.2L835.4 614H838.1L839.4 616.2L838.1 618.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 614.7H846.3L842.8 608.7L846.3 602.7H853.3L856.8 608.7L853.3 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.1 606.8H859.6L856.4 601.2L859.6 595.5H866.1L869.4 601.2L866.1 606.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.5 403.4H950.9L947.6 397.7L950.9 392H957.5L960.8 397.7L957.5 403.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 388.7H950.7L947.3 382.6L950.7 376.6H957.7L961.2 382.6L957.7 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 373.6H950.7L947.3 367.6L950.7 361.5H957.7L961.2 367.6L957.7 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 358.5H950.7L947.3 352.5L950.7 346.5H957.7L961.2 352.5L957.7 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 343.4H950.7L947.3 337.4L950.7 331.4H957.7L961.2 337.4L957.7 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 328.4H950.7L947.3 322.4L950.7 316.3H957.7L961.2 322.4L957.7 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 313.3H950.7L947.3 307.3L950.7 301.3H957.7L961.2 307.3L957.7 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 298.2H950.7L947.3 292.2L950.7 286.2H957.7L961.2 292.2L957.7 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 283.2H950.7L947.3 277.1L950.7 271.1H957.7L961.2 277.1L957.7 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 268.1H950.7L947.3 262.1L950.7 256H957.7L961.2 262.1L957.7 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 253H950.7L947.3 247L950.7 241H957.7L961.2 247L957.7 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 245.5H937.7L934.2 239.5L937.7 233.4H944.7L948.1 239.5L944.7 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 238H924.6L921.2 231.9L924.6 225.9H931.6L935.1 231.9L931.6 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 230.4H911.6L908.1 224.4L911.6 218.4H918.5L922 224.4L918.5 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 222.9H898.5L895.1 216.9L898.5 210.8H905.5L909 216.9L905.5 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 215.3H885.5L882 209.3L885.5 203.3H892.4L895.9 209.3L892.4 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 207.8H872.4L869 201.8L872.4 195.8H879.4L882.9 201.8L879.4 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 200.3H859.4L855.9 194.2L859.4 188.2H866.3L869.8 194.2L866.3 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 192.7H846.3L842.8 186.7L846.3 180.7H853.3L856.8 186.7L853.3 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 185.2H833.3L829.8 179.2L833.3 173.1H840.2L843.7 179.2L840.2 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 177.7H820.2L816.7 171.6L820.2 165.6H827.2L830.7 171.6L827.2 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M814.1 170.1H807.2L803.7 164.1L807.2 158.1H814.1L817.6 164.1L814.1 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M800.3 161.3H794.9L792.1 156.6L794.9 151.8H800.3L803.1 156.6L800.3 161.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 155.1H781.1L777.6 149L781.1 143H788L791.5 149L788 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 147.5H768L764.5 141.5L768 135.5H775L778.5 141.5L775 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 140H755L751.5 134L755 127.9H761.9L765.4 134L761.9 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.9 132.5H741.9L738.4 126.4L741.9 120.4H748.9L752.4 126.4L748.9 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.4 124.2H729.3L726.2 118.9L729.3 113.6H735.4L738.5 118.9L735.4 124.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M604.8 154.1H598.9L596 149L598.9 144H604.8L607.7 149L604.8 154.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M591.9 162H585.6L582.5 156.6L585.6 151.1H591.9L595.1 156.6L591.9 162Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.5 267.9H428.8L425.4 262.1L428.8 256.2H435.5L438.9 262.1L435.5 267.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M434.4 492H429.9L427.6 488.1L429.9 484.2H434.4L436.7 488.1L434.4 492Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.5 509H428.8L425.4 503.2L428.8 497.4H435.5L438.9 503.2L435.5 509Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 524.3H428.7L425.2 518.3L428.7 512.2H435.6L439.1 518.3L435.6 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 539.4H428.7L425.2 533.3L428.7 527.3H435.6L439.1 533.3L435.6 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 562H415.6L412.1 555.9L415.6 549.9H422.6L426.1 555.9L422.6 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 569.5H428.7L425.2 563.5L428.7 557.5H435.6L439.1 563.5L435.6 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 577H441.7L438.2 571L441.7 565H448.7L452.2 571L448.7 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 584.6H454.8L451.3 578.6L454.8 572.5H461.7L465.2 578.6L461.7 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 592.1H467.8L464.3 586.1L467.8 580.1H474.8L478.3 586.1L474.8 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M485.2 595H483.6L482.8 593.6L483.6 592.3H485.2L485.9 593.6L485.2 595Z'
          fill='#DFDFDF'
        />
        <path
          d='M747.3 687.3H743.5L741.6 684L743.5 680.8H747.3L749.2 684L747.3 687.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.2 681.2H755.7L753 676.5L755.7 671.8H761.2L763.9 676.5L761.2 681.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M774.9 674.8H768.1L764.7 669L768.1 663.1H774.9L778.3 669L774.9 674.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M785.2 662.5H783.9L783.3 661.4L783.9 660.4H785.2L785.8 661.4L785.2 662.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M838 633.5H835.5L834.2 631.3L835.5 629.1H838L839.3 631.3L838 633.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 629.8H846.3L842.8 623.8L846.3 617.7H853.3L856.8 623.8L853.3 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M865 620H860.7L858.5 616.2L860.7 612.5H865L867.2 616.2L865 620Z'
          fill='#DFDFDF'
        />
        <path
          d='M968.3 437.2H966.2L965.1 435.4L966.2 433.5H968.3L969.4 435.4L968.3 437.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.6 426H964L960.7 420.3L964 414.6H970.6L973.9 420.3L970.6 426Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 411.3H963.8L960.3 405.2L963.8 399.2H970.8L974.2 405.2L970.8 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 396.2H963.8L960.3 390.2L963.8 384.1H970.8L974.2 390.2L970.8 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 381.1H963.8L960.3 375.1L963.8 369.1H970.8L974.2 375.1L970.8 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 366.1H963.8L960.3 360L963.8 354H970.8L974.2 360L970.8 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 351H963.8L960.3 345L963.8 338.9H970.8L974.2 345L970.8 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 335.9H963.8L960.3 329.9L963.8 323.9H970.8L974.2 329.9L970.8 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 320.8H963.8L960.3 314.8L963.8 308.8H970.8L974.2 314.8L970.8 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 305.8H963.8L960.3 299.7L963.8 293.7H970.8L974.2 299.7L970.8 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 290.7H963.8L960.3 284.7L963.8 278.6H970.8L974.2 284.7L970.8 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 275.6H963.8L960.3 269.6L963.8 263.6H970.8L974.2 269.6L970.8 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 260.6H963.8L960.3 254.5L963.8 248.5H970.8L974.2 254.5L970.8 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 245.5H963.8L960.3 239.5L963.8 233.4H970.8L974.2 239.5L970.8 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 238H950.7L947.3 231.9L950.7 225.9H957.7L961.2 231.9L957.7 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 230.4H937.7L934.2 224.4L937.7 218.4H944.7L948.1 224.4L944.7 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 222.9H924.6L921.2 216.9L924.6 210.8H931.6L935.1 216.9L931.6 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 215.3H911.6L908.1 209.3L911.6 203.3H918.5L922 209.3L918.5 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 207.8H898.5L895.1 201.8L898.5 195.8H905.5L909 201.8L905.5 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 200.3H885.5L882 194.2L885.5 188.2H892.4L895.9 194.2L892.4 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 192.7H872.4L869 186.7L872.4 180.7H879.4L882.9 186.7L879.4 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 185.2H859.4L855.9 179.2L859.4 173.1H866.3L869.8 179.2L866.3 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 177.7H846.3L842.8 171.6L846.3 165.6H853.3L856.8 171.6L853.3 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 170.1H833.3L829.8 164.1L833.3 158.1H840.2L843.7 164.1L840.2 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.2 162.6H820.2L816.7 156.6L820.2 150.5H827.2L830.7 156.6L827.2 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M813.9 154.7H807.4L804.2 149L807.4 143.4H813.9L817.2 149L813.9 154.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M788 140H781.1L777.6 134L781.1 128H788L791.5 134L788 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M775 132.5H768L764.5 126.4L768 120.4H775L778.5 126.4L775 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.9 124.9H755L751.5 118.9L755 112.9H761.9L765.4 118.9L761.9 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.4 116.6H742.4L739.4 111.4L742.4 106.1H748.4L751.4 111.4L748.4 116.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M591.8 146.7H585.8L582.8 141.5L585.8 136.3H591.8L594.8 141.5L591.8 146.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M579.1 155H572.3L568.9 149L572.3 143.1H579.1L582.6 149L579.1 155Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.2 244.8H416L412.9 239.5L416 234.1H422.2L425.3 239.5L422.2 244.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M421.6 258.9H416.6L414.1 254.5L416.6 250.2H421.6L424.1 254.5L421.6 258.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M421 483.8H417.2L415.4 480.6L417.2 477.4H421L422.8 480.6L421 483.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 501.7H415.6L412.1 495.7L415.6 489.6H422.6L426.1 495.7L422.6 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 516.8H415.6L412.1 510.7L415.6 504.7H422.6L426.1 510.7L422.6 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 531.8H415.6L412.1 525.8L415.6 519.8H422.6L426.1 525.8L422.6 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 546.9H415.6L412.1 540.9L415.6 534.8H422.6L426.1 540.9L422.6 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 569.5H402.6L399.1 563.5L402.6 557.5H409.5L413 563.5L409.5 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 577H415.6L412.1 571L415.6 565H422.6L426.1 571L422.6 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 584.6H428.7L425.2 578.6L428.7 572.5H435.6L439.1 578.6L435.6 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 592.1H441.7L438.2 586.1L441.7 580.1H448.7L452.2 586.1L448.7 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 599.7H454.8L451.3 593.6L454.8 587.6H461.7L465.2 593.6L461.7 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 607.2H467.8L464.3 601.2L467.8 595.1H474.8L478.3 601.2L474.8 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M851.4 641.5H848.3L846.7 638.8L848.3 636.2H851.4L852.9 638.8L851.4 641.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M981.8 460.5H978.9L977.4 458L978.9 455.5H981.8L983.2 458L981.8 460.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 448.9H976.9L973.4 442.9L976.9 436.9H983.8L987.3 442.9L983.8 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 433.9H976.8L973.4 427.8L976.8 421.8H983.8L987.3 427.8L983.8 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 418.8H976.8L973.4 412.8L976.8 406.7H983.8L987.3 412.8L983.8 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 403.7H976.8L973.4 397.7L976.8 391.7H983.8L987.3 397.7L983.8 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 388.7H976.8L973.4 382.6L976.8 376.6H983.8L987.3 382.6L983.8 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 373.6H976.8L973.4 367.6L976.8 361.5H983.8L987.3 367.6L983.8 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 358.5H976.8L973.4 352.5L976.8 346.5H983.8L987.3 352.5L983.8 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 343.4H976.8L973.4 337.4L976.8 331.4H983.8L987.3 337.4L983.8 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 328.4H976.8L973.4 322.4L976.8 316.3H983.8L987.3 322.4L983.8 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 313.3H976.8L973.4 307.3L976.8 301.3H983.8L987.3 307.3L983.8 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 298.2H976.8L973.4 292.2L976.8 286.2H983.8L987.3 292.2L983.8 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 283.2H976.8L973.4 277.1L976.8 271.1H983.8L987.3 277.1L983.8 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 268.1H976.8L973.4 262.1L976.8 256H983.8L987.3 262.1L983.8 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 253H976.8L973.4 247L976.8 241H983.8L987.3 247L983.8 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 238H976.8L973.4 231.9L976.8 225.9H983.8L987.3 231.9L983.8 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 230.4H963.8L960.3 224.4L963.8 218.4H970.8L974.2 224.4L970.8 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 222.9H950.7L947.3 216.9L950.7 210.8H957.7L961.2 216.9L957.7 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 215.3H937.7L934.2 209.3L937.7 203.3H944.7L948.1 209.3L944.7 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 207.8H924.6L921.2 201.8L924.6 195.8H931.6L935.1 201.8L931.6 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 200.3H911.6L908.1 194.2L911.6 188.2H918.5L922 194.2L918.5 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 192.7H898.5L895.1 186.7L898.5 180.7H905.5L909 186.7L905.5 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 185.2H885.5L882 179.2L885.5 173.1H892.4L895.9 179.2L892.4 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 177.7H872.4L869 171.6L872.4 165.6H879.4L882.9 171.6L879.4 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 170.1H859.4L855.9 164.1L859.4 158.1H866.3L869.8 164.1L866.3 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 162.6H846.3L842.8 156.6L846.3 150.5H853.3L856.8 156.6L853.3 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M840.2 155.1H833.3L829.8 149L833.3 143H840.2L843.7 149L840.2 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M827.1 147.4H820.3L816.9 141.5L820.3 135.6H827.1L830.5 141.5L827.1 147.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M813.9 139.6H807.4L804.1 134L807.4 128.3H813.9L817.2 134L813.9 139.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M800.9 132.2H794.3L791 126.4L794.3 120.7H800.9L804.2 126.4L800.9 132.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M787.3 123.7H781.8L779 118.9L781.8 114H787.3L790.1 118.9L787.3 123.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M774.3 116.2H768.7L765.9 111.4L768.7 106.5H774.3L777.1 111.4L774.3 116.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M760.5 107.4H756.4L754.3 103.8L756.4 100.2H760.5L762.6 103.8L760.5 107.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M577.4 136.9H574L572.4 134L574 131.1H577.4L579.1 134L577.4 136.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M485.9 189.3H482.9L481.4 186.7L482.9 184.1H485.9L487.4 186.7L485.9 189.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M421.4 228.5H416.8L414.4 224.4L416.8 220.3H421.4L423.8 224.4L421.4 228.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 238H402.6L399.1 231.9L402.6 225.9H409.5L413 231.9L409.5 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M408 250.4H404.1L402.2 247L404.1 243.6H408L409.9 247L408 250.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M407.3 475.2H404.8L403.6 473.1L404.8 470.9H407.3L408.5 473.1L407.3 475.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 494.2H402.6L399.1 488.1L402.6 482.1H409.5L413 488.1L409.5 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 509.2H402.6L399.1 503.2L402.6 497.2H409.5L413 503.2L409.5 509.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 524.3H402.6L399.1 518.3L402.6 512.2H409.5L413 518.3L409.5 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 539.4H402.6L399.1 533.3L402.6 527.3H409.5L413 533.3L409.5 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 554.4H402.6L399.1 548.4L402.6 542.4H409.5L413 548.4L409.5 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 577H389.5L386 571L389.5 565H396.5L400 571L396.5 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 584.6H402.6L399.1 578.6L402.6 572.5H409.5L413 578.6L409.5 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 592.1H415.6L412.1 586.1L415.6 580.1H422.6L426.1 586.1L422.6 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 599.7H428.7L425.2 593.6L428.7 587.6H435.6L439.1 593.6L435.6 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 607.2H441.7L438.2 601.2L441.7 595.1H448.7L452.2 601.2L448.7 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 614.7H454.8L451.3 608.7L454.8 602.7H461.7L465.2 608.7L461.7 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 622.3H467.8L464.3 616.2L467.8 610.2H474.8L478.3 616.2L474.8 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M995.9 469.9H990.8L988.3 465.5L990.8 461.1H995.9L998.5 465.5L995.9 469.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 456.5H989.9L986.4 450.5L989.9 444.4H996.9L1000.3 450.5L996.9 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 441.4H989.9L986.4 435.4L989.9 429.4H996.9L1000.3 435.4L996.9 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 426.3H989.9L986.4 420.3L989.9 414.3H996.9L1000.3 420.3L996.9 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 411.3H989.9L986.4 405.2L989.9 399.2H996.9L1000.3 405.2L996.9 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 396.2H989.9L986.4 390.2L989.9 384.1H996.9L1000.3 390.2L996.9 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 381.1H989.9L986.4 375.1L989.9 369.1H996.9L1000.3 375.1L996.9 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M961.5 426.1H954.5L951 420L954.5 414H961.5L964.9 420L961.5 426.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 351H989.9L986.4 345L989.9 338.9H996.9L1000.3 345L996.9 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 335.9H989.9L986.4 329.9L989.9 323.9H996.9L1000.3 329.9L996.9 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 320.8H989.9L986.4 314.8L989.9 308.8H996.9L1000.3 314.8L996.9 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 305.8H989.9L986.4 299.7L989.9 293.7H996.9L1000.3 299.7L996.9 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 290.7H989.9L986.4 284.7L989.9 278.6H996.9L1000.3 284.7L996.9 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 275.6H989.9L986.4 269.6L989.9 263.6H996.9L1000.3 269.6L996.9 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 260.6H989.9L986.4 254.5L989.9 248.5H996.9L1000.3 254.5L996.9 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 245.5H989.9L986.4 239.5L989.9 233.4H996.9L1000.3 239.5L996.9 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 230.4H989.9L986.4 224.4L989.9 218.4H996.9L1000.3 224.4L996.9 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 222.9H976.8L973.4 216.9L976.8 210.8H983.8L987.3 216.9L983.8 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 215.3H963.8L960.3 209.3L963.8 203.3H970.8L974.2 209.3L970.8 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 207.8H950.7L947.3 201.8L950.7 195.8H957.7L961.2 201.8L957.7 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 200.3H937.7L934.2 194.2L937.7 188.2H944.7L948.1 194.2L944.7 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 192.7H924.6L921.2 186.7L924.6 180.7H931.6L935.1 186.7L931.6 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 185.2H911.6L908.1 179.2L911.6 173.1H918.5L922 179.2L918.5 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 177.7H898.5L895.1 171.6L898.5 165.6H905.5L909 171.6L905.5 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 170.1H885.5L882 164.1L885.5 158.1H892.4L895.9 164.1L892.4 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 162.6H872.4L869 156.6L872.4 150.5H879.4L882.9 156.6L879.4 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 155.1H859.4L855.9 149L859.4 143H866.3L869.8 149L866.3 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M853.3 147.5H846.3L842.8 141.5L846.3 135.5H853.3L856.8 141.5L853.3 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M839 137.8H834.5L832.3 134L834.5 130.1H839L841.2 134L839 137.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M826.7 131.6H820.7L817.8 126.4L820.7 121.3H826.7L829.7 126.4L826.7 131.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M722.8 72.2001H715.8L712.4 66.1001L715.8 60.1001H722.8L726.2 66.1001L722.8 72.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M708.4 62.2999H704.1L701.9 58.5999L704.1 54.8999H708.4L710.6 58.5999L708.4 62.2999Z'
          fill='#DFDFDF'
        />
        <path
          d='M694.7 53.7H691.7L690.2 51.1L691.7 48.5H694.7L696.2 51.1L694.7 53.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M498.2 165.5H496.6L495.9 164.1L496.6 162.8H498.2L499 164.1L498.2 165.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 177.7H480.9L477.4 171.6L480.9 165.6H487.8L491.3 171.6L487.8 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.4 184.5H468.2L465.1 179.2L468.2 173.8H474.4L477.5 179.2L474.4 184.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 222.9H402.6L399.1 216.9L402.6 210.8H409.5L413 216.9L409.5 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 230.4H389.5L386 224.4L389.5 218.4H396.5L400 224.4L396.5 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 245.5H389.5L386 239.5L389.5 233.4H396.5L400 239.5L396.5 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.4 260.5H389.6L386.1 254.5L389.6 248.6H396.4L399.9 254.5L396.4 260.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M396 274.8H390L387 269.6L390 264.4H396L398.9 269.6L396 274.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.3 471.2H389.7L386.4 465.5L389.7 459.8H396.3L399.6 465.5L396.3 471.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 486.6H389.5L386 480.6L389.5 474.6H396.5L400 480.6L396.5 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 501.7H389.5L386 495.7L389.5 489.6H396.5L400 495.7L396.5 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 516.8H389.5L386 510.7L389.5 504.7H396.5L400 510.7L396.5 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 531.8H389.5L386 525.8L389.5 519.8H396.5L400 525.8L396.5 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 546.9H389.5L386 540.9L389.5 534.8H396.5L400 540.9L396.5 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 562H389.5L386 555.9L389.5 549.9H396.5L400 555.9L396.5 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 584.6H376.5L373 578.6L376.5 572.5H383.4L386.9 578.6L383.4 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 592.1H389.5L386 586.1L389.5 580.1H396.5L400 586.1L396.5 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 599.7H402.6L399.1 593.6L402.6 587.6H409.5L413 593.6L409.5 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 607.2H415.6L412.1 601.2L415.6 595.1H422.6L426.1 601.2L422.6 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 614.7H428.7L425.2 608.7L428.7 602.7H435.6L439.1 608.7L435.6 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 622.3H441.7L438.2 616.2L441.7 610.2H448.7L452.2 616.2L448.7 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 629.8H454.8L451.3 623.8L454.8 617.8H461.7L465.2 623.8L461.7 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1008.9 477.3H1004L1001.5 473.1L1004 468.8H1008.9L1011.4 473.1L1008.9 477.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.4 433H1003.4L1000.5 427.8L1003.4 422.7H1009.4L1012.4 427.8L1009.4 433Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 418.8H1002.9L999.5 412.8L1002.9 406.7H1009.9L1013.4 412.8L1009.9 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 403.7H1002.9L999.5 397.7L1002.9 391.7H1009.9L1013.4 397.7L1009.9 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 388.7H1002.9L999.5 382.6L1002.9 376.6H1009.9L1013.4 382.6L1009.9 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 373.6H1002.9L999.5 367.6L1002.9 361.5H1009.9L1013.4 367.6L1009.9 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 358.5H1002.9L999.5 352.5L1002.9 346.5H1009.9L1013.4 352.5L1009.9 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 343.4H1002.9L999.5 337.4L1002.9 331.4H1009.9L1013.4 337.4L1009.9 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 328.4H1002.9L999.5 322.4L1002.9 316.3H1009.9L1013.4 322.4L1009.9 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 313.3H1002.9L999.5 307.3L1002.9 301.3H1009.9L1013.4 307.3L1009.9 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 298.2H1002.9L999.5 292.2L1002.9 286.2H1009.9L1013.4 292.2L1009.9 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 283.2H1002.9L999.5 277.1L1002.9 271.1H1009.9L1013.4 277.1L1009.9 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 268.1H1002.9L999.5 262.1L1002.9 256H1009.9L1013.4 262.1L1009.9 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 253H1002.9L999.5 247L1002.9 241H1009.9L1013.4 247L1009.9 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 238H1002.9L999.5 231.9L1002.9 225.9H1009.9L1013.4 231.9L1009.9 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 222.9H1002.9L999.5 216.9L1002.9 210.8H1009.9L1013.4 216.9L1009.9 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 215.3H989.9L986.4 209.3L989.9 203.3H996.9L1000.3 209.3L996.9 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 207.8H976.8L973.4 201.8L976.8 195.8H983.8L987.3 201.8L983.8 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 200.3H963.8L960.3 194.2L963.8 188.2H970.8L974.2 194.2L970.8 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 192.7H950.7L947.3 186.7L950.7 180.7H957.7L961.2 186.7L957.7 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 185.2H937.7L934.2 179.2L937.7 173.1H944.7L948.1 179.2L944.7 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 177.7H924.6L921.2 171.6L924.6 165.6H931.6L935.1 171.6L931.6 177.7Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-fifth'
        />
        <path
          d='M918.5 170.1H911.6L908.1 164.1L911.6 158.1H918.5L922 164.1L918.5 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 162.6H898.5L895.1 156.6L898.5 150.5H905.5L909 156.6L905.5 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 155.1H885.5L882 149L885.5 143H892.4L895.9 149L892.4 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 147.5H872.4L869 141.5L872.4 135.5H879.4L882.9 141.5L879.4 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M866.3 140H859.4L855.9 134L859.4 127.9H866.3L869.8 134L866.3 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M852.2 130.5H847.5L845.1 126.4L847.5 122.4H852.2L854.5 126.4L852.2 130.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M746.1 67.3H744.7L744 66.1L744.7 65H746.1L746.8 66.1L746.1 67.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M733.7 61H730.9L729.5 58.5999L730.9 56.2H733.7L735.2 58.5999L733.7 61Z'
          fill='#DFDFDF'
        />
        <path
          d='M709.7 49.6H702.8L699.3 43.5L702.8 37.5H709.7L713.2 43.5L709.7 49.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M696.7 42H689.7L686.2 36L689.7 30H696.7L700.1 36L696.7 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M577 106H574.5L573.2 103.8L574.5 101.6H577L578.2 103.8L577 106Z'
          fill='#DFDFDF'
        />
        <path
          d='M564.8 115H560.6L558.4 111.4L560.6 107.7H564.8L566.9 111.4L564.8 115Z'
          fill='#DFDFDF'
        />
        <path
          d='M553.1 124.9H546.2L542.7 118.9L546.2 112.9H553.1L556.5 118.9L553.1 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M540 132.5H533.1L529.6 126.4L533.1 120.4H540L543.5 126.4L540 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M527 140H520L516.6 134L520 128H527L530.4 134L527 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M513.7 147.1H507.3L504 141.5L507.3 135.9H513.7L516.9 141.5L513.7 147.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.5 154.4H494.3L491.2 149L494.3 143.6H500.5L503.7 149L500.5 154.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 162.6H480.9L477.4 156.6L480.9 150.5H487.8L491.3 156.6L487.8 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 170.1H467.8L464.3 164.1L467.8 158.1H474.8L478.3 164.1L474.8 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M460.9 176.2H455.6L453 171.6L455.6 167.1H460.9L463.5 171.6L460.9 176.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.4 207.6H402.7L399.3 201.8L402.7 196H409.4L412.8 201.8L409.4 207.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 215.3H389.5L386 209.3L389.5 203.3H396.5L400 209.3L396.5 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 222.9H376.5L373 216.9L376.5 210.8H383.4L386.9 216.9L383.4 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 238H376.5L373 231.9L376.5 225.9H383.4L386.9 231.9L383.4 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 253H376.5L373 247L376.5 241H383.4L386.9 247L383.4 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 268.1H376.5L373 262.1L376.5 256H383.4L386.9 262.1L383.4 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M382.2 281H377.7L375.5 277.1L377.7 273.3H382.2L384.4 277.1L382.2 281Z'
          fill='#DFDFDF'
        />
        <path
          d='M382.8 463H377.1L374.2 458L377.1 453H382.8L385.7 458L382.8 463Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 479.1H376.5L373 473.1L376.5 467H383.4L386.9 473.1L383.4 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 494.2H376.5L373 488.1L376.5 482.1H383.4L386.9 488.1L383.4 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 509.2H376.5L373 503.2L376.5 497.2H383.4L386.9 503.2L383.4 509.2Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-sixth'
        />
        <path
          d='M383.4 524.3H376.5L373 518.3L376.5 512.2H383.4L386.9 518.3L383.4 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 539.4H376.5L373 533.3L376.5 527.3H383.4L386.9 533.3L383.4 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 554.4H376.5L373 548.4L376.5 542.4H383.4L386.9 548.4L383.4 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 569.5H376.5L373 563.5L376.5 557.5H383.4L386.9 563.5L383.4 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 592.1H363.4L359.9 586.1L363.4 580.1H370.4L373.9 586.1L370.4 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 599.7H376.5L373 593.6L376.5 587.6H383.4L386.9 593.6L383.4 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 607.2H389.5L386 601.2L389.5 595.1H396.5L400 601.2L396.5 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 614.7H402.6L399.1 608.7L402.6 602.7H409.5L413 608.7L409.5 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 622.3H415.6L412.1 616.2L415.6 610.2H422.6L426.1 616.2L422.6 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 629.8H428.7L425.2 623.8L428.7 617.7H435.6L439.1 623.8L435.6 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 637.3H441.7L438.2 631.3L441.7 625.3H448.7L452.2 631.3L448.7 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1020.7 422.4H1018.3L1017.1 420.3L1018.3 418.2H1020.7L1021.9 420.3L1020.7 422.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 411.3H1016L1012.5 405.2L1016 399.2H1023L1026.4 405.2L1023 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 396.2H1016L1012.5 390.2L1016 384.1H1023L1026.4 390.2L1023 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 381.1H1016L1012.5 375.1L1016 369.1H1023L1026.4 375.1L1023 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 366.1H1016L1012.5 360L1016 354H1023L1026.4 360L1023 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 351H1016L1012.5 345L1016 338.9H1023L1026.4 345L1023 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 335.9H1016L1012.5 329.9L1016 323.9H1023L1026.4 329.9L1023 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 320.8H1016L1012.5 314.8L1016 308.8H1023L1026.4 314.8L1023 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 305.8H1016L1012.5 299.7L1016 293.7H1023L1026.4 299.7L1023 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 290.7H1016L1012.5 284.7L1016 278.6H1023L1026.4 284.7L1023 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 275.6H1016L1012.5 269.6L1016 263.6H1023L1026.4 269.6L1023 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 260.6H1016L1012.5 254.5L1016 248.5H1023L1026.4 254.5L1023 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 245.5H1016L1012.5 239.5L1016 233.4H1023L1026.4 239.5L1023 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 230.4H1016L1012.5 224.4L1016 218.4H1023L1026.4 224.4L1023 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 215.3H1016L1012.5 209.3L1016 203.3H1023L1026.4 209.3L1023 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 207.8H1002.9L999.5 201.8L1002.9 195.8H1009.9L1013.4 201.8L1009.9 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 200.3H989.9L986.4 194.2L989.9 188.2H996.9L1000.3 194.2L996.9 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 192.7H976.8L973.4 186.7L976.8 180.7H983.8L987.3 186.7L983.8 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 185.2H963.8L960.3 179.2L963.8 173.1H970.8L974.2 179.2L970.8 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 177.7H950.7L947.3 171.6L950.7 165.6H957.7L961.2 171.6L957.7 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 170.1H937.7L934.2 164.1L937.7 158.1H944.7L948.1 164.1L944.7 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 162.6H924.6L921.2 156.6L924.6 150.5H931.6L935.1 156.6L931.6 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 155.1H911.6L908.1 149L911.6 143H918.5L922 149L918.5 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 147.5H898.5L895.1 141.5L898.5 135.5H905.5L909 141.5L905.5 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 140H885.5L882 134L885.5 127.9H892.4L895.9 134L892.4 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M879.4 132.5H872.4L869 126.4L872.4 120.4H879.4L882.9 126.4L879.4 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M863.9 120.8H861.8L860.7 118.9L861.8 117H863.9L865 118.9L863.9 120.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M746.7 53.2999H744.1L742.9 51.0999L744.1 48.8999H746.7L747.9 51.0999L746.7 53.2999Z'
          fill='#DFDFDF'
        />
        <path
          d='M734.7 47.6H730L727.7 43.5L730 39.5H734.7L737 43.5L734.7 47.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M721 39H717.5L715.8 36L717.5 33H721L722.8 36L721 39Z'
          fill='#DFDFDF'
        />
        <path
          d='M707 29.7H705.5L704.8 28.5L705.5 27.2H707L707.7 28.5L707 29.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M579.1 94.5999H572.4L569 88.7999L572.4 82.8999H579.1L582.4 88.7999L579.1 94.5999Z'
          fill='#DFDFDF'
        />
        <path
          d='M565 100.3H560.4L558.1 96.3L560.4 92.3H565L567.3 96.3L565 100.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M552.3 108.5H546.9L544.2 103.8L546.9 99.1001H552.3L555.1 103.8L552.3 108.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M540 117.4H533.1L529.6 111.4L533.1 105.3H540L543.5 111.4L540 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M527 124.9H520L516.6 118.9L520 112.9H527L530.5 118.9L527 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M513.9 132.5H507L503.5 126.4L507 120.4H513.9L517.4 126.4L513.9 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.9 140H493.9L490.5 134L493.9 127.9H500.9L504.4 134L500.9 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 147.5H480.9L477.4 141.5L480.9 135.5H487.8L491.3 141.5L487.8 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 155.1H467.8L464.3 149L467.8 143H474.8L478.3 149L474.8 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.1 161.5H455.4L452.6 156.6L455.4 151.7H461.1L463.9 156.6L461.1 161.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M406.6 187.7H405.5L404.9 186.7L405.5 185.7H406.6L407.2 186.7L406.6 187.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M396 199.4H390L387 194.2L390 189.1H396L398.9 194.2L396 199.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 207.8H376.5L373 201.8L376.5 195.8H383.4L386.9 201.8L383.4 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 215.3H363.4L359.9 209.3L363.4 203.3H370.4L373.9 209.3L370.4 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 230.4H363.4L359.9 224.4L363.4 218.4H370.4L373.9 224.4L370.4 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 245.5H363.4L359.9 239.5L363.4 233.4H370.4L373.9 239.5L370.4 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 260.6H363.4L359.9 254.5L363.4 248.5H370.4L373.9 254.5L370.4 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 275.6H363.4L359.9 269.6L363.4 263.6H370.4L373.9 269.6L370.4 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M368.5 287.5H365.3L363.6 284.7L365.3 281.8H368.5L370.2 284.7L368.5 287.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M367.6 300.9H366.2L365.5 299.7L366.2 298.6H367.6L368.3 299.7L367.6 300.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M368.9 423.8H364.9L362.9 420.3L364.9 416.8H368.9L370.9 420.3L368.9 423.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M367.9 452.1H365.9L365 450.5L365.9 448.8H367.9L368.8 450.5L367.9 452.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 471.6H363.4L359.9 465.5L363.4 459.5H370.4L373.9 465.5L370.4 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 486.6H363.4L359.9 480.6L363.4 474.6H370.4L373.9 480.6L370.4 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 501.7H363.4L359.9 495.7L363.4 489.6H370.4L373.9 495.7L370.4 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 516.8H363.4L359.9 510.7L363.4 504.7H370.4L373.9 510.7L370.4 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 531.8H363.4L359.9 525.8L363.4 519.8H370.4L373.9 525.8L370.4 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 546.9H363.4L359.9 540.9L363.4 534.8H370.4L373.9 540.9L370.4 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 562H363.4L359.9 555.9L363.4 549.9H370.4L373.9 555.9L370.4 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 577H363.4L359.9 571L363.4 565H370.4L373.9 571L370.4 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 599.7H350.4L346.9 593.6L350.4 587.6H357.3L360.8 593.6L357.3 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 607.2H363.4L359.9 601.2L363.4 595.1H370.4L373.9 601.2L370.4 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 614.7H376.5L373 608.7L376.5 602.7H383.4L386.9 608.7L383.4 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 622.3H389.5L386 616.2L389.5 610.2H396.5L400 616.2L396.5 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 629.8H402.6L399.1 623.8L402.6 617.7H409.5L413 623.8L409.5 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 637.3H415.6L412.1 631.3L415.6 625.3H422.6L426.1 631.3L422.6 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 644.9H428.7L425.2 638.8L428.7 632.8H435.6L439.1 638.8L435.6 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 652.4H441.7L438.2 646.4L441.7 640.3H448.7L452.2 646.4L448.7 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 403.7H1029.1L1025.6 397.7L1029.1 391.7H1036L1039.5 397.7L1036 403.7Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-seventh'
        />
        <path
          d='M1036 388.7H1029.1L1025.6 382.6L1029.1 376.6H1036L1039.5 382.6L1036 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 373.6H1029.1L1025.6 367.6L1029.1 361.5H1036L1039.5 367.6L1036 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 358.5H1029.1L1025.6 352.5L1029.1 346.5H1036L1039.5 352.5L1036 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 343.4H1029.1L1025.6 337.4L1029.1 331.4H1036L1039.5 337.4L1036 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 328.4H1029.1L1025.6 322.4L1029.1 316.3H1036L1039.5 322.4L1036 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 313.3H1029.1L1025.6 307.3L1029.1 301.3H1036L1039.5 307.3L1036 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 298.2H1029.1L1025.6 292.2L1029.1 286.2H1036L1039.5 292.2L1036 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 283.2H1029.1L1025.6 277.1L1029.1 271.1H1036L1039.5 277.1L1036 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 268.1H1029.1L1025.6 262.1L1029.1 256H1036L1039.5 262.1L1036 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 253H1029.1L1025.6 247L1029.1 241H1036L1039.5 247L1036 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 238H1029.1L1025.6 231.9L1029.1 225.9H1036L1039.5 231.9L1036 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 222.9H1029.1L1025.6 216.9L1029.1 210.8H1036L1039.5 216.9L1036 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 207.8H1029.1L1025.6 201.8L1029.1 195.8H1036L1039.5 201.8L1036 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 200.3H1016L1012.5 194.2L1016 188.2H1023L1026.4 194.2L1023 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 192.7H1002.9L999.5 186.7L1002.9 180.7H1009.9L1013.4 186.7L1009.9 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 185.2H989.9L986.4 179.2L989.9 173.1H996.9L1000.3 179.2L996.9 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 177.7H976.8L973.4 171.6L976.8 165.6H983.8L987.3 171.6L983.8 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 170.1H963.8L960.3 164.1L963.8 158.1H970.8L974.2 164.1L970.8 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 162.6H950.7L947.3 156.6L950.7 150.5H957.7L961.2 156.6L957.7 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 155.1H937.7L934.2 149L937.7 143H944.7L948.1 149L944.7 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 147.5H924.6L921.2 141.5L924.6 135.5H931.6L935.1 141.5L931.6 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 140H911.6L908.1 134L911.6 127.9H918.5L922 134L918.5 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.5 132.5H898.5L895.1 126.4L898.5 120.4H905.5L909 126.4L905.5 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M892.4 124.9H885.5L882 118.9L885.5 112.9H892.4L895.9 118.9L892.4 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M876.8 113H875L874.1 111.4L875 109.8H876.8L877.8 111.4L876.8 113Z'
          fill='#DFDFDF'
        />
        <path
          d='M865.8 109H859.9L856.9 103.8L859.9 98.7H865.8L868.8 103.8L865.8 109Z'
          fill='#DFDFDF'
        />
        <path
          d='M850.9 98.0999H848.7L847.7 96.2999L848.7 94.3999H850.9L851.9 96.2999L850.9 98.0999Z'
          fill='#DFDFDF'
        />
        <path
          d='M748.3 41H742.5L739.6 36L742.5 31H748.3L751.2 36L748.3 41Z'
          fill='#DFDFDF'
        />
        <path
          d='M735.3 33.5999H729.4L726.4 28.4999L729.4 23.3999H735.3L738.2 28.4999L735.3 33.5999Z'
          fill='#DFDFDF'
        />
        <path
          d='M589.5 67.4H588L587.3 66.1L588 64.8H589.5L590.3 66.1L589.5 67.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M579.2 79.6H572.3L568.8 73.7L572.3 67.7H579.2L582.6 73.7L579.2 79.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M566.2 87.2H559.2L555.7 81.2L559.2 75.2H566.2L569.6 81.2L566.2 87.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M553.1 94.8H546.1L542.7 88.8L546.1 82.7H553.1L556.6 88.8L553.1 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M540 102.3H533.1L529.6 96.3L533.1 90.3H540L543.5 96.3L540 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M527 109.9H520L516.6 103.8L520 97.8H527L530.5 103.8L527 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M513.9 117.4H507L503.5 111.4L507 105.3H513.9L517.4 111.4L513.9 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.9 124.9H493.9L490.5 118.9L493.9 112.9H500.9L504.4 118.9L500.9 124.9Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-eighth'
        />
        <path
          d='M487.8 132.5H480.9L477.4 126.4L480.9 120.4H487.8L491.3 126.4L487.8 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 140H467.8L464.3 134L467.8 127.9H474.8L478.3 134L474.8 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 147.5H454.8L451.3 141.5L454.8 135.5H461.7L465.2 141.5L461.7 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M383 191.9H376.9L373.9 186.7L376.9 181.5H383L386 186.7L383 191.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 200.3H363.4L359.9 194.2L363.4 188.2H370.4L373.9 194.2L370.4 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 207.8H350.4L346.9 201.8L350.4 195.8H357.3L360.8 201.8L357.3 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 222.9H350.4L346.9 216.9L350.4 210.8H357.3L360.8 216.9L357.3 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 238H350.4L346.9 231.9L350.4 225.9H357.3L360.8 231.9L357.3 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 253H350.4L346.9 247L350.4 241H357.3L360.8 247L357.3 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 268.1H350.4L346.9 262.1L350.4 256H357.3L360.8 262.1L357.3 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 283.2H350.4L346.9 277.1L350.4 271.1H357.3L360.8 277.1L357.3 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 298.2H350.4L346.9 292.2L350.4 286.2H357.3L360.8 292.2L357.3 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M354.9 399.5H352.8L351.8 397.7L352.8 395.9H354.9L355.9 397.7L354.9 399.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M356.9 418.1H350.8L347.7 412.8L350.8 407.4H356.9L360 412.8L356.9 418.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 464H350.4L346.9 458L350.4 452H357.3L360.8 458L357.3 464Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 479.1H350.4L346.9 473.1L350.4 467H357.3L360.8 473.1L357.3 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 494.2H350.4L346.9 488.1L350.4 482.1H357.3L360.8 488.1L357.3 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 509.2H350.4L346.9 503.2L350.4 497.2H357.3L360.8 503.2L357.3 509.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 524.3H350.4L346.9 518.3L350.4 512.2H357.3L360.8 518.3L357.3 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 539.4H350.4L346.9 533.3L350.4 527.3H357.3L360.8 533.3L357.3 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 554.4H350.4L346.9 548.4L350.4 542.4H357.3L360.8 548.4L357.3 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 569.5H350.4L346.9 563.5L350.4 557.5H357.3L360.8 563.5L357.3 569.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 584.6H350.4L346.9 578.6L350.4 572.5H357.3L360.8 578.6L357.3 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M356.9 613.9H350.8L347.8 608.7L350.8 603.5H356.9L359.9 608.7L356.9 613.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 622.3H363.4L359.9 616.2L363.4 610.2H370.4L373.9 616.2L370.4 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 629.8H376.5L373 623.8L376.5 617.7H383.4L386.9 623.8L383.4 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 637.3H389.5L386 631.3L389.5 625.3H396.5L400 631.3L396.5 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 644.9H402.6L399.1 638.8L402.6 632.8H409.5L413 638.8L409.5 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 652.4H415.6L412.1 646.4L415.6 640.3H422.6L426.1 646.4L422.6 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 659.9H428.7L425.2 653.9L428.7 647.9H435.6L439.1 653.9L435.6 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M446.7 664H443.7L442.2 661.4L443.7 658.8H446.7L448.2 661.4L446.7 664Z'
          fill='#DFDFDF'
        />
        <path
          d='M1047.7 409H1043.4L1041.3 405.2L1043.4 401.5H1047.7L1049.9 405.2L1047.7 409Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 396.2H1042.1L1038.6 390.2L1042.1 384.1H1049.1L1052.5 390.2L1049.1 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 381.1H1042.1L1038.6 375.1L1042.1 369.1H1049.1L1052.5 375.1L1049.1 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 366.1H1042.1L1038.6 360L1042.1 354H1049.1L1052.5 360L1049.1 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 351H1042.1L1038.6 345L1042.1 338.9H1049.1L1052.5 345L1049.1 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 335.9H1042.1L1038.6 329.9L1042.1 323.9H1049.1L1052.5 329.9L1049.1 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 320.8H1042.1L1038.6 314.8L1042.1 308.8H1049.1L1052.5 314.8L1049.1 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 305.8H1042.1L1038.6 299.7L1042.1 293.7H1049.1L1052.5 299.7L1049.1 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 290.7H1042.1L1038.6 284.7L1042.1 278.6H1049.1L1052.5 284.7L1049.1 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 275.6H1042.1L1038.6 269.6L1042.1 263.6H1049.1L1052.5 269.6L1049.1 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 260.6H1042.1L1038.6 254.5L1042.1 248.5H1049.1L1052.5 254.5L1049.1 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 245.5H1042.1L1038.6 239.5L1042.1 233.4H1049.1L1052.5 239.5L1049.1 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 230.4H1042.1L1038.6 224.4L1042.1 218.4H1049.1L1052.5 224.4L1049.1 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 215.3H1042.1L1038.6 209.3L1042.1 203.3H1049.1L1052.5 209.3L1049.1 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 200.3H1042.1L1038.6 194.2L1042.1 188.2H1049.1L1052.5 194.2L1049.1 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 192.7H1029.1L1025.6 186.7L1029.1 180.7H1036L1039.5 186.7L1036 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 185.2H1016L1012.5 179.2L1016 173.1H1023L1026.4 179.2L1023 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 177.7H1002.9L999.5 171.6L1002.9 165.6H1009.9L1013.4 171.6L1009.9 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 170.1H989.9L986.4 164.1L989.9 158.1H996.9L1000.3 164.1L996.9 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 162.6H976.8L973.4 156.6L976.8 150.5H983.8L987.3 156.6L983.8 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 155.1H963.8L960.3 149L963.8 143H970.8L974.2 149L970.8 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 147.5H950.7L947.3 141.5L950.7 135.5H957.7L961.2 141.5L957.7 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.7 140H937.7L934.2 134L937.7 127.9H944.7L948.1 134L944.7 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.6 132.5H924.6L921.2 126.4L924.6 120.4H931.6L935.1 126.4L931.6 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.5 124.9H911.6L908.1 118.9L911.6 112.9H918.5L922 118.9L918.5 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M903.6 114.1H900.4L898.8 111.4L900.4 108.6H903.6L905.2 111.4L903.6 114.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M865.2 92.7H860.6L858.2 88.8L860.6 84.8H865.2L867.5 88.8L865.2 92.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M772.3 37.4001H770.7L769.9 36.0001L770.7 34.6001H772.3L773.1 36.0001L772.3 37.4001Z'
          fill='#DFDFDF'
        />
        <path
          d='M761.5 33.8001H755.4L752.3 28.5001L755.4 23.1001H761.5L764.6 28.5001L761.5 33.8001Z'
          fill='#DFDFDF'
        />
        <path
          d='M746.2 22.3H744.6L743.8 20.9L744.6 19.5H746.2L747 20.9L746.2 22.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M589.8 52.9001H587.7L586.7 51.1L587.7 49.3H589.8L590.9 51.1L589.8 52.9001Z'
          fill='#DFDFDF'
        />
        <path
          d='M579.2 64.6001H572.2L568.8 58.6001L572.2 52.6001H579.2L582.7 58.6001L579.2 64.6001Z'
          fill='#DFDFDF'
        />
        <path
          d='M566.2 72.2001H559.2L555.7 66.1001L559.2 60.1001H566.2L569.6 66.1001L566.2 72.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M553.1 79.7H546.1L542.7 73.7L546.1 67.7H553.1L556.6 73.7L553.1 79.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M540 87.2H533.1L529.6 81.2L533.1 75.2H540L543.5 81.2L540 87.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M527 94.8H520L516.6 88.8L520 82.7H527L530.5 88.8L527 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M513.9 102.3H507L503.5 96.3L507 90.3H513.9L517.4 96.3L513.9 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.9 109.9H493.9L490.5 103.8L493.9 97.8H500.9L504.4 103.8L500.9 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 117.4H480.9L477.4 111.4L480.9 105.3H487.8L491.3 111.4L487.8 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 124.9H467.8L464.3 118.9L467.8 112.9H474.8L478.3 118.9L474.8 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M460.4 130.2H456.1L453.9 126.4L456.1 122.7H460.4L462.6 126.4L460.4 130.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M406.9 158H405.2L404.4 156.6L405.2 155.2H406.9L407.7 156.6L406.9 158Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 170.1H389.5L386 164.1L389.5 158.1H396.5L400 164.1L396.5 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M381.3 173.9H378.6L377.3 171.6L378.6 169.4H381.3L382.6 171.6L381.3 173.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 185.2H363.4L359.9 179.2L363.4 173.1H370.4L373.9 179.2L370.4 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 192.7H350.4L346.9 186.7L350.4 180.7H357.3L360.8 186.7L357.3 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M343.2 213.5H338.4L335.9 209.3L338.4 205.1H343.2L345.7 209.3L343.2 213.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.1 230.1H337.5L334.2 224.4L337.5 218.7H344.1L347.4 224.4L344.1 230.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 245.5H337.3L333.8 239.5L337.3 233.4H344.3L347.8 239.5L344.3 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 260.6H337.3L333.8 254.5L337.3 248.5H344.3L347.8 254.5L344.3 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 275.6H337.3L333.8 269.6L337.3 263.6H344.3L347.8 269.6L344.3 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 290.7H337.3L333.8 284.7L337.3 278.6H344.3L347.8 284.7L344.3 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 305.8H337.3L333.8 299.7L337.3 293.7H344.3L347.8 299.7L344.3 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.2 320.7H337.4L334 314.8L337.4 309H344.2L347.5 314.8L344.2 320.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M341.4 330.9H340.2L339.6 329.9L340.2 328.9H341.4L342 329.9L341.4 330.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M342.1 392.4H339.5L338.2 390.2L339.5 387.9H342.1L343.4 390.2L342.1 392.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M341.7 406.9H339.8L338.9 405.2L339.8 403.6H341.7L342.7 405.2L341.7 406.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M342.7 423.7H338.9L336.9 420.3L338.9 417H342.7L344.7 420.3L342.7 423.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.1 456.2H337.5L334.2 450.5L337.5 444.7H344.1L347.4 450.5L344.1 456.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 471.6H337.3L333.8 465.5L337.3 459.5H344.3L347.8 465.5L344.3 471.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 486.6H337.3L333.8 480.6L337.3 474.6H344.3L347.8 480.6L344.3 486.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 501.7H337.3L333.8 495.7L337.3 489.6H344.3L347.8 495.7L344.3 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 516.8H337.3L333.8 510.7L337.3 504.7H344.3L347.8 510.7L344.3 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 531.8H337.3L333.8 525.8L337.3 519.8H344.3L347.8 525.8L344.3 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 546.9H337.3L333.8 540.9L337.3 534.8H344.3L347.8 540.9L344.3 546.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 562H337.3L333.8 555.9L337.3 549.9H344.3L347.8 555.9L344.3 562Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 577H337.3L333.8 571L337.3 565H344.3L347.8 571L344.3 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 592.1H337.3L333.8 586.1L337.3 580.1H344.3L347.8 586.1L344.3 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M356.7 628.7H351L348.2 623.8L351 618.9H356.7L359.5 623.8L356.7 628.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 637.3H363.4L359.9 631.3L363.4 625.3H370.4L373.9 631.3L370.4 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 644.9H376.5L373 638.8L376.5 632.8H383.4L386.9 638.8L383.4 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 652.4H389.5L386 646.4L389.5 640.3H396.5L400 646.4L396.5 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 659.9H402.6L399.1 653.9L402.6 647.9H409.5L413 653.9L409.5 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 667.5H415.6L412.1 661.4L415.6 655.4H422.6L426.1 661.4L422.6 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 674.9H428.7L425.3 669L428.7 663H435.6L439 669L435.6 674.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1061.5 432.9H1055.7L1052.8 427.8L1055.7 422.8H1061.5L1064.4 427.8L1061.5 432.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 418.8H1055.2L1051.7 412.8L1055.2 406.7H1062.1L1065.6 412.8L1062.1 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 403.7H1055.2L1051.7 397.7L1055.2 391.7H1062.1L1065.6 397.7L1062.1 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 388.7H1055.2L1051.7 382.6L1055.2 376.6H1062.1L1065.6 382.6L1062.1 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 373.6H1055.2L1051.7 367.6L1055.2 361.5H1062.1L1065.6 367.6L1062.1 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 358.5H1055.2L1051.7 352.5L1055.2 346.5H1062.1L1065.6 352.5L1062.1 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 343.4H1055.2L1051.7 337.4L1055.2 331.4H1062.1L1065.6 337.4L1062.1 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 328.4H1055.2L1051.7 322.4L1055.2 316.3H1062.1L1065.6 322.4L1062.1 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 313.3H1055.2L1051.7 307.3L1055.2 301.3H1062.1L1065.6 307.3L1062.1 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 298.2H1055.2L1051.7 292.2L1055.2 286.2H1062.1L1065.6 292.2L1062.1 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 283.2H1055.2L1051.7 277.1L1055.2 271.1H1062.1L1065.6 277.1L1062.1 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 268.1H1055.2L1051.7 262.1L1055.2 256H1062.1L1065.6 262.1L1062.1 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 253H1055.2L1051.7 247L1055.2 241H1062.1L1065.6 247L1062.1 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 238H1055.2L1051.7 231.9L1055.2 225.9H1062.1L1065.6 231.9L1062.1 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 222.9H1055.2L1051.7 216.9L1055.2 210.8H1062.1L1065.6 216.9L1062.1 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 207.8H1055.2L1051.7 201.8L1055.2 195.8H1062.1L1065.6 201.8L1062.1 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 192.7H1055.2L1051.7 186.7L1055.2 180.7H1062.1L1065.6 186.7L1062.1 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 185.2H1042.1L1038.6 179.2L1042.1 173.1H1049.1L1052.5 179.2L1049.1 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 177.7H1029.1L1025.6 171.6L1029.1 165.6H1036L1039.5 171.6L1036 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 170.1H1016L1012.5 164.1L1016 158.1H1023L1026.4 164.1L1023 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 162.6H1002.9L999.5 156.6L1002.9 150.5H1009.9L1013.4 156.6L1009.9 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 155.1H989.9L986.4 149L989.9 143H996.9L1000.3 149L996.9 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 147.5H976.8L973.4 141.5L976.8 135.5H983.8L987.3 141.5L983.8 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 140H963.8L960.3 134L963.8 127.9H970.8L974.2 134L970.8 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 132.5H950.7L947.3 126.4L950.7 120.4H957.7L961.2 126.4L957.7 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M944.5 124.6H937.9L934.6 118.9L937.9 113.2H944.5L947.7 118.9L944.5 124.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M931.5 117.2H924.8L921.4 111.4L924.8 105.6H931.5L934.8 111.4L931.5 117.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M918.2 109.3H911.9L908.8 103.8L911.9 98.3999H918.2L921.4 103.8L918.2 109.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M878.9 86.3001H873L870 81.2001L873 76.1001H878.9L881.8 81.2001L878.9 86.3001Z'
          fill='#DFDFDF'
        />
        <path
          d='M863.4 74.7H862.3L861.7 73.7L862.3 72.7H863.4L864 73.7L863.4 74.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M605.1 34.2H598.5L595.2 28.5L598.5 22.7H605.1L608.5 28.5L605.1 34.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M592 41.7001H585.5L582.2 36L585.5 30.3H592L595.3 36L592 41.7001Z'
          fill='#DFDFDF'
        />
        <path
          d='M579.2 49.6H572.2L568.8 43.5L572.2 37.5H579.2L582.7 43.5L579.2 49.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M566.2 57.1H559.2L555.7 51.1L559.2 45H566.2L569.6 51.1L566.2 57.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M553.1 64.6001H546.1L542.7 58.6001L546.1 52.6001H553.1L556.6 58.6001L553.1 64.6001Z'
          fill='#DFDFDF'
        />
        <path
          d='M540 72.2001H533.1L529.6 66.1001L533.1 60.1001H540L543.5 66.1001L540 72.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M527 79.7H520L516.6 73.7L520 67.7H527L530.5 73.7L527 79.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M513.9 87.2H507L503.5 81.2L507 75.2H513.9L517.4 81.2L513.9 87.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.9 94.8H493.9L490.5 88.8L493.9 82.7H500.9L504.4 88.8L500.9 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 102.3H480.9L477.4 96.3L480.9 90.3H487.8L491.3 96.3L487.8 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 109.9H467.8L464.3 103.8L467.8 97.8H474.8L478.3 103.8L474.8 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.4 116.8H455.1L452 111.4L455.1 105.9H461.4L464.5 111.4L461.4 116.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M421.1 137.3H417.1L415.2 134L417.1 130.6H421.1L423 134L421.1 137.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 147.5H402.6L399.1 141.5L402.6 135.5H409.5L413 141.5L409.5 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 155.1H389.5L386 149L389.5 143H396.5L400 149L396.5 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 162.6H376.5L373 156.6L376.5 150.6H383.4L386.9 156.6L383.4 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 177.6H350.4L347 171.6L350.4 165.7H357.3L360.7 171.6L357.3 177.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M330 220.8H325.5L323.2 216.9L325.5 212.9H330L332.3 216.9L330 220.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.1 237.7H324.4L321.1 231.9L324.4 226.2H331.1L334.4 231.9L331.1 237.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 253H324.3L320.8 247L324.3 241H331.2L334.7 247L331.2 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 268.1H324.3L320.8 262.1L324.3 256H331.2L334.7 262.1L331.2 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 283.2H324.3L320.8 277.1L324.3 271.1H331.2L334.7 277.1L331.2 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 298.2H324.3L320.8 292.2L324.3 286.2H331.2L334.7 292.2L331.2 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 313.3H324.3L320.8 307.3L324.3 301.3H331.2L334.7 307.3L331.2 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 328.4H324.3L320.8 322.4L324.3 316.3H331.2L334.7 322.4L331.2 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M330.6 342.3H324.9L322.1 337.4L324.9 332.5H330.6L333.4 337.4L330.6 342.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M329.2 400.2H326.3L324.9 397.7L326.3 395.2H329.2L330.6 397.7L329.2 400.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M329 414.9H326.5L325.3 412.8L326.5 410.6H329L330.2 412.8L329 414.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M330.5 462.8H325L322.2 458L325 453.2H330.5L333.3 458L330.5 462.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 479.1H324.3L320.8 473.1L324.3 467H331.2L334.7 473.1L331.2 479.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 494.2H324.3L320.8 488.1L324.3 482.1H331.2L334.7 488.1L331.2 494.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 509.2H324.3L320.8 503.2L324.3 497.2H331.2L334.7 503.2L331.2 509.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 524.3H324.3L320.8 518.3L324.3 512.2H331.2L334.7 518.3L331.2 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 539.4H324.3L320.8 533.3L324.3 527.3H331.2L334.7 533.3L331.2 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 554.4H324.3L320.8 548.4L324.3 542.4H331.2L334.7 548.4L331.2 554.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 569.4H324.3L320.9 563.5L324.3 557.5H331.2L334.6 563.5L331.2 569.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M329.8 582.1H325.7L323.6 578.6L325.7 575H329.8L331.8 578.6L329.8 582.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M356.4 643.3H351.2L348.7 638.8L351.2 634.3H356.4L359 638.8L356.4 643.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 652.4H363.4L359.9 646.4L363.4 640.3H370.4L373.9 646.4L370.4 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 659.9H376.5L373 653.9L376.5 647.9H383.4L386.9 653.9L383.4 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 667.5H389.5L386 661.4L389.5 655.4H396.5L400 661.4L396.5 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 675H402.6L399.1 669L402.6 663H409.5L413 669L409.5 675Z'
          fill='#DFDFDF'
        />
        <path
          d='M422.6 682.5H415.6L412.2 676.5L415.6 670.5H422.6L426 676.5L422.6 682.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1074.2 500H1069.2L1066.7 495.7L1069.2 491.4H1074.2L1076.7 495.7L1074.2 500Z'
          fill='#DFDFDF'
        />
        <path
          d='M1073.8 484.2H1069.6L1067.5 480.6L1069.6 477H1073.8L1075.8 480.6L1073.8 484.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1074.6 440.4H1068.8L1065.9 435.4L1068.8 430.3H1074.6L1077.5 435.4L1074.6 440.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 426.3H1068.2L1064.7 420.3L1068.2 414.3H1075.2L1078.6 420.3L1075.2 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 411.3H1068.2L1064.7 405.2L1068.2 399.2H1075.2L1078.6 405.2L1075.2 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 396.2H1068.2L1064.7 390.2L1068.2 384.1H1075.2L1078.6 390.2L1075.2 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 381.1H1068.2L1064.7 375.1L1068.2 369.1H1075.2L1078.6 375.1L1075.2 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 366.1H1068.2L1064.7 360L1068.2 354H1075.2L1078.6 360L1075.2 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 351H1068.2L1064.7 345L1068.2 338.9H1075.2L1078.6 345L1075.2 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 335.9H1068.2L1064.7 329.9L1068.2 323.9H1075.2L1078.6 329.9L1075.2 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 320.8H1068.2L1064.7 314.8L1068.2 308.8H1075.2L1078.6 314.8L1075.2 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 305.8H1068.2L1064.7 299.7L1068.2 293.7H1075.2L1078.6 299.7L1075.2 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 290.7H1068.2L1064.7 284.7L1068.2 278.6H1075.2L1078.6 284.7L1075.2 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 275.6H1068.2L1064.7 269.6L1068.2 263.6H1075.2L1078.6 269.6L1075.2 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 260.6H1068.2L1064.7 254.5L1068.2 248.5H1075.2L1078.6 254.5L1075.2 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 245.5H1068.2L1064.7 239.5L1068.2 233.4H1075.2L1078.6 239.5L1075.2 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 230.4H1068.2L1064.7 224.4L1068.2 218.4H1075.2L1078.6 224.4L1075.2 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 215.3H1068.2L1064.7 209.3L1068.2 203.3H1075.2L1078.6 209.3L1075.2 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 200.3H1068.2L1064.7 194.2L1068.2 188.2H1075.2L1078.6 194.2L1075.2 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 185.2H1068.2L1064.7 179.2L1068.2 173.1H1075.2L1078.6 179.2L1075.2 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 177.7H1055.2L1051.7 171.6L1055.2 165.6H1062.1L1065.6 171.6L1062.1 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 170.1H1042.1L1038.6 164.1L1042.1 158.1H1049.1L1052.5 164.1L1049.1 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 162.6H1029.1L1025.6 156.6L1029.1 150.5H1036L1039.5 156.6L1036 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 155.1H1016L1012.5 149L1016 143H1023L1026.4 149L1023 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 147.5H1002.9L999.5 141.5L1002.9 135.5H1009.9L1013.4 141.5L1009.9 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 140H989.9L986.4 134L989.9 127.9H996.9L1000.3 134L996.9 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 132.5H976.8L973.4 126.4L976.8 120.4H983.8L987.3 126.4L983.8 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.8 124.9H963.8L960.3 118.9L963.8 112.9H970.8L974.2 118.9L970.8 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M957.7 117.4H950.7L947.3 111.4L950.7 105.3H957.7L961.2 111.4L957.7 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M943.1 107.1H939.3L937.4 103.8L939.3 100.6H943.1L944.9 103.8L943.1 107.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M929 97.7H927.3L926.5 96.3L927.3 94.8H929L929.8 96.3L929 97.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M891.4 77.8H886.6L884.2 73.7L886.6 69.5H891.4L893.7 73.7L891.4 77.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M591.1 24.9999H586.5L584.1 20.8999L586.5 16.8999H591.1L593.4 20.8999L591.1 24.9999Z'
          fill='#DFDFDF'
        />
        <path
          d='M579.2 34.4999H572.2L568.8 28.4999L572.2 22.3999H579.2L582.7 28.4999L579.2 34.4999Z'
          fill='#DFDFDF'
        />
        <path
          d='M566.2 42H559.2L555.7 36L559.2 30H566.2L569.6 36L566.2 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M553.1 49.6H546.1L542.7 43.5L546.1 37.5H553.1L556.6 43.5L553.1 49.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M540 57.1H533.1L529.6 51.1L533.1 45H540L543.5 51.1L540 57.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M527 64.6001H520L516.6 58.6001L520 52.6001H527L530.5 58.6001L527 64.6001Z'
          fill='#DFDFDF'
        />
        <path
          d='M513.9 72.2001H507L503.5 66.1001L507 60.1001H513.9L517.4 66.1001L513.9 72.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.9 79.7H493.9L490.5 73.7L493.9 67.7H500.9L504.4 73.7L500.9 79.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 87.2H480.9L477.4 81.2L480.9 75.2H487.8L491.3 81.2L487.8 87.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 94.8H467.8L464.3 88.8L467.8 82.7H474.8L478.3 88.8L474.8 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 102.2H454.8L451.4 96.3L454.8 90.3H461.7L465.1 96.3L461.7 102.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M446 105.1H444.4L443.7 103.8L444.4 102.5H446L446.7 103.8L446 105.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M408.2 130.1H403.9L401.8 126.4L403.9 122.7H408.2L410.3 126.4L408.2 130.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 140H389.5L386 134L389.5 127.9H396.5L400 134L396.5 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 147.5H376.5L373 141.5L376.5 135.5H383.4L386.9 141.5L383.4 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.3 155H363.4L360 149L363.4 143.1H370.3L373.8 149L370.3 155Z'
          fill='#DFDFDF'
        />
        <path
          d='M354.6 157.9H353.1L352.3 156.6L353.1 155.2H354.6L355.4 156.6L354.6 157.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M342.4 166.9H339.2L337.6 164.1L339.2 161.3H342.4L344 164.1L342.4 166.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M315.7 211.1H313.6L312.6 209.3L313.6 207.5H315.7L316.8 209.3L315.7 211.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 230.4H311.2L307.7 224.4L311.2 218.4H318.2L321.6 224.4L318.2 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 245.5H311.2L307.7 239.5L311.2 233.4H318.2L321.6 239.5L318.2 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 260.6H311.2L307.7 254.5L311.2 248.5H318.2L321.6 254.5L318.2 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 275.6H311.2L307.7 269.6L311.2 263.6H318.2L321.6 269.6L318.2 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 290.7H311.2L307.7 284.7L311.2 278.6H318.2L321.6 284.7L318.2 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 305.8H311.2L307.7 299.7L311.2 293.7H318.2L321.6 299.7L318.2 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 320.8H311.2L307.7 314.8L311.2 308.8H318.2L321.6 314.8L318.2 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 335.9H311.2L307.7 329.9L311.2 323.9H318.2L321.6 329.9L318.2 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.1 350.8H311.3L307.9 345L311.3 339.1H318.1L321.5 345L318.1 350.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M315.8 362H313.6L312.4 360L313.6 358.1H315.8L316.9 360L315.8 362Z'
          fill='#DFDFDF'
        />
        <path
          d='M317.6 380.2H311.8L308.8 375.1L311.8 370H317.6L320.5 375.1L317.6 380.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M315.6 406.8H313.8L312.8 405.2L313.8 403.6H315.6L316.5 405.2L315.6 406.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M318 471.3H311.4L308.1 465.5L311.4 459.8H318L321.3 465.5L318 471.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M315.7 482.3H313.7L312.7 480.6L313.7 478.9H315.7L316.7 480.6L315.7 482.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M316.8 499.3H312.6L310.5 495.7L312.6 492H316.8L318.9 495.7L316.8 499.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 516.8H311.2L307.7 510.7L311.2 504.7H318.2L321.6 510.7L318.2 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.1 531.8H311.2L307.8 525.8L311.2 519.8H318.1L321.6 525.8L318.1 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M316.1 543.2H313.3L312 540.9L313.3 538.5H316.1L317.4 540.9L316.1 543.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M317.7 561.2H311.6L308.6 555.9L311.6 550.7H317.7L320.8 555.9L317.7 561.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M355.8 657.3H351.9L349.9 653.9L351.9 650.5H355.8L357.8 653.9L355.8 657.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 667.5H363.4L359.9 661.4L363.4 655.4H370.4L373.9 661.4L370.4 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 675H376.5L373 669L376.5 663H383.4L386.9 669L383.4 675Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 682.5H389.5L386 676.5L389.5 670.5H396.5L400 676.5L396.5 682.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 690.1H402.6L399.1 684L402.6 678H409.5L413 684L409.5 690.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M420.9 694.7H417.3L415.5 691.6L417.3 688.5H420.9L422.7 691.6L420.9 694.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1087 522.2H1082.5L1080.2 518.3L1082.5 514.3H1087L1089.3 518.3L1087 522.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088 508.9H1081.5L1078.2 503.2L1081.5 497.5H1088L1091.3 503.2L1088 508.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1085.8 490H1083.7L1082.6 488.1L1083.7 486.3H1085.8L1086.9 488.1L1085.8 490Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.1 478.8H1081.4L1078.1 473.1L1081.4 467.3H1088.1L1091.4 473.1L1088.1 478.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1085.8 459.8H1083.7L1082.6 458L1083.7 456.2H1085.8L1086.8 458L1085.8 459.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088 448.6H1081.4L1078.2 442.9L1081.4 437.2H1088L1091.3 442.9L1088 448.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 433.9H1081.3L1077.8 427.8L1081.3 421.8H1088.2L1091.7 427.8L1088.2 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 418.8H1081.3L1077.8 412.8L1081.3 406.7H1088.2L1091.7 412.8L1088.2 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 403.7H1081.3L1077.8 397.7L1081.3 391.7H1088.2L1091.7 397.7L1088.2 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 388.7H1081.3L1077.8 382.6L1081.3 376.6H1088.2L1091.7 382.6L1088.2 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 373.6H1081.3L1077.8 367.6L1081.3 361.5H1088.2L1091.7 367.6L1088.2 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 358.5H1081.3L1077.8 352.5L1081.3 346.5H1088.2L1091.7 352.5L1088.2 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 343.4H1081.3L1077.8 337.4L1081.3 331.4H1088.2L1091.7 337.4L1088.2 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 328.4H1081.3L1077.8 322.4L1081.3 316.3H1088.2L1091.7 322.4L1088.2 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 313.3H1081.3L1077.8 307.3L1081.3 301.3H1088.2L1091.7 307.3L1088.2 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 298.2H1081.3L1077.8 292.2L1081.3 286.2H1088.2L1091.7 292.2L1088.2 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 283.2H1081.3L1077.8 277.1L1081.3 271.1H1088.2L1091.7 277.1L1088.2 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 268.1H1081.3L1077.8 262.1L1081.3 256H1088.2L1091.7 262.1L1088.2 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 253H1081.3L1077.8 247L1081.3 241H1088.2L1091.7 247L1088.2 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 238H1081.3L1077.8 231.9L1081.3 225.9H1088.2L1091.7 231.9L1088.2 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 207.8H1081.3L1077.8 201.8L1081.3 195.8H1088.2L1091.7 201.8L1088.2 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 192.7H1081.3L1077.8 186.7L1081.3 180.7H1088.2L1091.7 186.7L1088.2 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 177.7H1081.3L1077.8 171.6L1081.3 165.6H1088.2L1091.7 171.6L1088.2 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 170.1H1068.2L1064.7 164.1L1068.2 158.1H1075.2L1078.6 164.1L1075.2 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 162.6H1055.2L1051.7 156.6L1055.2 150.5H1062.1L1065.6 156.6L1062.1 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 155.1H1042.1L1038.6 149L1042.1 143H1049.1L1052.5 149L1049.1 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 147.5H1029.1L1025.6 141.5L1029.1 135.5H1036L1039.5 141.5L1036 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 140H1016L1012.5 134L1016 127.9H1023L1026.4 134L1023 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 132.5H1002.9L999.5 126.4L1002.9 120.4H1009.9L1013.4 126.4L1009.9 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 117.4H976.8L973.4 111.4L976.8 105.3H983.8L987.3 111.4L983.8 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M970.6 109.7H963.9L960.5 103.8L963.9 98H970.6L974 103.8L970.6 109.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M955.9 99.1H952.6L950.9 96.3L952.6 93.5H955.9L957.5 96.3L955.9 99.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M905.1 71.3999H899L895.9 66.0999L899 60.8999H905.1L908.1 66.0999L905.1 71.3999Z'
          fill='#DFDFDF'
        />
        <path
          d='M591.1 9.99995H586.4L584 5.89995L586.4 1.69995H591.1L593.5 5.89995L591.1 9.99995Z'
          fill='#DFDFDF'
        />
        <path
          d='M578.9 18.8999H572.6L569.4 13.3999L572.6 7.8999H578.9L582 13.3999L578.9 18.8999Z'
          fill='#DFDFDF'
        />
        <path
          d='M564.2 23.6H561.1L559.6 20.9L561.1 18.2H564.2L565.8 20.9L564.2 23.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M553.1 34.4999H546.1L542.7 28.4999L546.1 22.3999H553.1L556.6 28.4999L553.1 34.4999Z'
          fill='#DFDFDF'
        />
        <path
          d='M540 42H533.1L529.6 36L533.1 30H540L543.5 36L540 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M527 49.6H520L516.6 43.5L520 37.5H527L530.5 43.5L527 49.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.9 64.6001H493.9L490.5 58.6001L493.9 52.6001H500.9L504.4 58.6001L500.9 64.6001Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 72.2001H480.9L477.4 66.1001L480.9 60.1001H487.8L491.3 66.1001L487.8 72.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 79.7H467.8L464.3 73.7L467.8 67.7H474.8L478.3 73.7L474.8 79.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 87.2H454.8L451.3 81.2L454.8 75.2H461.7L465.2 81.2L461.7 87.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M446.5 91H443.9L442.6 88.8L443.9 86.5H446.5L447.8 88.8L446.5 91Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 124.9H389.5L386 118.9L389.5 112.9H396.5L400 118.9L396.5 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.2 132.1H376.7L373.4 126.4L376.7 120.7H383.2L386.5 126.4L383.2 132.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M330.4 161.2H325.1L322.4 156.6L325.1 152H330.4L333.1 156.6L330.4 161.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M303.1 174.2H300.2L298.7 171.6L300.2 169.1H303.1L304.6 171.6L303.1 174.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M302.7 203.6H300.6L299.5 201.8L300.6 199.9H302.7L303.8 201.8L302.7 203.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 222.9H298.2L294.7 216.9L298.2 210.8H305.1L308.6 216.9L305.1 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 238H298.2L294.7 231.9L298.2 225.9H305.1L308.6 231.9L305.1 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 253H298.2L294.7 247L298.2 241H305.1L308.6 247L305.1 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 268.1H298.2L294.7 262.1L298.2 256H305.1L308.6 262.1L305.1 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 283.2H298.2L294.7 277.1L298.2 271.1H305.1L308.6 277.1L305.1 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 298.2H298.2L294.7 292.2L298.2 286.2H305.1L308.6 292.2L305.1 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 313.3H298.2L294.7 307.3L298.2 301.3H305.1L308.6 307.3L305.1 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 328.4H298.2L294.7 322.4L298.2 316.3H305.1L308.6 322.4L305.1 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 343.4H298.2L294.7 337.4L298.2 331.4H305.1L308.6 337.4L305.1 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 358.5H298.2L294.7 352.5L298.2 346.5H305.1L308.6 352.5L305.1 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M302.9 369.8H300.3L299 367.6L300.3 365.3H302.9L304.3 367.6L302.9 369.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M304.7 403H298.6L295.6 397.7L298.6 392.5H304.7L307.7 397.7L304.7 403Z'
          fill='#DFDFDF'
        />
        <path
          d='M302.4 444.3H300.8L300 442.9L300.8 441.5H302.4L303.2 442.9L302.4 444.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M302.7 459.8H300.6L299.5 458L300.6 456.1H302.7L303.8 458L302.7 459.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M303.1 475.5H300.2L298.8 473.1L300.2 470.6H303.1L304.5 473.1L303.1 475.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M356.9 674.3H350.7L347.6 669L350.7 663.6H356.9L360 669L356.9 674.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 682.5H363.4L359.9 676.5L363.4 670.5H370.4L373.9 676.5L370.4 682.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 690.1H376.5L373 684L376.5 678H383.4L386.9 684L383.4 690.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 697.6H389.5L386 691.6L389.5 685.6H396.5L400 691.6L396.5 697.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 705.1H402.6L399.1 699.1L402.6 693.1H409.5L413 699.1L409.5 705.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.1 531.5H1094.5L1091.2 525.8L1094.5 520.1H1101.1L1104.3 525.8L1101.1 531.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101 516.3H1094.6L1091.4 510.7L1094.6 505.2H1101L1104.2 510.7L1101 516.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1099.3 498.2H1096.3L1094.8 495.7L1096.3 493.1H1099.3L1100.8 495.7L1099.3 498.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1099.5 483.6H1096.1L1094.4 480.6L1096.1 477.6H1099.5L1101.2 480.6L1099.5 483.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 456.5H1094.3L1090.8 450.5L1094.3 444.4H1101.3L1104.8 450.5L1101.3 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 441.4H1094.3L1090.8 435.4L1094.3 429.4H1101.3L1104.8 435.4L1101.3 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 426.3H1094.3L1090.8 420.3L1094.3 414.3H1101.3L1104.8 420.3L1101.3 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 411.3H1094.3L1090.8 405.2L1094.3 399.2H1101.3L1104.8 405.2L1101.3 411.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 396.2H1094.3L1090.8 390.2L1094.3 384.1H1101.3L1104.8 390.2L1101.3 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 381.1H1094.3L1090.8 375.1L1094.3 369.1H1101.3L1104.8 375.1L1101.3 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 366.1H1094.3L1090.8 360L1094.3 354H1101.3L1104.8 360L1101.3 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 351H1094.3L1090.8 345L1094.3 338.9H1101.3L1104.8 345L1101.3 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 335.9H1094.3L1090.8 329.9L1094.3 323.9H1101.3L1104.8 329.9L1101.3 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 320.8H1094.3L1090.8 314.8L1094.3 308.8H1101.3L1104.8 314.8L1101.3 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 305.8H1094.3L1090.8 299.7L1094.3 293.7H1101.3L1104.8 299.7L1101.3 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 290.7H1094.3L1090.8 284.7L1094.3 278.6H1101.3L1104.8 284.7L1101.3 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 275.6H1094.3L1090.8 269.6L1094.3 263.6H1101.3L1104.8 269.6L1101.3 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 260.6H1094.3L1090.8 254.5L1094.3 248.5H1101.3L1104.8 254.5L1101.3 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 245.5H1094.3L1090.8 239.5L1094.3 233.4H1101.3L1104.8 239.5L1101.3 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 230.4H1094.3L1090.8 224.4L1094.3 218.4H1101.3L1104.8 224.4L1101.3 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 215.3H1094.3L1090.8 209.3L1094.3 203.3H1101.3L1104.8 209.3L1101.3 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 200.3H1094.3L1090.8 194.2L1094.3 188.2H1101.3L1104.8 194.2L1101.3 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 185.2H1094.3L1090.8 179.2L1094.3 173.1H1101.3L1104.8 179.2L1101.3 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 170.1H1094.3L1090.8 164.1L1094.3 158.1H1101.3L1104.8 164.1L1101.3 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 162.6H1081.3L1077.8 156.6L1081.3 150.5H1088.2L1091.7 156.6L1088.2 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 155.1H1068.2L1064.7 149L1068.2 143H1075.2L1078.6 149L1075.2 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 147.5H1055.2L1051.7 141.5L1055.2 135.5H1062.1L1065.6 141.5L1062.1 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 140H1042.1L1038.6 134L1042.1 127.9H1049.1L1052.5 134L1049.1 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 132.5H1029.1L1025.6 126.4L1029.1 120.4H1036L1039.5 126.4L1036 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 124.9H1016L1012.5 118.9L1016 112.9H1023L1026.4 118.9L1023 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 117.4H1002.9L999.5 111.4L1002.9 105.3H1009.9L1013.4 111.4L1009.9 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 109.9H989.9L986.4 103.8L989.9 97.8H996.9L1000.3 103.8L996.9 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.8 102.3H976.8L973.4 96.3L976.8 90.3H983.8L987.3 96.3L983.8 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M969.8 93.0999H964.8L962.3 88.7999L964.8 84.3999H969.8L972.3 88.7999L969.8 93.0999Z'
          fill='#DFDFDF'
        />
        <path
          d='M877.2 38.2001H874.6L873.4 36L874.6 33.8H877.2L878.5 36L877.2 38.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M566 11.7H559.3L555.9 5.9L559.3 0H566L569.4 5.9L566 11.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M552.9 19.1H546.3L543 13.4L546.3 7.69995H552.9L556.2 13.4L552.9 19.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M540 26.9999H533.1L529.6 20.8999L533.1 14.8999H540L543.5 20.8999L540 26.9999Z'
          fill='#DFDFDF'
        />
        <path
          d='M527 34.4999H520L516.6 28.4999L520 22.3999H527L530.5 28.4999L527 34.4999Z'
          fill='#DFDFDF'
        />
        <path
          d='M513.9 42H507L503.5 36L507 30H513.9L517.4 36L513.9 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.9 49.6H493.9L490.5 43.5L493.9 37.5H500.9L504.4 43.5L500.9 49.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 57.1H480.9L477.4 51.1L480.9 45H487.8L491.3 51.1L487.8 57.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 64.6001H467.8L464.3 58.6001L467.8 52.6001H474.8L478.3 58.6001L474.8 64.6001Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 72.2001H454.8L451.3 66.1001L454.8 60.1001H461.7L465.2 66.1001L461.7 72.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.6 79.5H441.8L438.5 73.7L441.8 67.8H448.6L451.9 73.7L448.6 79.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M395 107.3H391L388.9 103.8L391 100.3H395L397.1 103.8L395 107.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 117.4H376.5L373 111.4L376.5 105.3H383.4L386.9 111.4L383.4 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M369.5 123.4H364.3L361.7 118.9L364.3 114.4H369.5L372.1 118.9L369.5 123.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 140H337.3L333.8 134L337.3 127.9H344.3L347.8 134L344.3 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.1 147.2H324.4L321.1 141.5L324.4 135.8H331.1L334.4 141.5L331.1 147.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 155.1H311.2L307.7 149L311.2 143H318.2L321.6 149L318.2 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 162.6H298.2L294.7 156.6L298.2 150.5H305.1L308.6 156.6L305.1 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 170.1H285.1L281.6 164.1L285.1 158.1H292.1L295.5 164.1L292.1 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M291.9 185H285.2L281.9 179.2L285.2 173.4H291.9L295.3 179.2L291.9 185Z'
          fill='#DFDFDF'
        />
        <path
          d='M291.7 199.6H285.5L282.4 194.2L285.5 188.9H291.7L294.7 194.2L291.7 199.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 215.3H285.1L281.6 209.3L285.1 203.3H292.1L295.5 209.3L292.1 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 230.4H285.1L281.6 224.4L285.1 218.4H292.1L295.5 224.4L292.1 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 245.5H285.1L281.6 239.5L285.1 233.4H292.1L295.5 239.5L292.1 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 260.6H285.1L281.6 254.5L285.1 248.5H292.1L295.5 254.5L292.1 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 275.6H285.1L281.6 269.6L285.1 263.6H292.1L295.5 269.6L292.1 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 290.7H285.1L281.6 284.7L285.1 278.6H292.1L295.5 284.7L292.1 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 305.8H285.1L281.6 299.7L285.1 293.7H292.1L295.5 299.7L292.1 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 320.8H285.1L281.6 314.8L285.1 308.8H292.1L295.5 314.8L292.1 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 335.9H285.1L281.6 329.9L285.1 323.9H292.1L295.5 329.9L292.1 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 351H285.1L281.6 345L285.1 338.9H292.1L295.5 345L292.1 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M291.9 365.8H285.2L281.9 360L285.2 354.2H291.9L295.3 360L291.9 365.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 441.4H285.1L281.6 435.4L285.1 429.4H292.1L295.5 435.4L292.1 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 456.5H285.1L281.6 450.5L285.1 444.4H292.1L295.5 450.5L292.1 456.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M289.1 466.5H288L287.5 465.5L288 464.6H289.1L289.7 465.5L289.1 466.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 690.1H350.4L346.9 684L350.4 678H357.3L360.8 684L357.3 690.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 697.6H363.4L359.9 691.6L363.4 685.6H370.4L373.9 691.6L370.4 697.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 705.1H376.5L373 699.1L376.5 693.1H383.4L386.9 699.1L383.4 705.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 712.6H389.5L386.1 706.7L389.5 700.7H396.5L399.9 706.7L396.5 712.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1112.6 536.3H1109.1L1107.4 533.3L1109.1 530.4H1112.6L1114.3 533.3L1112.6 536.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.1 463.5H1107.6L1104.4 458L1107.6 452.4H1114.1L1117.3 458L1114.1 463.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 448.9H1107.4L1103.9 442.9L1107.4 436.9H1114.3L1117.8 442.9L1114.3 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 433.9H1107.4L1103.9 427.8L1107.4 421.8H1114.3L1117.8 427.8L1114.3 433.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1112.2 415.1H1109.5L1108.2 412.8L1109.5 410.5H1112.2L1113.5 412.8L1112.2 415.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 403.7H1107.4L1103.9 397.7L1107.4 391.7H1114.3L1117.8 397.7L1114.3 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 388.7H1107.4L1103.9 382.6L1107.4 376.6H1114.3L1117.8 382.6L1114.3 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 373.6H1107.4L1103.9 367.6L1107.4 361.5H1114.3L1117.8 367.6L1114.3 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 358.5H1107.4L1103.9 352.5L1107.4 346.5H1114.3L1117.8 352.5L1114.3 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 343.4H1107.4L1103.9 337.4L1107.4 331.4H1114.3L1117.8 337.4L1114.3 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 328.4H1107.4L1103.9 322.4L1107.4 316.3H1114.3L1117.8 322.4L1114.3 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 313.3H1107.4L1103.9 307.3L1107.4 301.3H1114.3L1117.8 307.3L1114.3 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 298.2H1107.4L1103.9 292.2L1107.4 286.2H1114.3L1117.8 292.2L1114.3 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 283.2H1107.4L1103.9 277.1L1107.4 271.1H1114.3L1117.8 277.1L1114.3 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 268.1H1107.4L1103.9 262.1L1107.4 256H1114.3L1117.8 262.1L1114.3 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 253H1107.4L1103.9 247L1107.4 241H1114.3L1117.8 247L1114.3 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 238H1107.4L1103.9 231.9L1107.4 225.9H1114.3L1117.8 231.9L1114.3 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 222.9H1107.4L1103.9 216.9L1107.4 210.8H1114.3L1117.8 216.9L1114.3 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 207.8H1107.4L1103.9 201.8L1107.4 195.8H1114.3L1117.8 201.8L1114.3 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 192.7H1107.4L1103.9 186.7L1107.4 180.7H1114.3L1117.8 186.7L1114.3 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 177.7H1107.4L1103.9 171.6L1107.4 165.6H1114.3L1117.8 171.6L1114.3 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 162.6H1107.4L1103.9 156.6L1107.4 150.5H1114.3L1117.8 156.6L1114.3 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 155.1H1094.3L1090.8 149L1094.3 143H1101.3L1104.8 149L1101.3 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 147.5H1081.3L1077.8 141.5L1081.3 135.5H1088.2L1091.7 141.5L1088.2 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 140H1068.2L1064.7 134L1068.2 127.9H1075.2L1078.6 134L1075.2 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 132.5H1055.2L1051.7 126.4L1055.2 120.4H1062.1L1065.6 126.4L1062.1 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 124.9H1042.1L1038.6 118.9L1042.1 112.9H1049.1L1052.5 118.9L1049.1 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 117.4H1029.1L1025.6 111.4L1029.1 105.3H1036L1039.5 111.4L1036 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 109.9H1016L1012.5 103.8L1016 97.8H1023L1026.4 103.8L1023 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 102.3H1002.9L999.5 96.3L1002.9 90.3H1009.9L1013.4 96.3L1009.9 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M996.9 94.8H989.9L986.4 88.8L989.9 82.7H996.9L1000.3 88.8L996.9 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M983.7 87.0999H976.9L973.6 81.1999L976.9 75.3999H983.7L987.1 81.1999L983.7 87.0999Z'
          fill='#DFDFDF'
        />
        <path
          d='M540 11.7H533.2L529.8 5.9L533.2 0H540L543.3 5.9L540 11.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M527 19.3999H520L516.6 13.3999L520 7.3999H527L530.5 13.3999L527 19.3999Z'
          fill='#DFDFDF'
        />
        <path
          d='M513.9 26.9999H507L503.5 20.8999L507 14.8999H513.9L517.4 20.8999L513.9 26.9999Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.9 34.4999H493.9L490.5 28.4999L493.9 22.3999H500.9L504.4 28.4999L500.9 34.4999Z'
          fill='#DFDFDF'
        />
        <path
          d='M487.8 42H480.9L477.4 36L480.9 30H487.8L491.3 36L487.8 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 49.6H467.8L464.3 43.5L467.8 37.5H474.8L478.3 43.5L474.8 49.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 57.1H454.8L451.3 51.1L454.8 45H461.7L465.2 51.1L461.7 57.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 64.6001H441.7L438.2 58.6001L441.7 52.6001H448.7L452.2 58.6001L448.7 64.6001Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 72.2001H428.7L425.2 66.1001L428.7 60.1001H435.6L439.1 66.1001L435.6 72.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M420.4 75.9999H417.8L416.5 73.6999L417.8 71.3999H420.4L421.7 73.6999L420.4 75.9999Z'
          fill='#DFDFDF'
        />
        <path
          d='M380.6 97.4001H379.3L378.6 96.3001L379.3 95.1001H380.6L381.3 96.3001L380.6 97.4001Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 109.9H363.4L359.9 103.8L363.4 97.8H370.4L373.9 103.8L370.4 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 117.4H350.4L346.9 111.4L350.4 105.3H357.3L360.8 111.4L357.3 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M343.5 123.6H338.1L335.3 118.9L338.1 114.2H343.5L346.3 118.9L343.5 123.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M329.7 129.9H325.7L323.7 126.4L325.7 123H329.7L331.8 126.4L329.7 129.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 140H311.2L307.7 134L311.2 127.9H318.2L321.6 134L318.2 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M305.1 147.5H298.2L294.7 141.5L298.2 135.5H305.1L308.6 141.5L305.1 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 155.1H285.1L281.6 149L285.1 143H292.1L295.5 149L292.1 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 162.6H272.1L268.6 156.6L272.1 150.5H279L282.5 156.6L279 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 177.7H272.1L268.6 171.6L272.1 165.6H279L282.5 171.6L279 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 192.7H272.1L268.6 186.7L272.1 180.7H279L282.5 186.7L279 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 207.8H272.1L268.6 201.8L272.1 195.8H279L282.5 201.8L279 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 222.9H272.1L268.6 216.9L272.1 210.8H279L282.5 216.9L279 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 238H272.1L268.6 231.9L272.1 225.9H279L282.5 231.9L279 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 253H272.1L268.6 247L272.1 241H279L282.5 247L279 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 268.1H272.1L268.6 262.1L272.1 256H279L282.5 262.1L279 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 283.2H272.1L268.6 277.1L272.1 271.1H279L282.5 277.1L279 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 298.2H272.1L268.6 292.2L272.1 286.2H279L282.5 292.2L279 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 313.3H272.1L268.6 307.3L272.1 301.3H279L282.5 307.3L279 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 328.4H272.1L268.6 322.4L272.1 316.3H279L282.5 322.4L279 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 343.4H272.1L268.6 337.4L272.1 331.4H279L282.5 337.4L279 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 358.5H272.1L268.6 352.5L272.1 346.5H279L282.5 352.5L279 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M277.9 371.6H273.2L270.8 367.6L273.2 363.5H277.9L280.2 367.6L277.9 371.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 418.8H272.1L268.6 412.8L272.1 406.7H279L282.5 412.8L279 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M278.7 433.3H272.4L269.2 427.8L272.4 422.4H278.7L281.8 427.8L278.7 433.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 448.9H272.1L268.6 442.9L272.1 436.9H279L282.5 442.9L279 448.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.2 704.9H350.5L347.2 699.1L350.5 693.3H357.2L360.5 699.1L357.2 704.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 712.7H363.4L359.9 706.7L363.4 700.6H370.4L373.9 706.7L370.4 712.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 720.2H376.5L373 714.2L376.5 708.2H383.4L386.9 714.2L383.4 720.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M394 723.4H392L391 721.7L392 720H394L395 721.7L394 723.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.2 546.7H1120.6L1117.2 540.9L1120.6 535.1H1127.2L1130.6 540.9L1127.2 546.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1126.3 514.9H1121.5L1119.1 510.7L1121.5 506.6H1126.3L1128.7 510.7L1126.3 514.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1124.6 451.8H1123.1L1122.4 450.5L1123.1 449.2H1124.6L1125.4 450.5L1124.6 451.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1125.1 437.5H1122.7L1121.4 435.4L1122.7 433.2H1125.1L1126.4 435.4L1125.1 437.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1126.5 424.8H1121.3L1118.7 420.3L1121.3 415.8H1126.5L1129.1 420.3L1126.5 424.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.2 411H1120.6L1117.3 405.2L1120.6 399.5H1127.2L1130.5 405.2L1127.2 411Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 396.2H1120.4L1116.9 390.2L1120.4 384.1H1127.4L1130.9 390.2L1127.4 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 381.1H1120.4L1116.9 375.1L1120.4 369.1H1127.4L1130.9 375.1L1127.4 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 366.1H1120.4L1116.9 360L1120.4 354H1127.4L1130.9 360L1127.4 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 351H1120.4L1116.9 345L1120.4 338.9H1127.4L1130.9 345L1127.4 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 335.9H1120.4L1116.9 329.9L1120.4 323.9H1127.4L1130.9 329.9L1127.4 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 320.8H1120.4L1116.9 314.8L1120.4 308.8H1127.4L1130.9 314.8L1127.4 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 305.8H1120.4L1116.9 299.7L1120.4 293.7H1127.4L1130.9 299.7L1127.4 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 290.7H1120.4L1116.9 284.7L1120.4 278.6H1127.4L1130.9 284.7L1127.4 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 275.6H1120.4L1116.9 269.6L1120.4 263.6H1127.4L1130.9 269.6L1127.4 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 260.6H1120.4L1116.9 254.5L1120.4 248.5H1127.4L1130.9 254.5L1127.4 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 245.5H1120.4L1116.9 239.5L1120.4 233.4H1127.4L1130.9 239.5L1127.4 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 230.4H1120.4L1116.9 224.4L1120.4 218.4H1127.4L1130.9 224.4L1127.4 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 215.3H1120.4L1116.9 209.3L1120.4 203.3H1127.4L1130.9 209.3L1127.4 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 200.3H1120.4L1116.9 194.2L1120.4 188.2H1127.4L1130.9 194.2L1127.4 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 185.2H1120.4L1116.9 179.2L1120.4 173.1H1127.4L1130.9 179.2L1127.4 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 170.1H1120.4L1116.9 164.1L1120.4 158.1H1127.4L1130.9 164.1L1127.4 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 155.1H1120.4L1116.9 149L1120.4 143H1127.4L1130.9 149L1127.4 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 147.5H1107.4L1103.9 141.5L1107.4 135.5H1114.3L1117.8 141.5L1114.3 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 140H1094.3L1090.8 134L1094.3 127.9H1101.3L1104.8 134L1101.3 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 132.5H1081.3L1077.8 126.4L1081.3 120.4H1088.2L1091.7 126.4L1088.2 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 124.9H1068.2L1064.7 118.9L1068.2 112.9H1075.2L1078.6 118.9L1075.2 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 117.4H1055.2L1051.7 111.4L1055.2 105.3H1062.1L1065.6 111.4L1062.1 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 109.9H1042.1L1038.6 103.8L1042.1 97.8H1049.1L1052.5 103.8L1049.1 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 102.3H1029.1L1025.6 96.3L1029.1 90.3H1036L1039.5 96.3L1036 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1023 94.8H1016L1012.5 88.8L1016 82.7H1023L1026.4 88.8L1023 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1009.9 87.2H1002.9L999.5 81.2L1002.9 75.2H1009.9L1013.4 81.2L1009.9 87.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M995.2 76.8H991.5L989.7 73.7L991.5 70.5H995.2L997 73.7L995.2 76.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M513.3 10.7H507.7L504.9 5.9L507.7 1H513.3L516.1 5.9L513.3 10.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M500.2 18.2001H494.7L491.9 13.4001L494.7 8.6001H500.2L502.9 13.4001L500.2 18.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M487 25.6H481.7L479 20.9L481.7 16.3H487L489.7 20.9L487 25.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M474.8 34.4999H467.8L464.3 28.4999L467.8 22.3999H474.8L478.3 28.4999L474.8 34.4999Z'
          fill='#DFDFDF'
        />
        <path
          d='M461.7 42H454.8L451.3 36L454.8 30H461.7L465.2 36L461.7 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.7 49.6H441.7L438.2 43.5L441.7 37.5H448.7L452.2 43.5L448.7 49.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 57.1H428.7L425.2 51.1L428.7 45H435.6L439.1 51.1L435.6 57.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M420.7 61.3999H417.5L415.9 58.5999L417.5 55.8999H420.7L422.3 58.5999L420.7 61.3999Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.3 71.8H402.8L399.5 66.1L402.8 60.5H409.3L412.6 66.1L409.3 71.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.2 102.1H350.5L347.2 96.3L350.5 90.5H357.2L360.5 96.3L357.2 102.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M344.3 109.9H337.3L333.8 103.8L337.3 97.8H344.3L347.8 103.8L344.3 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 117.4H324.3L320.8 111.4L324.3 105.3H331.2L334.7 111.4L331.2 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M318.2 124.9H311.2L307.8 118.9L311.2 112.9H318.2L321.6 118.9L318.2 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M304.8 131.8H298.5L295.4 126.4L298.5 121H304.8L307.9 126.4L304.8 131.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 140H285.1L281.6 134L285.1 127.9H292.1L295.5 134L292.1 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M279 147.5H272.1L268.6 141.5L272.1 135.5H279L282.5 141.5L279 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 155.1H259L255.5 149L259 143H266L269.4 149L266 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 170.1H259L255.5 164.1L259 158.1H266L269.4 164.1L266 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 185.2H259L255.5 179.2L259 173.1H266L269.4 179.2L266 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 200.3H259L255.5 194.2L259 188.2H266L269.4 194.2L266 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 215.3H259L255.5 209.3L259 203.3H266L269.4 209.3L266 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 230.4H259L255.5 224.4L259 218.4H266L269.4 224.4L266 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 245.5H259L255.5 239.5L259 233.4H266L269.4 239.5L266 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 260.6H259L255.5 254.5L259 248.5H266L269.4 254.5L266 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 275.6H259L255.5 269.6L259 263.6H266L269.4 269.6L266 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 290.7H259L255.5 284.7L259 278.6H266L269.4 284.7L266 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 305.8H259L255.5 299.7L259 293.7H266L269.4 299.7L266 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 320.8H259L255.5 314.8L259 308.8H266L269.4 314.8L266 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 335.9H259L255.5 329.9L259 323.9H266L269.4 329.9L266 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 351H259L255.5 345L259 338.9H266L269.4 345L266 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M265.8 365.8H259.1L255.8 360L259.1 354.2H265.8L269.2 360L265.8 365.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M263.4 406.8H261.6L260.7 405.2L261.6 403.7H263.4L264.3 405.2L263.4 406.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M265.9 426.3H259L255.6 420.3L259 414.3H265.9L269.4 420.3L265.9 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M265.9 441.4H259L255.5 435.4L259 429.4H265.9L269.4 435.4L265.9 441.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M357 719.7H350.7L347.5 714.2L350.7 708.7H357L360.1 714.2L357 719.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 727.8H363.4L359.9 721.7L363.4 715.7H370.4L373.9 721.7L370.4 727.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 735.3H376.5L373 729.3L376.5 723.2H383.4L386.9 729.3L383.4 735.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.2 659.5H1133.7L1130.5 653.9L1133.7 648.4H1140.2L1143.4 653.9L1140.2 659.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 644.8H1133.5L1130.1 638.8L1133.5 632.9H1140.4L1143.8 638.8L1140.4 644.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1138.6 626.6H1135.3L1133.7 623.8L1135.3 620.9H1138.6L1140.2 623.8L1138.6 626.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1139.8 553.4H1134.1L1131.2 548.4L1134.1 543.5H1139.8L1142.7 548.4L1139.8 553.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 524.3H1133.5L1130 518.3L1133.5 512.2H1140.4L1143.9 518.3L1140.4 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 509.2H1133.5L1130 503.2L1133.5 497.2H1140.4L1143.9 503.2L1140.4 509.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1137.8 489.5H1136.1L1135.3 488.1L1136.1 486.7H1137.8L1138.6 488.1L1137.8 489.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 403.7H1133.5L1130 397.7L1133.5 391.7H1140.4L1143.9 397.7L1140.4 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 388.7H1133.5L1130 382.6L1133.5 376.6H1140.4L1143.9 382.6L1140.4 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 373.6H1133.5L1130 367.6L1133.5 361.5H1140.4L1143.9 367.6L1140.4 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 358.5H1133.5L1130 352.5L1133.5 346.5H1140.4L1143.9 352.5L1140.4 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 343.4H1133.5L1130 337.4L1133.5 331.4H1140.4L1143.9 337.4L1140.4 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 328.4H1133.5L1130 322.4L1133.5 316.3H1140.4L1143.9 322.4L1140.4 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 313.3H1133.5L1130 307.3L1133.5 301.3H1140.4L1143.9 307.3L1140.4 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 298.2H1133.5L1130 292.2L1133.5 286.2H1140.4L1143.9 292.2L1140.4 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 283.2H1133.5L1130 277.1L1133.5 271.1H1140.4L1143.9 277.1L1140.4 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 268.1H1133.5L1130 262.1L1133.5 256H1140.4L1143.9 262.1L1140.4 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 253H1133.5L1130 247L1133.5 241H1140.4L1143.9 247L1140.4 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 238H1133.5L1130 231.9L1133.5 225.9H1140.4L1143.9 231.9L1140.4 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 222.9H1133.5L1130 216.9L1133.5 210.8H1140.4L1143.9 216.9L1140.4 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 207.8H1133.5L1130 201.8L1133.5 195.8H1140.4L1143.9 201.8L1140.4 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 192.7H1133.5L1130 186.7L1133.5 180.7H1140.4L1143.9 186.7L1140.4 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 177.7H1133.5L1130 171.6L1133.5 165.6H1140.4L1143.9 171.6L1140.4 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 162.6H1133.5L1130 156.6L1133.5 150.5H1140.4L1143.9 156.6L1140.4 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 147.5H1133.5L1130 141.5L1133.5 135.5H1140.4L1143.9 141.5L1140.4 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 140H1120.4L1116.9 134L1120.4 127.9H1127.4L1130.9 134L1127.4 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 132.5H1107.4L1103.9 126.4L1107.4 120.4H1114.3L1117.8 126.4L1114.3 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 124.9H1094.3L1090.8 118.9L1094.3 112.9H1101.3L1104.8 118.9L1101.3 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 117.4H1081.3L1077.8 111.4L1081.3 105.3H1088.2L1091.7 111.4L1088.2 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.2 109.9H1068.2L1064.7 103.8L1068.2 97.8H1075.2L1078.6 103.8L1075.2 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 102.3H1055.2L1051.7 96.3L1055.2 90.3H1062.1L1065.6 96.3L1062.1 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 94.8H1042.1L1038.6 88.8L1042.1 82.7H1049.1L1052.5 88.8L1049.1 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 87.2H1029.1L1025.6 81.2L1029.1 75.2H1036L1039.5 81.2L1036 87.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1022.9 79.6H1016L1012.6 73.7L1016 67.7H1022.9L1026.4 73.7L1022.9 79.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1007.4 67.7999H1005.4L1004.5 66.0999L1005.4 64.3999H1007.4L1008.4 66.0999L1007.4 67.7999Z'
          fill='#DFDFDF'
        />
        <path
          d='M460.8 25.3001H455.8L453.3 20.9001L455.8 16.6001H460.8L463.3 20.9001L460.8 25.3001Z'
          fill='#DFDFDF'
        />
        <path
          d='M448.5 34.2001H441.9L438.6 28.5L441.9 22.8H448.5L451.8 28.5L448.5 34.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M435.6 42H428.7L425.2 36L428.7 30H435.6L439.1 36L435.6 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M420.2 45.5001H418L416.9 43.5001L418 41.6001H420.2L421.3 43.5001L420.2 45.5001Z'
          fill='#DFDFDF'
        />
        <path
          d='M408.5 55.4001H403.6L401.1 51.1L403.6 46.8H408.5L411 51.1L408.5 55.4001Z'
          fill='#DFDFDF'
        />
        <path
          d='M369.6 78.3H364.2L361.5 73.7L364.2 69H369.6L372.2 73.7L369.6 78.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M356.9 86.4H350.8L347.8 81.2L350.8 76H356.9L359.9 81.2L356.9 86.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M342.9 92.5H338.6L336.5 88.8L338.6 85H342.9L345.1 88.8L342.9 92.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.1 102.1H324.4L321 96.3L324.4 90.5H331.1L334.5 96.3L331.1 102.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M305 117.2H298.3L294.9 111.4L298.3 105.6H305L308.3 111.4L305 117.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M276.6 128.2H274.5L273.5 126.4L274.5 124.7H276.6L277.6 126.4L276.6 128.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 140H259L255.5 134L259 127.9H266L269.4 134L266 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 147.5H245.9L242.5 141.5L245.9 135.5H252.9L256.4 141.5L252.9 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 162.6H245.9L242.5 156.6L245.9 150.5H252.9L256.4 156.6L252.9 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 177.7H245.9L242.5 171.6L245.9 165.6H252.9L256.4 171.6L252.9 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 192.7H245.9L242.5 186.7L245.9 180.7H252.9L256.4 186.7L252.9 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 207.8H245.9L242.5 201.8L245.9 195.8H252.9L256.4 201.8L252.9 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 222.9H245.9L242.5 216.9L245.9 210.8H252.9L256.4 216.9L252.9 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 238H245.9L242.5 231.9L245.9 225.9H252.9L256.4 231.9L252.9 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 253H245.9L242.5 247L245.9 241H252.9L256.4 247L252.9 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 268.1H245.9L242.5 262.1L245.9 256H252.9L256.4 262.1L252.9 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 283.2H245.9L242.5 277.1L245.9 271.1H252.9L256.4 277.1L252.9 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 298.2H245.9L242.5 292.2L245.9 286.2H252.9L256.4 292.2L252.9 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 313.3H245.9L242.5 307.3L245.9 301.3H252.9L256.4 307.3L252.9 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 328.4H245.9L242.5 322.4L245.9 316.3H252.9L256.4 322.4L252.9 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 343.4H245.9L242.5 337.4L245.9 331.4H252.9L256.4 337.4L252.9 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 358.5H245.9L242.5 352.5L245.9 346.5H252.9L256.4 352.5L252.9 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M252 372.1H246.8L244.2 367.6L246.8 363H252L254.6 367.6L252 372.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M250.3 414.3H248.6L247.7 412.8L248.6 411.3H250.3L251.1 412.8L250.3 414.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 433.8H246L242.5 427.8L246 421.9H252.9L256.3 427.8L252.9 433.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 735.3H350.4L346.9 729.3L350.4 723.2H357.3L360.8 729.3L357.3 735.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 742.8H363.4L359.9 736.8L363.4 730.8H370.4L373.9 736.8L370.4 742.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 750.3H376.5L373 744.3L376.5 738.3H383.4L386.9 744.3L383.4 750.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1138.7 672H1135.2L1133.4 669L1135.2 665.9H1138.7L1140.5 669L1138.7 672Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 667.5H1146.5L1143 661.4L1146.5 655.4H1153.5L1157 661.4L1153.5 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 652.4H1146.5L1143 646.4L1146.5 640.3H1153.5L1157 646.4L1153.5 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 637.3H1146.5L1143 631.3L1146.5 625.3H1153.5L1157 631.3L1153.5 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1152.5 620.6H1147.5L1145 616.2L1147.5 611.9H1152.5L1155 616.2L1152.5 620.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.3 531.5H1146.7L1143.4 525.8L1146.7 520.1H1153.3L1156.6 525.8L1153.3 531.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 516.8H1146.5L1143 510.7L1146.5 504.7H1153.5L1157 510.7L1153.5 516.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 501.7H1146.5L1143 495.7L1146.5 489.6H1153.5L1157 495.7L1153.5 501.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1152.9 485.6H1147.1L1144.3 480.6L1147.1 475.6H1152.9L1155.7 480.6L1152.9 485.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.2 395.6H1146.8L1143.7 390.2L1146.8 384.7H1153.2L1156.3 390.2L1153.2 395.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 381.1H1146.5L1143 375.1L1146.5 369.1H1153.5L1157 375.1L1153.5 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 366.1H1146.5L1143 360L1146.5 354H1153.5L1157 360L1153.5 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 351H1146.5L1143 345L1146.5 338.9H1153.5L1157 345L1153.5 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 335.9H1146.5L1143 329.9L1146.5 323.9H1153.5L1157 329.9L1153.5 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1151.9 318.1H1148.1L1146.2 314.8L1148.1 311.6H1151.9L1153.8 314.8L1151.9 318.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 305.8H1146.5L1143 299.7L1146.5 293.7H1153.5L1157 299.7L1153.5 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 290.7H1146.5L1143 284.7L1146.5 278.6H1153.5L1157 284.7L1153.5 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 275.6H1146.5L1143 269.6L1146.5 263.6H1153.5L1157 269.6L1153.5 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 260.6H1146.5L1143 254.5L1146.5 248.5H1153.5L1157 254.5L1153.5 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 245.5H1146.5L1143 239.5L1146.5 233.4H1153.5L1157 239.5L1153.5 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 230.4H1146.5L1143 224.4L1146.5 218.4H1153.5L1157 224.4L1153.5 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 215.3H1146.5L1143 209.3L1146.5 203.3H1153.5L1157 209.3L1153.5 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 200.3H1146.5L1143 194.2L1146.5 188.2H1153.5L1157 194.2L1153.5 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 185.2H1146.5L1143 179.2L1146.5 173.1H1153.5L1157 179.2L1153.5 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 170.1H1146.5L1143 164.1L1146.5 158.1H1153.5L1157 164.1L1153.5 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 155.1H1146.5L1143 149L1146.5 143H1153.5L1157 149L1153.5 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 140H1146.5L1143 134L1146.5 127.9H1153.5L1157 134L1153.5 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 132.5H1133.5L1130 126.4L1133.5 120.4H1140.4L1143.9 126.4L1140.4 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 124.9H1120.4L1116.9 118.9L1120.4 112.9H1127.4L1130.9 118.9L1127.4 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 117.4H1107.4L1103.9 111.4L1107.4 105.3H1114.3L1117.8 111.4L1114.3 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1101.3 109.9H1094.3L1090.8 103.8L1094.3 97.8H1101.3L1104.8 103.8L1101.3 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1088.2 102.3H1081.3L1077.8 96.3L1081.3 90.3H1088.2L1091.7 96.3L1088.2 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1075.1 94.7H1068.3L1064.9 88.8L1068.3 82.8H1075.1L1078.5 88.8L1075.1 94.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 87.2H1055.2L1051.8 81.2L1055.2 75.3H1062.1L1065.5 81.2L1062.1 87.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1049.1 79.7H1042.1L1038.6 73.7L1042.1 67.7H1049.1L1052.5 73.7L1049.1 79.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1036 72.2001H1029.1L1025.6 66.1001L1029.1 60.1001H1036L1039.5 66.1001L1036 72.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M995.9 47.9001H990.8L988.3 43.5001L990.8 39.1001H995.9L998.5 43.5001L995.9 47.9001Z'
          fill='#DFDFDF'
        />
        <path
          d='M982 38.7999H978.7L977 36L978.7 33.2H982L983.6 36L982 38.7999Z'
          fill='#DFDFDF'
        />
        <path
          d='M434.6 25.1H429.8L427.3 20.9L429.8 16.8H434.6L437 20.9L434.6 25.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M421 31.8001H417.2L415.3 28.5001L417.2 25.1001H421L423 28.5001L421 31.8001Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.1 41.2999H403L399.9 36L403 30.7H409.1L412.2 36L409.1 41.2999Z'
          fill='#DFDFDF'
        />
        <path
          d='M395.1 47.1H390.9L388.9 43.5L390.9 40H395.1L397.1 43.5L395.1 47.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.3 56.8H376.6L373.3 51.1L376.6 45.3H383.3L386.6 51.1L383.3 56.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 64.6001H363.4L359.9 58.6001L363.4 52.6001H370.4L373.9 58.6001L370.4 64.6001Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 72.2001H350.4L346.9 66.1001L350.4 60.1001H357.3L360.8 66.1001L357.3 72.2001Z'
          fill='#DFDFDF'
        />
        <path
          d='M342.3 76.2H339.3L337.9 73.7L339.3 71.2H342.3L343.7 73.7L342.3 76.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M329.4 84.1H326L324.4 81.2L326 78.3H329.4L331.1 81.2L329.4 84.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M290.2 106.7H286.9L285.3 103.8L286.9 101H290.2L291.9 103.8L290.2 106.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M277.6 115H273.4L271.3 111.4L273.4 107.7H277.6L279.7 111.4L277.6 115Z'
          fill='#DFDFDF'
        />
        <path
          d='M264.6 122.6H260.3L258.2 118.9L260.3 115.2H264.6L266.8 118.9L264.6 122.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M250.4 128.1H248.4L247.5 126.4L248.4 124.7H250.4L251.4 126.4L250.4 128.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 140H232.9L229.4 134L232.9 127.9H239.9L243.3 134L239.9 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 155.1H232.9L229.4 149L232.9 143H239.9L243.3 149L239.9 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 170.1H232.9L229.4 164.1L232.9 158.1H239.9L243.3 164.1L239.9 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 185.2H232.9L229.4 179.2L232.9 173.1H239.9L243.3 179.2L239.9 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 200.3H232.9L229.4 194.2L232.9 188.2H239.9L243.3 194.2L239.9 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 215.3H232.9L229.4 209.3L232.9 203.3H239.9L243.3 209.3L239.9 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 230.4H232.9L229.4 224.4L232.9 218.4H239.9L243.3 224.4L239.9 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 245.5H232.9L229.4 239.5L232.9 233.4H239.9L243.3 239.5L239.9 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 260.6H232.9L229.4 254.5L232.9 248.5H239.9L243.3 254.5L239.9 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 275.6H232.9L229.4 269.6L232.9 263.6H239.9L243.3 269.6L239.9 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 290.7H232.9L229.4 284.7L232.9 278.6H239.9L243.3 284.7L239.9 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 305.8H232.9L229.4 299.7L232.9 293.7H239.9L243.3 299.7L239.9 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 320.8H232.9L229.4 314.8L232.9 308.8H239.9L243.3 314.8L239.9 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 335.9H232.9L229.4 329.9L232.9 323.9H239.9L243.3 329.9L239.9 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 351H232.9L229.4 345L232.9 338.9H239.9L243.3 345L239.9 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 366.1H232.9L229.4 360L232.9 354H239.9L243.3 360L239.9 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 381.1H232.9L229.4 375.1L232.9 369.1H239.9L243.3 375.1L239.9 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 396.2H232.9L229.4 390.2L232.9 384.1H239.9L243.3 390.2L239.9 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 411.3H232.9L229.4 405.2L232.9 399.2H239.9L243.3 405.2L239.9 411.3Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-ninth'
        />
        <path
          d='M239.9 426.3H232.9L229.4 420.3L232.9 414.3H239.9L243.3 420.3L239.9 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M237.1 436.7H235.6L234.9 435.4L235.6 434.1H237.1L237.9 435.4L237.1 436.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 750.3H350.4L346.9 744.3L350.4 738.3H357.3L360.8 744.3L357.3 750.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 757.9H363.4L359.9 751.9L363.4 745.8H370.4L373.9 751.9L370.4 757.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 765.3H376.5L373.1 759.4L376.5 753.5H383.4L386.8 759.4L383.4 765.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1138.1 686H1135.8L1134.7 684L1135.8 682.1H1138.1L1139.2 684L1138.1 686Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 682.5H1146.5L1143 676.5L1146.5 670.5H1153.5L1157 676.5L1153.5 682.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 675H1159.6L1156.1 669L1159.6 663H1166.5L1170 669L1166.5 675Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 659.9H1159.6L1156.1 653.9L1159.6 647.9H1166.5L1170 653.9L1166.5 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 644.9H1159.6L1156.1 638.8L1159.6 632.8H1166.5L1170 638.8L1166.5 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 629.8H1159.6L1156.1 623.8L1159.6 617.7H1166.5L1170 623.8L1166.5 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.4 614.5H1159.7L1156.4 608.7L1159.7 602.9H1166.4L1169.7 608.7L1166.4 614.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1163.7 594.7H1162.4L1161.8 593.6L1162.4 592.6H1163.7L1164.3 593.6L1163.7 594.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1165.2 522H1160.9L1158.8 518.3L1160.9 514.6H1165.2L1167.3 518.3L1165.2 522Z'
          fill='#DFDFDF'
        />
        <path
          d='M1164.2 505.1H1161.9L1160.8 503.2L1161.9 501.3H1164.2L1165.3 503.2L1164.2 505.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1165.2 491.8H1160.9L1158.8 488.1L1160.9 484.4H1165.2L1167.3 488.1L1165.2 491.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1164.8 400.7H1161.3L1159.6 397.7L1161.3 394.7H1164.8L1166.5 397.7L1164.8 400.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1164.1 384.5H1162L1160.9 382.6L1162 380.8H1164.1L1165.2 382.6L1164.1 384.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.2 373H1159.9L1156.7 367.6L1159.9 362.1H1166.2L1169.4 367.6L1166.2 373Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.4 358.3H1159.7L1156.3 352.5L1159.7 346.7H1166.4L1169.8 352.5L1166.4 358.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1163.6 338.4H1162.5L1161.9 337.4L1162.5 336.4H1163.6L1164.2 337.4L1163.6 338.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1164.5 324.8H1161.6L1160.2 322.4L1161.6 319.9H1164.5L1165.9 322.4L1164.5 324.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.1 312.6H1160L1157 307.3L1160 302H1166.1L1169.1 307.3L1166.1 312.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 298.2H1159.6L1156.1 292.2L1159.6 286.2H1166.5L1170 292.2L1166.5 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 283.2H1159.6L1156.1 277.1L1159.6 271.1H1166.5L1170 277.1L1166.5 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 268.1H1159.6L1156.1 262.1L1159.6 256H1166.5L1170 262.1L1166.5 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 253H1159.6L1156.1 247L1159.6 241H1166.5L1170 247L1166.5 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 238H1159.6L1156.1 231.9L1159.6 225.9H1166.5L1170 231.9L1166.5 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 222.9H1159.6L1156.1 216.9L1159.6 210.8H1166.5L1170 216.9L1166.5 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 207.8H1159.6L1156.1 201.8L1159.6 195.8H1166.5L1170 201.8L1166.5 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 192.7H1159.6L1156.1 186.7L1159.6 180.7H1166.5L1170 186.7L1166.5 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 177.7H1159.6L1156.1 171.6L1159.6 165.6H1166.5L1170 171.6L1166.5 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 162.6H1159.6L1156.1 156.6L1159.6 150.5H1166.5L1170 156.6L1166.5 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 147.5H1159.6L1156.1 141.5L1159.6 135.5H1166.5L1170 141.5L1166.5 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 132.5H1159.6L1156.1 126.4L1159.6 120.4H1166.5L1170 126.4L1166.5 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 124.9H1146.5L1143 118.9L1146.5 112.9H1153.5L1157 118.9L1153.5 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1140.4 117.4H1133.5L1130 111.4L1133.5 105.3H1140.4L1143.9 111.4L1140.4 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1127.4 109.9H1120.4L1116.9 103.8L1120.4 97.8H1127.4L1130.9 103.8L1127.4 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1114.3 102.3H1107.4L1103.9 96.3L1107.4 90.3H1114.3L1117.7 96.3L1114.3 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1099.3 91.4001H1096.2L1094.7 88.8001L1096.2 86.1001H1099.3L1100.9 88.8001L1099.3 91.4001Z'
          fill='#DFDFDF'
        />
        <path
          d='M1073.3 76.3999H1070.1L1068.5 73.6999L1070.1 70.8999H1073.3L1074.9 73.6999L1073.3 76.3999Z'
          fill='#DFDFDF'
        />
        <path
          d='M1062.1 72.0999H1055.2L1051.7 66.0999L1055.2 60.2H1062.1L1065.5 66.0999L1062.1 72.0999Z'
          fill='#DFDFDF'
        />
        <path
          d='M1008.1 38.7999H1004.8L1003.2 36L1004.8 33.2H1008.1L1009.7 36L1008.1 38.7999Z'
          fill='#DFDFDF'
        />
        <path
          d='M421.5 17.6H416.7L414.2 13.4L416.7 9.19995H421.5L424 13.4L421.5 17.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M409.5 26.9999H402.6L399.1 20.8999L402.6 14.8999H409.5L413 20.8999L409.5 26.9999Z'
          fill='#DFDFDF'
        />
        <path
          d='M396.5 34.4999H389.5L386 28.4999L389.5 22.3999H396.5L400 28.4999L396.5 34.4999Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 42H376.5L373 36L376.5 30H383.4L386.9 36L383.4 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 49.6H363.4L359.9 43.5L363.4 37.5H370.4L373.9 43.5L370.4 49.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 57.1H350.4L346.9 51.1L350.4 45H357.3L360.8 51.1L357.3 57.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M343.8 63.7999H337.8L334.8 58.5999L337.8 53.3999H343.8L346.8 58.5999L343.8 63.7999Z'
          fill='#DFDFDF'
        />
        <path
          d='M330.5 71H325L322.2 66.1L325 61.3H330.5L333.3 66.1L330.5 71Z'
          fill='#DFDFDF'
        />
        <path
          d='M315.6 75.2H313.8L312.9 73.7L313.8 72.2H315.6L316.4 73.7L315.6 75.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M292.1 94.8H285.1L281.6 88.8L285.1 82.7H292.1L295.5 88.8L292.1 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M276.7 98.2H274.4L273.3 96.3L274.4 94.3H276.7L277.8 96.3L276.7 98.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M266 109.9H259L255.5 103.8L259 97.8H266L269.4 103.8L266 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 117.4H245.9L242.5 111.4L245.9 105.3H252.9L256.4 111.4L252.9 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.4 124H233.4L230.4 118.9L233.4 113.7H239.4L242.3 118.9L239.4 124Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.5 131.9H220.2L217 126.4L220.2 120.9H226.5L229.7 126.4L226.5 131.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 147.5H219.8L216.4 141.5L219.8 135.5H226.8L230.3 141.5L226.8 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 162.6H219.8L216.4 156.6L219.8 150.5H226.8L230.3 156.6L226.8 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 177.7H219.8L216.4 171.6L219.8 165.6H226.8L230.3 171.6L226.8 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 192.7H219.8L216.4 186.7L219.8 180.7H226.8L230.3 186.7L226.8 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 207.8H219.8L216.4 201.8L219.8 195.8H226.8L230.3 201.8L226.8 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 222.9H219.8L216.4 216.9L219.8 210.8H226.8L230.3 216.9L226.8 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 238H219.8L216.4 231.9L219.8 225.9H226.8L230.3 231.9L226.8 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 253H219.8L216.4 247L219.8 241H226.8L230.3 247L226.8 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 268.1H219.8L216.4 262.1L219.8 256H226.8L230.3 262.1L226.8 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 283.2H219.8L216.4 277.1L219.8 271.1H226.8L230.3 277.1L226.8 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 298.2H219.8L216.4 292.2L219.8 286.2H226.8L230.3 292.2L226.8 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 313.3H219.8L216.4 307.3L219.8 301.3H226.8L230.3 307.3L226.8 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 328.4H219.8L216.4 322.4L219.8 316.3H226.8L230.3 322.4L226.8 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 343.4H219.8L216.4 337.4L219.8 331.4H226.8L230.3 337.4L226.8 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 358.5H219.8L216.4 352.5L219.8 346.5H226.8L230.3 352.5L226.8 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 373.6H219.8L216.4 367.6L219.8 361.5H226.8L230.3 367.6L226.8 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 388.7H219.8L216.4 382.6L219.8 376.6H226.8L230.3 382.6L226.8 388.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 403.7H219.8L216.4 397.7L219.8 391.7H226.8L230.3 397.7L226.8 403.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 418.8H219.8L216.4 412.8L219.8 406.7H226.8L230.3 412.8L226.8 418.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M225 430.7H221.7L220 427.8L221.7 425H225L226.6 427.8L225 430.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M356.9 764.6H350.8L347.8 759.4L350.8 754.2H356.9L359.9 759.4L356.9 764.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 773H363.4L359.9 766.9L363.4 760.9H370.4L373.9 766.9L370.4 773Z'
          fill='#DFDFDF'
        />
        <path
          d='M381 776.2H378.9L377.9 774.5L378.9 772.7H381L382 774.5L381 776.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1177.8 679.5H1174.4L1172.6 676.5L1174.4 673.5H1177.8L1179.6 676.5L1177.8 679.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 667.5H1172.6L1169.1 661.4L1172.6 655.4H1179.6L1183.1 661.4L1179.6 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 652.4H1172.6L1169.1 646.4L1172.6 640.3H1179.6L1183.1 646.4L1179.6 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 637.3H1172.6L1169.1 631.3L1172.6 625.3H1179.6L1183.1 631.3L1179.6 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 622.3H1172.6L1169.1 616.2L1172.6 610.2H1179.6L1183.1 616.2L1179.6 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 607.2H1172.6L1169.1 601.2L1172.6 595.1H1179.6L1183.1 601.2L1179.6 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1177.5 528.3H1174.7L1173.3 525.8L1174.7 523.3H1177.5L1178.9 525.8L1177.5 528.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.3 516.4H1172.9L1169.6 510.7L1172.9 505.1H1179.3L1182.6 510.7L1179.3 516.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 426.3H1172.6L1169.1 420.3L1172.6 414.3H1179.6L1183.1 420.3L1179.6 426.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1178.5 319H1173.7L1171.3 314.8L1173.7 310.7H1178.5L1180.9 314.8L1178.5 319Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 305.8H1172.6L1169.1 299.7L1172.6 293.7H1179.6L1183.1 299.7L1179.6 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 290.7H1172.6L1169.1 284.7L1172.6 278.6H1179.6L1183.1 284.7L1179.6 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 275.6H1172.6L1169.1 269.6L1172.6 263.6H1179.6L1183.1 269.6L1179.6 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 260.6H1172.6L1169.1 254.5L1172.6 248.5H1179.6L1183.1 254.5L1179.6 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 245.5H1172.6L1169.1 239.5L1172.6 233.4H1179.6L1183.1 239.5L1179.6 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 230.4H1172.6L1169.1 224.4L1172.6 218.4H1179.6L1183.1 224.4L1179.6 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 215.3H1172.6L1169.1 209.3L1172.6 203.3H1179.6L1183.1 209.3L1179.6 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 200.3H1172.6L1169.1 194.2L1172.6 188.2H1179.6L1183.1 194.2L1179.6 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 185.2H1172.6L1169.1 179.2L1172.6 173.1H1179.6L1183.1 179.2L1179.6 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 170.1H1172.6L1169.1 164.1L1172.6 158.1H1179.6L1183.1 164.1L1179.6 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 155.1H1172.6L1169.1 149L1172.6 143H1179.6L1183.1 149L1179.6 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 140H1172.6L1169.1 134L1172.6 127.9H1179.6L1183.1 134L1179.6 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 124.9H1172.6L1169.1 118.9L1172.6 112.9H1179.6L1183.1 118.9L1179.6 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1166.5 117.4H1159.6L1156.1 111.4L1159.6 105.3H1166.5L1170 111.4L1166.5 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1153.5 109.9H1146.5L1143 103.8L1146.5 97.8H1153.5L1157 103.8L1153.5 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1138.9 99.6H1135L1133.1 96.3L1135 93H1138.9L1140.8 96.3L1138.9 99.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M395.7 18.1H390.3L387.5 13.4L390.3 8.69995H395.7L398.5 13.4L395.7 18.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.4 26.9999H376.5L373 20.8999L376.5 14.8999H383.4L386.9 20.8999L383.4 26.9999Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 34.4999H363.4L359.9 28.4999L363.4 22.3999H370.4L373.9 28.4999L370.4 34.4999Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 42H350.4L346.9 36L350.4 30H357.3L360.8 36L357.3 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M342.9 47.1H338.7L336.7 43.5L338.7 40H342.9L344.9 43.5L342.9 47.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M330.5 55.9001H325L322.2 51.1L325 46.3H330.5L333.3 51.1L330.5 55.9001Z'
          fill='#DFDFDF'
        />
        <path
          d='M302.6 67.8H300.7L299.8 66.1L300.7 64.5H302.6L303.5 66.1L302.6 67.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M291.5 78.7001H285.7L282.7 73.7001L285.7 68.6001H291.5L294.4 73.7001L291.5 78.7001Z'
          fill='#DFDFDF'
        />
        <path
          d='M277 83.7H274.1L272.6 81.2L274.1 78.7H277L278.5 81.2L277 83.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M265.4 93.9001H259.5L256.6 88.8001L259.5 83.6001H265.4L268.4 88.8001L265.4 93.9001Z'
          fill='#DFDFDF'
        />
        <path
          d='M252.9 102.3H246L242.5 96.3L246 90.3H252.9L256.3 96.3L252.9 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.9 109.9H232.9L229.4 103.8L232.9 97.8H239.9L243.3 103.8L239.9 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.4 116.7H220.2L217.2 111.4L220.2 106H226.4L229.5 111.4L226.4 116.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M211.1 120.3H209.5L208.7 118.9L209.5 117.5H211.1L211.9 118.9L211.1 120.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 140H206.8L203.3 134L206.8 127.9H213.8L217.2 134L213.8 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 155.1H206.8L203.3 149L206.8 143H213.8L217.2 149L213.8 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 170.1H206.8L203.3 164.1L206.8 158.1H213.8L217.2 164.1L213.8 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 185.2H206.8L203.3 179.2L206.8 173.1H213.8L217.2 179.2L213.8 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 200.3H206.8L203.3 194.2L206.8 188.2H213.8L217.2 194.2L213.8 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 215.3H206.8L203.3 209.3L206.8 203.3H213.8L217.2 209.3L213.8 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 230.4H206.8L203.3 224.4L206.8 218.4H213.8L217.2 224.4L213.8 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 245.5H206.8L203.3 239.5L206.8 233.4H213.8L217.2 239.5L213.8 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 260.6H206.8L203.3 254.5L206.8 248.5H213.8L217.2 254.5L213.8 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 275.6H206.8L203.3 269.6L206.8 263.6H213.8L217.2 269.6L213.8 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 290.7H206.8L203.3 284.7L206.8 278.6H213.8L217.2 284.7L213.8 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 305.8H206.8L203.3 299.7L206.8 293.7H213.8L217.2 299.7L213.8 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 320.8H206.8L203.3 314.8L206.8 308.8H213.8L217.2 314.8L213.8 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 335.9H206.8L203.3 329.9L206.8 323.9H213.8L217.2 329.9L213.8 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 351H206.8L203.3 345L206.8 338.9H213.8L217.2 345L213.8 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 366.1H206.8L203.3 360L206.8 354H213.8L217.2 360L213.8 366.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 381.1H206.8L203.3 375.1L206.8 369.1H213.8L217.2 375.1L213.8 381.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 396.2H206.8L203.3 390.2L206.8 384.1H213.8L217.2 390.2L213.8 396.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.7 411.2H206.8L203.4 405.2L206.8 399.3H213.7L217.1 405.2L213.7 411.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M211.7 422.8H208.9L207.4 420.3L208.9 417.9H211.7L213.1 420.3L211.7 422.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M355.7 777.8H351.9L350 774.5L351.9 771.2H355.7L357.6 774.5L355.7 777.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M370.4 788H363.4L359.9 782L363.4 776H370.4L373.9 782L370.4 788Z'
          fill='#DFDFDF'
        />
        <path
          d='M383.2 795.1H376.7L373.5 789.5L376.7 783.9H383.2L386.4 789.5L383.2 795.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M394.5 799.7H391.5L389.9 797.1L391.5 794.4H394.5L396.1 797.1L394.5 799.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1191.8 673.6H1186.5L1183.8 669L1186.5 664.4H1191.8L1194.5 669L1191.8 673.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 659.9H1185.7L1182.2 653.9L1185.7 647.9H1192.6L1196.1 653.9L1192.6 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 644.9H1185.7L1182.2 638.8L1185.7 632.8H1192.6L1196.1 638.8L1192.6 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 629.8H1185.7L1182.2 623.8L1185.7 617.7H1192.6L1196.1 623.8L1192.6 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 614.7H1185.7L1182.2 608.7L1185.7 602.7H1192.6L1196.1 608.7L1192.6 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 599.7H1185.7L1182.2 593.6L1185.7 587.6H1192.6L1196.1 593.6L1192.6 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1191.7 583H1186.6L1184 578.6L1186.6 574.1H1191.7L1194.3 578.6L1191.7 583Z'
          fill='#DFDFDF'
        />
        <path
          d='M1190.5 460.3H1187.8L1186.5 458L1187.8 455.7H1190.5L1191.8 458L1190.5 460.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1190.2 444.8H1188.1L1187 442.9L1188.1 441H1190.2L1191.3 442.9L1190.2 444.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.5 328.1H1185.9L1182.5 322.4L1185.9 316.6H1192.5L1195.8 322.4L1192.5 328.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1191.7 311.7H1186.6L1184 307.3L1186.6 302.8H1191.7L1194.3 307.3L1191.7 311.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 298.2H1185.7L1182.2 292.2L1185.7 286.2H1192.6L1196.1 292.2L1192.6 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 283.2H1185.7L1182.2 277.1L1185.7 271.1H1192.6L1196.1 277.1L1192.6 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 268.1H1185.7L1182.2 262.1L1185.7 256H1192.6L1196.1 262.1L1192.6 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 253H1185.7L1182.2 247L1185.7 241H1192.6L1196.1 247L1192.6 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 238H1185.7L1182.2 231.9L1185.7 225.9H1192.6L1196.1 231.9L1192.6 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 222.9H1185.7L1182.2 216.9L1185.7 210.8H1192.6L1196.1 216.9L1192.6 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 207.8H1185.7L1182.2 201.8L1185.7 195.8H1192.6L1196.1 201.8L1192.6 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 192.7H1185.7L1182.2 186.7L1185.7 180.7H1192.6L1196.1 186.7L1192.6 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 177.7H1185.7L1182.2 171.6L1185.7 165.6H1192.6L1196.1 171.6L1192.6 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 162.6H1185.7L1182.2 156.6L1185.7 150.5H1192.6L1196.1 156.6L1192.6 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 147.5H1185.7L1182.2 141.5L1185.7 135.5H1192.6L1196.1 141.5L1192.6 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 132.5H1185.7L1182.2 126.4L1185.7 120.4H1192.6L1196.1 126.4L1192.6 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 117.4H1185.7L1182.2 111.4L1185.7 105.3H1192.6L1196.1 111.4L1192.6 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.6 109.9H1172.6L1169.1 103.8L1172.6 97.8H1179.6L1183.1 103.8L1179.6 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1165 99.6H1161.1L1159.2 96.3L1161.1 93H1165L1166.9 96.3L1165 99.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M369 17H364.8L362.7 13.4L364.8 9.80005H369L371.1 13.4L369 17Z'
          fill='#DFDFDF'
        />
        <path
          d='M357.3 26.9999H350.4L346.9 20.8999L350.4 14.8999H357.3L360.8 20.8999L357.3 26.9999Z'
          fill='#DFDFDF'
        />
        <path
          d='M343.3 32.8001H338.3L335.7 28.5001L338.3 24.1001H343.3L345.8 28.5001L343.3 32.8001Z'
          fill='#DFDFDF'
        />
        <path
          d='M331.2 42H324.3L320.8 36L324.3 30H331.2L334.7 36L331.2 42Z'
          fill='#DFDFDF'
        />
        <path
          d='M276.6 68.1H274.4L273.3 66.1L274.4 64.2H276.6L277.7 66.1L276.6 68.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M265.8 79.4H259.2L255.9 73.7L259.2 68H265.8L269 73.7L265.8 79.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M251.9 85.4H247L244.6 81.2L247 77H251.9L254.3 81.2L251.9 85.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M239.8 94.8H232.9L229.4 88.8L232.9 82.7H239.8L243.3 88.8L239.8 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.8 102.3H219.8L216.4 96.3L219.8 90.3H226.8L230.3 96.3L226.8 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M211.1 105.3H209.4L208.5 103.8L209.4 102.3H211.1L212 103.8L211.1 105.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 132.5H193.7L190.3 126.4L193.7 120.4H200.7L204.2 126.4L200.7 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 147.5H193.7L190.3 141.5L193.7 135.5H200.7L204.2 141.5L200.7 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 162.6H193.7L190.3 156.6L193.7 150.5H200.7L204.2 156.6L200.7 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 177.7H193.7L190.3 171.6L193.7 165.6H200.7L204.2 171.6L200.7 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 192.7H193.7L190.3 186.7L193.7 180.7H200.7L204.2 186.7L200.7 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 207.8H193.7L190.3 201.8L193.7 195.8H200.7L204.2 201.8L200.7 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 222.9H193.7L190.3 216.9L193.7 210.8H200.7L204.2 216.9L200.7 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 238H193.7L190.3 231.9L193.7 225.9H200.7L204.2 231.9L200.7 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 253H193.7L190.3 247L193.7 241H200.7L204.2 247L200.7 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 268.1H193.7L190.3 262.1L193.7 256H200.7L204.2 262.1L200.7 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 283.2H193.7L190.3 277.1L193.7 271.1H200.7L204.2 277.1L200.7 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 298.2H193.7L190.3 292.2L193.7 286.2H200.7L204.2 292.2L200.7 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 313.3H193.7L190.3 307.3L193.7 301.3H200.7L204.2 307.3L200.7 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 328.4H193.7L190.3 322.4L193.7 316.3H200.7L204.2 322.4L200.7 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 343.4H193.7L190.3 337.4L193.7 331.4H200.7L204.2 337.4L200.7 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 358.5H193.7L190.3 352.5L193.7 346.5H200.7L204.2 352.5L200.7 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 373.6H193.7L190.3 367.6L193.7 361.5H200.7L204.2 367.6L200.7 373.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.5 388.3H194L190.7 382.6L194 377H200.5L203.7 382.6L200.5 388.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 667.5H1198.7L1195.2 661.4L1198.7 655.4H1205.7L1209.2 661.4L1205.7 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 652.4H1198.7L1195.2 646.4L1198.7 640.3H1205.7L1209.2 646.4L1205.7 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 637.3H1198.7L1195.2 631.3L1198.7 625.3H1205.7L1209.2 631.3L1205.7 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 622.3H1198.7L1195.2 616.2L1198.7 610.2H1205.7L1209.2 616.2L1205.7 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 607.2H1198.7L1195.2 601.2L1198.7 595.1H1205.7L1209.2 601.2L1205.7 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 592.1H1198.7L1195.2 586.1L1198.7 580.1H1205.7L1209.2 586.1L1205.7 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1203.2 572.8H1201.2L1200.1 571L1201.2 569.2H1203.2L1204.3 571L1203.2 572.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.6 456.3H1198.8L1195.5 450.5L1198.8 444.6H1205.6L1208.9 450.5L1205.6 456.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1204.8 349.5H1199.6L1197 345L1199.6 340.4H1204.8L1207.4 345L1204.8 349.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1202.7 330.8H1201.7L1201.1 329.9L1201.7 329H1202.7L1203.3 329.9L1202.7 330.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.5 290.4H1198.9L1195.6 284.7L1198.9 279H1205.5L1208.8 284.7L1205.5 290.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 275.6H1198.7L1195.2 269.6L1198.7 263.6H1205.7L1209.2 269.6L1205.7 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 260.6H1198.7L1195.2 254.5L1198.7 248.5H1205.7L1209.2 254.5L1205.7 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 245.5H1198.7L1195.2 239.5L1198.7 233.4H1205.7L1209.2 239.5L1205.7 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.6 230.3H1198.8L1195.4 224.4L1198.8 218.5H1205.6L1209 224.4L1205.6 230.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1204.5 213.3H1199.9L1197.6 209.3L1199.9 205.4H1204.5L1206.8 209.3L1204.5 213.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 200.3H1198.7L1195.2 194.2L1198.7 188.2H1205.7L1209.2 194.2L1205.7 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 185.2H1198.7L1195.2 179.2L1198.7 173.1H1205.7L1209.2 179.2L1205.7 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 170.1H1198.7L1195.2 164.1L1198.7 158.1H1205.7L1209.2 164.1L1205.7 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 155.1H1198.7L1195.2 149L1198.7 143H1205.7L1209.2 149L1205.7 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 140H1198.7L1195.2 134L1198.7 127.9H1205.7L1209.2 134L1205.7 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 124.9H1198.7L1195.2 118.9L1198.7 112.9H1205.7L1209.2 118.9L1205.7 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1205.7 109.9H1198.7L1195.2 103.8L1198.7 97.8H1205.7L1209.2 103.8L1205.7 109.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1192.6 102.3H1185.7L1182.2 96.3L1185.7 90.3H1192.6L1196.1 96.3L1192.6 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1177.8 91.7H1174.4L1172.7 88.8L1174.4 85.8H1177.8L1179.5 88.8L1177.8 91.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M341.4 14.3999H340.2L339.6 13.3999L340.2 12.3999H341.4L342 13.3999L341.4 14.3999Z'
          fill='#DFDFDF'
        />
        <path
          d='M328.3 21.9999H327.2L326.6 20.8999L327.2 19.8999H328.3L328.9 20.8999L328.3 21.9999Z'
          fill='#DFDFDF'
        />
        <path
          d='M263.8 60.9001H261.2L259.8 58.6L261.2 56.3H263.8L265.1 58.6L263.8 60.9001Z'
          fill='#DFDFDF'
        />
        <path
          d='M250.3 67.6H248.6L247.7 66.1L248.6 64.7H250.3L251.1 66.1L250.3 67.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M237.9 76.3001H234.9L233.3 73.7001L234.9 71.1001H237.9L239.4 73.7001L237.9 76.3001Z'
          fill='#DFDFDF'
        />
        <path
          d='M226.7 86.9999H220L216.7 81.1999L220 75.3999H226.7L230 81.1999L226.7 86.9999Z'
          fill='#DFDFDF'
        />
        <path
          d='M213.8 94.8H206.8L203.3 88.8L206.8 82.7H213.8L217.2 88.8L213.8 94.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M200.7 102.3H193.7L190.3 96.3L193.7 90.3H200.7L204.2 96.3L200.7 102.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M186.2 122.4H182.2L180.1 118.9L182.2 115.4H186.2L188.2 118.9L186.2 122.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 140H180.7L177.2 134L180.7 127.9H187.7L191.1 134L187.7 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 155.1H180.7L177.2 149L180.7 143H187.7L191.1 149L187.7 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 170.1H180.7L177.2 164.1L180.7 158.1H187.7L191.1 164.1L187.7 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 185.2H180.7L177.2 179.2L180.7 173.1H187.7L191.1 179.2L187.7 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 200.3H180.7L177.2 194.2L180.7 188.2H187.7L191.1 194.2L187.7 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 215.3H180.7L177.2 209.3L180.7 203.3H187.7L191.1 209.3L187.7 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 230.4H180.7L177.2 224.4L180.7 218.4H187.7L191.1 224.4L187.7 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 245.5H180.7L177.2 239.5L180.7 233.4H187.7L191.1 239.5L187.7 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 260.6H180.7L177.2 254.5L180.7 248.5H187.7L191.1 254.5L187.7 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 275.6H180.7L177.2 269.6L180.7 263.6H187.7L191.1 269.6L187.7 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 290.7H180.7L177.2 284.7L180.7 278.6H187.7L191.1 284.7L187.7 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 305.8H180.7L177.2 299.7L180.7 293.7H187.7L191.1 299.7L187.7 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 320.8H180.7L177.2 314.8L180.7 308.8H187.7L191.1 314.8L187.7 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 335.9H180.7L177.2 329.9L180.7 323.9H187.7L191.1 329.9L187.7 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.7 351H180.7L177.2 345L180.7 338.9H187.7L191.1 345L187.7 351Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.6 365.9H180.8L177.4 360L180.8 354.1H187.6L191 360L187.6 365.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M187.2 380.3H181.2L178.2 375.1L181.2 369.9H187.2L190.1 375.1L187.2 380.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M186.7 394.6H181.6L179 390.2L181.6 385.7H186.7L189.3 390.2L186.7 394.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1216.6 686.4H1213.9L1212.6 684L1213.9 681.7H1216.6L1217.9 684L1216.6 686.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 675H1211.8L1208.3 669L1211.8 663H1218.7L1222.2 669L1218.7 675Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 659.9H1211.8L1208.3 653.9L1211.8 647.9H1218.7L1222.2 653.9L1218.7 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 644.9H1211.8L1208.3 638.8L1211.8 632.8H1218.7L1222.2 638.8L1218.7 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 629.8H1211.8L1208.3 623.8L1211.8 617.7H1218.7L1222.2 623.8L1218.7 629.8Z'
          fill='#57546c'
          className='fadeAnimation'
          id='fadeAnimation-tenth'
        />
        <path
          d='M1218.7 614.7H1211.8L1208.3 608.7L1211.8 602.7H1218.7L1222.2 608.7L1218.7 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 599.7H1211.8L1208.3 593.6L1211.8 587.6H1218.7L1222.2 593.6L1218.7 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 584.6H1211.8L1208.3 578.6L1211.8 572.5H1218.7L1222.2 578.6L1218.7 584.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1216.9 566.3H1213.6L1212 563.5L1213.6 560.7H1216.9L1218.5 563.5L1216.9 566.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1217.2 521.6H1213.3L1211.4 518.3L1213.3 514.9H1217.2L1219.1 518.3L1217.2 521.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.6 508.9H1211.9L1208.6 503.2L1211.9 497.5H1218.6L1221.9 503.2L1218.6 508.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.5 343H1212L1208.8 337.4L1212 331.8H1218.5L1221.7 337.4L1218.5 343Z'
          fill='#DFDFDF'
        />
        <path
          d='M1215.9 278.3H1214.6L1213.9 277.1L1214.6 276H1215.9L1216.6 277.1L1215.9 278.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 268H1211.8L1208.4 262.1L1211.8 256.1H1218.7L1222.2 262.1L1218.7 268Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 253H1211.8L1208.3 247L1211.8 241H1218.7L1222.2 247L1218.7 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 237.9H1211.8L1208.4 231.9L1211.8 226H1218.7L1222.1 231.9L1218.7 237.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1216.7 219.4H1213.8L1212.3 216.9L1213.8 214.3H1216.7L1218.2 216.9L1216.7 219.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.6 192.5H1211.9L1208.5 186.7L1211.9 180.9H1218.6L1222 186.7L1218.6 192.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 177.7H1211.8L1208.3 171.6L1211.8 165.6H1218.7L1222.2 171.6L1218.7 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 162.6H1211.8L1208.3 156.6L1211.8 150.5H1218.7L1222.2 156.6L1218.7 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 147.5H1211.8L1208.3 141.5L1211.8 135.5H1218.7L1222.2 141.5L1218.7 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 132.5H1211.8L1208.3 126.4L1211.8 120.4H1218.7L1222.2 126.4L1218.7 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1218.7 117.4H1211.8L1208.3 111.4L1211.8 105.3H1218.7L1222.2 111.4L1218.7 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1179.2 79H1173L1169.9 73.7L1173 68.3H1179.2L1182.3 73.7L1179.2 79Z'
          fill='#DFDFDF'
        />
        <path
          d='M1165.8 70.8999H1160.3L1157.5 66.0999L1160.3 61.3999H1165.8L1168.6 66.0999L1165.8 70.8999Z'
          fill='#DFDFDF'
        />
        <path
          d='M211.5 75.8H209L207.8 73.7L209 71.5H211.5L212.8 73.7L211.5 75.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M199 84.2H195.5L193.7 81.2L195.5 78.2H199L200.7 81.2L199 84.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 132.5H167.6L164.2 126.4L167.6 120.4H174.6L178.1 126.4L174.6 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 147.5H167.6L164.2 141.5L167.6 135.5H174.6L178.1 141.5L174.6 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 162.6H167.6L164.2 156.6L167.6 150.5H174.6L178.1 156.6L174.6 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 177.7H167.6L164.2 171.6L167.6 165.6H174.6L178.1 171.6L174.6 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 192.7H167.6L164.2 186.7L167.6 180.7H174.6L178.1 186.7L174.6 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 207.8H167.6L164.2 201.8L167.6 195.8H174.6L178.1 201.8L174.6 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 222.9H167.6L164.2 216.9L167.6 210.8H174.6L178.1 216.9L174.6 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 238H167.6L164.2 231.9L167.6 225.9H174.6L178.1 231.9L174.6 238Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 253H167.6L164.2 247L167.6 241H174.6L178.1 247L174.6 253Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 268.1H167.6L164.2 262.1L167.6 256H174.6L178.1 262.1L174.6 268.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 283.2H167.6L164.2 277.1L167.6 271.1H174.6L178.1 277.1L174.6 283.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 298.2H167.6L164.2 292.2L167.6 286.2H174.6L178.1 292.2L174.6 298.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 313.3H167.6L164.2 307.3L167.6 301.3H174.6L178.1 307.3L174.6 313.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 328.4H167.6L164.2 322.4L167.6 316.3H174.6L178.1 322.4L174.6 328.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 343.4H167.6L164.2 337.4L167.6 331.4H174.6L178.1 337.4L174.6 343.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.6 358.5H167.6L164.2 352.5L167.6 346.5H174.6L178.1 352.5L174.6 358.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M174.4 373.2H167.9L164.6 367.6L167.9 361.9H174.4L177.6 367.6L174.4 373.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1229.3 693.3H1227.3L1226.4 691.6L1227.3 689.9H1229.3L1230.2 691.6L1229.3 693.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 682.5H1224.8L1221.3 676.5L1224.8 670.5H1231.8L1235.3 676.5L1231.8 682.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 667.5H1224.8L1221.3 661.4L1224.8 655.4H1231.8L1235.3 661.4L1231.8 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 652.4H1224.8L1221.3 646.4L1224.8 640.3H1231.8L1235.3 646.4L1231.8 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 637.3H1224.8L1221.3 631.3L1224.8 625.3H1231.8L1235.3 631.3L1231.8 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 622.3H1224.8L1221.3 616.2L1224.8 610.2H1231.8L1235.3 616.2L1231.8 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 607.2H1224.8L1221.3 601.2L1224.8 595.1H1231.8L1235.3 601.2L1231.8 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 592.1H1224.8L1221.3 586.1L1224.8 580.1H1231.8L1235.3 586.1L1231.8 592.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 577H1224.8L1221.3 571L1224.8 565H1231.8L1235.3 571L1231.8 577Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 335.9H1224.8L1221.3 329.9L1224.8 323.9H1231.8L1235.3 329.9L1231.8 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1230.1 287.8H1226.5L1224.7 284.7L1226.5 281.5H1230.1L1231.9 284.7L1230.1 287.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1229.4 256.4H1227.2L1226.1 254.5L1227.2 252.6H1229.4L1230.5 254.5L1229.4 256.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1230.5 243.3H1226.1L1223.8 239.5L1226.1 235.6H1230.5L1232.8 239.5L1230.5 243.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1230.9 229H1225.7L1223 224.4L1225.7 219.8H1230.9L1233.6 224.4L1230.9 229Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 185.2H1224.8L1221.3 179.2L1224.8 173.1H1231.8L1235.3 179.2L1231.8 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 170.1H1224.8L1221.3 164.1L1224.8 158.1H1231.8L1235.3 164.1L1231.8 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 155.1H1224.8L1221.3 149L1224.8 143H1231.8L1235.3 149L1231.8 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 140H1224.8L1221.3 134L1224.8 127.9H1231.8L1235.3 134L1231.8 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1231.8 124.9H1224.8L1221.3 118.9L1224.8 112.9H1231.8L1235.3 118.9L1231.8 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1229.2 105.4H1227.4L1226.5 103.8L1227.4 102.3H1229.2L1230.1 103.8L1229.2 105.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M160.3 122.8H155.8L153.6 118.9L155.8 115H160.3L162.6 118.9L160.3 122.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 140H154.6L151.1 134L154.6 127.9H161.5L165 134L161.5 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 155.1H154.6L151.1 149L154.6 143H161.5L165 149L161.5 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 170.1H154.6L151.1 164.1L154.6 158.1H161.5L165 164.1L161.5 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 185.2H154.6L151.1 179.2L154.6 173.1H161.5L165 179.2L161.5 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 200.3H154.6L151.1 194.2L154.6 188.2H161.5L165 194.2L161.5 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 215.3H154.6L151.1 209.3L154.6 203.3H161.5L165 209.3L161.5 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 230.4H154.6L151.1 224.4L154.6 218.4H161.5L165 224.4L161.5 230.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 245.5H154.6L151.1 239.5L154.6 233.4H161.5L165 239.5L161.5 245.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 260.6H154.6L151.1 254.5L154.6 248.5H161.5L165 254.5L161.5 260.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 275.6H154.6L151.1 269.6L154.6 263.6H161.5L165 269.6L161.5 275.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 290.7H154.6L151.1 284.7L154.6 278.6H161.5L165 284.7L161.5 290.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 305.8H154.6L151.1 299.7L154.6 293.7H161.5L165 299.7L161.5 305.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 320.8H154.6L151.1 314.8L154.6 308.8H161.5L165 314.8L161.5 320.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M161.5 335.9H154.6L151.1 329.9L154.6 323.9H161.5L165 329.9L161.5 335.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M159.1 346.8H157L155.9 345L157 343.1H159.1L160.2 345L159.1 346.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1243.5 718H1239.2L1237 714.2L1239.2 710.4H1243.5L1245.7 714.2L1243.5 718Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 705.1H1237.9L1234.4 699.1L1237.9 693.1H1244.8L1248.3 699.1L1244.8 705.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 690.1H1237.9L1234.4 684L1237.9 678H1244.8L1248.3 684L1244.8 690.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 675H1237.9L1234.4 669L1237.9 663H1244.8L1248.3 669L1244.8 675Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 659.9H1237.9L1234.4 653.9L1237.9 647.9H1244.8L1248.3 653.9L1244.8 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 644.9H1237.9L1234.4 638.8L1237.9 632.8H1244.8L1248.3 638.8L1244.8 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 614.7H1237.9L1234.4 608.7L1237.9 602.7H1244.8L1248.3 608.7L1244.8 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 599.7H1237.9L1234.4 593.6L1237.9 587.6H1244.8L1248.3 593.6L1244.8 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1242.2 534.8H1240.5L1239.7 533.3L1240.5 531.9H1242.2L1243 533.3L1242.2 534.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 524.3H1237.9L1234.4 518.3L1237.9 512.2H1244.8L1248.3 518.3L1244.8 524.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.4 327.7H1238.3L1235.2 322.4L1238.3 317H1244.4L1247.5 322.4L1244.4 327.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1243.4 310.8H1239.3L1237.3 307.3L1239.3 303.8H1243.4L1245.4 307.3L1243.4 310.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1242.6 294.3H1240.2L1239 292.2L1240.2 290.1H1242.6L1243.8 292.2L1242.6 294.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.5 282.6H1238.2L1235.1 277.1L1238.2 271.7H1244.5L1247.6 277.1L1244.5 282.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1242.8 249.5H1239.9L1238.5 247L1239.9 244.5H1242.8L1244.2 247L1242.8 249.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.7 192.5H1238L1234.6 186.7L1238 180.9H1244.7L1248.1 186.7L1244.7 192.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 177.7H1237.9L1234.4 171.6L1237.9 165.6H1244.8L1248.3 171.6L1244.8 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 162.6H1237.9L1234.4 156.6L1237.9 150.5H1244.8L1248.3 156.6L1244.8 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 147.5H1237.9L1234.4 141.5L1237.9 135.5H1244.8L1248.3 141.5L1244.8 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 132.5H1237.9L1234.4 126.4L1237.9 120.4H1244.8L1248.3 126.4L1244.8 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1244.8 117.4H1237.9L1234.4 111.4L1237.9 105.3H1244.8L1248.3 111.4L1244.8 117.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M148.5 132.5H141.5L138.1 126.4L141.5 120.4H148.5L152 126.4L148.5 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M148.5 147.5H141.5L138.1 141.5L141.5 135.5H148.5L152 141.5L148.5 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M148.5 162.6H141.5L138.1 156.6L141.5 150.5H148.5L152 156.6L148.5 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M148.5 177.7H141.5L138.1 171.6L141.5 165.6H148.5L152 171.6L148.5 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M148.5 192.7H141.5L138.1 186.7L141.5 180.7H148.5L152 186.7L148.5 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M148.5 207.8H141.5L138.1 201.8L141.5 195.8H148.5L152 201.8L148.5 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M148.3 222.6H141.7L138.4 216.9L141.7 211.1H148.3L151.6 216.9L148.3 222.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M147.6 236.3H142.5L139.9 231.9L142.5 227.5H147.6L150.1 231.9L147.6 236.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M146.3 249.2H143.8L142.5 247L143.8 244.8H146.3L147.5 247L146.3 249.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M145.8 263.4H144.3L143.5 262.1L144.3 260.8H145.8L146.5 262.1L145.8 263.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M146.1 279.1H143.9L142.7 277.1L143.9 275.2H146.1L147.3 277.1L146.1 279.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M146.3 294.4H143.8L142.5 292.2L143.8 290H146.3L147.5 292.2L146.3 294.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M147.5 311.7H142.5L140 307.3L142.5 302.9H147.5L150.1 307.3L147.5 311.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M146.3 324.5H143.8L142.5 322.4L143.8 320.2H146.3L147.5 322.4L146.3 324.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 727.7H1250.9L1247.5 721.7L1250.9 715.7H1257.9L1261.3 721.7L1257.9 727.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1256.1 709.6H1252.7L1251 706.7L1252.7 703.7H1256.1L1257.8 706.7L1256.1 709.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 697.6H1250.9L1247.5 691.6L1250.9 685.6H1257.9L1261.4 691.6L1257.9 697.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 682.5H1250.9L1247.5 676.5L1250.9 670.5H1257.9L1261.4 676.5L1257.9 682.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 667.5H1250.9L1247.5 661.4L1250.9 655.4H1257.9L1261.4 661.4L1257.9 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 652.4H1250.9L1247.5 646.4L1250.9 640.3H1257.9L1261.4 646.4L1257.9 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 637.3H1250.9L1247.5 631.3L1250.9 625.3H1257.9L1261.4 631.3L1257.9 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 622.3H1250.9L1247.5 616.2L1250.9 610.2H1257.9L1261.4 616.2L1257.9 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 607.2H1250.9L1247.5 601.2L1250.9 595.1H1257.9L1261.4 601.2L1257.9 607.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1255.8 588.5H1253L1251.7 586.1L1253 583.7H1255.8L1257.2 586.1L1255.8 588.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.8 546.7H1251L1247.7 540.9L1251 535H1257.8L1261.2 540.9L1257.8 546.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 531.8H1250.9L1247.5 525.8L1250.9 519.8H1257.9L1261.4 525.8L1257.9 531.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1255.2 512.2H1253.6L1252.7 510.7L1253.6 509.3H1255.2L1256.1 510.7L1255.2 512.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1256.6 198.1H1252.2L1250 194.2L1252.2 190.4H1256.6L1258.8 194.2L1256.6 198.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 185.2H1250.9L1247.5 179.2L1250.9 173.1H1257.9L1261.4 179.2L1257.9 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 170.1H1250.9L1247.5 164.1L1250.9 158.1H1257.9L1261.4 164.1L1257.9 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 155.1H1250.9L1247.5 149L1250.9 143H1257.9L1261.4 149L1257.9 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.9 140H1250.9L1247.5 134L1250.9 127.9H1257.9L1261.4 134L1257.9 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1257.8 124.8H1251L1247.6 118.9L1251 113H1257.8L1261.2 118.9L1257.8 124.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M134.5 123.2H129.5L127 118.9L129.5 114.6H134.5L137 118.9L134.5 123.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M135.4 140H128.5L125 134L128.5 127.9H135.4L138.9 134L135.4 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M135.4 155.1H128.5L125 149L128.5 143H135.4L138.9 149L135.4 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M135.4 170.1H128.5L125 164.1L128.5 158.1H135.4L138.9 164.1L135.4 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M135.4 185.2H128.5L125 179.2L128.5 173.1H135.4L138.9 179.2L135.4 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M135.4 200.3H128.5L125 194.2L128.5 188.2H135.4L138.9 194.2L135.4 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M134.7 214H129.2L126.5 209.3L129.2 204.6H134.7L137.4 209.3L134.7 214Z'
          fill='#DFDFDF'
        />
        <path
          d='M1268.3 715.6H1266.7L1265.9 714.2L1266.7 712.8H1268.3L1269.1 714.2L1268.3 715.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.8 704.8H1264.2L1260.9 699.1L1264.2 693.4H1270.8L1274 699.1L1270.8 704.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 690.1H1264L1260.5 684L1264 678H1270.9L1274.4 684L1270.9 690.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 675H1264L1260.5 669L1264 663H1270.9L1274.4 669L1270.9 675Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 659.9H1264L1260.5 653.9L1264 647.9H1270.9L1274.4 653.9L1270.9 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 644.9H1264L1260.5 638.8L1264 632.8H1270.9L1274.4 638.8L1270.9 644.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 629.8H1264L1260.5 623.8L1264 617.7H1270.9L1274.4 623.8L1270.9 629.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 614.7H1264L1260.5 608.7L1264 602.7H1270.9L1274.4 608.7L1270.9 614.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 599.7H1264L1260.5 593.6L1264 587.6H1270.9L1274.4 593.6L1270.9 599.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.6 583.9H1264.4L1261.2 578.6L1264.4 573.2H1270.6L1273.7 578.6L1270.6 583.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1268.6 565.4H1266.3L1265.2 563.5L1266.3 561.5H1268.6L1269.7 563.5L1268.6 565.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.7 554H1264.3L1261 548.4L1264.3 542.9H1270.7L1273.9 548.4L1270.7 554Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 539.4H1264L1260.5 533.3L1264 527.3H1270.9L1274.4 533.3L1270.9 539.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 177.6H1264L1260.5 171.6L1264 165.6H1270.9L1274.4 171.6L1270.9 177.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 162.6H1264L1260.5 156.6L1264 150.5H1270.9L1274.4 156.6L1270.9 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 147.5H1264L1260.5 141.5L1264 135.5H1270.9L1274.4 141.5L1270.9 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1270.9 132.5H1264L1260.5 126.4L1264 120.4H1270.9L1274.4 126.4L1270.9 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M119.6 112.5H118.3L117.6 111.4L118.3 110.2H119.6L120.2 111.4L119.6 112.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M122.4 132.5H115.4L112 126.4L115.4 120.4H122.4L125.9 126.4L122.4 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M122.4 147.5H115.4L112 141.5L115.4 135.5H122.4L125.9 141.5L122.4 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M122.4 162.6H115.4L112 156.6L115.4 150.5H122.4L125.9 156.6L122.4 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M122.4 177.7H115.4L112 171.6L115.4 165.6H122.4L125.9 171.6L122.4 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M122.4 192.7H115.4L112 186.7L115.4 180.7H122.4L125.9 186.7L122.4 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M121.4 206.1H116.4L113.9 201.8L116.4 197.5H121.4L123.9 201.8L121.4 206.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1282.6 695.2H1278.4L1276.3 691.6L1278.4 688H1282.6L1284.7 691.6L1282.6 695.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1284 682.5H1277L1273.6 676.5L1277 670.5H1284L1287.5 676.5L1284 682.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1284 667.5H1277L1273.6 661.4L1277 655.4H1284L1287.5 661.4L1284 667.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1284 652.4H1277L1273.6 646.4L1277 640.3H1284L1287.5 646.4L1284 652.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1284 637.3H1277L1273.6 631.3L1277 625.3H1284L1287.5 631.3L1284 637.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1284 622.3H1277L1273.6 616.2L1277 610.2H1284L1287.5 616.2L1284 622.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1283.5 606.4H1277.5L1274.5 601.2L1277.5 595.9H1283.5L1286.5 601.2L1283.5 606.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1281.2 240.6H1279.9L1279.2 239.5L1279.9 238.3H1281.2L1281.8 239.5L1281.2 240.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1283.7 230H1277.3L1274 224.4L1277.3 218.8H1283.7L1287 224.4L1283.7 230Z'
          fill='#DFDFDF'
        />
        <path
          d='M1284 215.3H1277L1273.6 209.3L1277 203.3H1284L1287.5 209.3L1284 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1283.9 200.1H1277.1L1273.8 194.2L1277.1 188.4H1283.9L1287.3 194.2L1283.9 200.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1282.2 182.1H1278.8L1277.2 179.2L1278.8 176.3H1282.2L1283.9 179.2L1282.2 182.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1284 170.1H1277L1273.6 164.1L1277 158.1H1284L1287.5 164.1L1284 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1284 155.1H1277L1273.6 149L1277 143H1284L1287.5 149L1284 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1284 140H1277L1273.6 134L1277 127.9H1284L1287.5 134L1284 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1283.9 124.7H1277.2L1273.8 118.9L1277.2 113.1H1283.9L1287.2 118.9L1283.9 124.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M109.3 124.9H102.4L98.9004 118.9L102.4 112.9H109.3L112.8 118.9L109.3 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M109.3 140H102.4L98.9004 134L102.4 127.9H109.3L112.8 134L109.3 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M109.3 155.1H102.4L98.9004 149L102.4 143H109.3L112.8 149L109.3 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M109.3 170.1H102.4L98.9004 164.1L102.4 158.1H109.3L112.8 164.1L109.3 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M109.3 185.2H102.4L98.9004 179.2L102.4 173.1H109.3L112.8 179.2L109.3 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M106.8 195.8H105L104.1 194.2L105 192.7H106.8L107.7 194.2L106.8 195.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M1296.2 673.6H1290.9L1288.3 669L1290.9 664.4H1296.2L1298.9 669L1296.2 673.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1297 659.9H1290.1L1286.6 653.9L1290.1 647.9H1297L1300.5 653.9L1297 659.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1297 644.7H1290.2L1286.7 638.8L1290.2 632.9H1297L1300.4 638.8L1297 644.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1295.9 627.7H1291.3L1289 623.8L1291.3 619.8H1295.9L1298.2 623.8L1295.9 627.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1294.9 550.7H1292.2L1290.9 548.4L1292.2 546.1H1294.9L1296.2 548.4L1294.9 550.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1296.9 222.6H1290.3L1287 216.9L1290.3 211.1H1296.9L1300.2 216.9L1296.9 222.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1297 207.7H1290.2L1286.8 201.8L1290.2 195.9H1297L1300.4 201.8L1297 207.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1297 192.7H1290.1L1286.7 186.7L1290.1 180.8H1297L1300.4 186.7L1297 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1297 177.7H1290.1L1286.6 171.6L1290.1 165.6H1297L1300.5 171.6L1297 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1297 162.6H1290.1L1286.6 156.6L1290.1 150.5H1297L1300.5 156.6L1297 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1297 147.5H1290.1L1286.6 141.5L1290.1 135.5H1297L1300.5 141.5L1297 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1297 132.5H1290.1L1286.6 126.4L1290.1 120.4H1297L1300.5 126.4L1297 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1295.1 114H1292L1290.5 111.4L1292 108.7H1295.1L1296.6 111.4L1295.1 114Z'
          fill='#DFDFDF'
        />
        <path
          d='M94.6002 114.5H91.0002L89.2002 111.4L91.0002 108.2H94.6002L96.5002 111.4L94.6002 114.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M96.2998 132.5H89.2998L85.7998 126.4L89.2998 120.4H96.2998L99.7998 126.4L96.2998 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M96.2998 147.5H89.2998L85.7998 141.5L89.2998 135.5H96.2998L99.7998 141.5L96.2998 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M96.2998 162.6H89.2998L85.7998 156.6L89.2998 150.5H96.2998L99.7998 156.6L96.2998 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M96.2998 177.7H89.2998L85.7998 171.6L89.2998 165.6H96.2998L99.7998 171.6L96.2998 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M96.2998 192.7H89.2998L85.7998 186.7L89.2998 180.7H96.2998L99.7998 186.7L96.2998 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1307.9 618.5H1305.3L1304.1 616.2L1305.3 614H1307.9L1309.2 616.2L1307.9 618.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1307.5 602.7H1305.7L1304.8 601.2L1305.7 599.6H1307.5L1308.4 601.2L1307.5 602.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1310.1 185.2H1303.2L1299.7 179.2L1303.2 173.2H1310.1L1313.6 179.2L1310.1 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1310.1 170.1H1303.1L1299.7 164.1L1303.1 158.1H1310.1L1313.6 164.1L1310.1 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1310.1 155.1H1303.1L1299.7 149L1303.1 143H1310.1L1313.6 149L1310.1 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1310.1 140H1303.1L1299.7 134L1303.1 127.9H1310.1L1313.6 134L1310.1 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1310.1 124.9H1303.2L1299.7 118.9L1303.2 112.9H1310.1L1313.6 118.9L1310.1 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M80.5996 105.2H78.9996L78.0996 103.8L78.9996 102.4H80.5996L81.3996 103.8L80.5996 105.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M83.1998 124.9H76.2998L72.7998 118.9L76.2998 112.9H83.1998L86.6998 118.9L83.1998 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M83.1998 140H76.2998L72.7998 134L76.2998 127.9H83.1998L86.6998 134L83.1998 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M83.1998 155.1H76.2998L72.7998 149L76.2998 143H83.1998L86.6998 149L83.1998 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M83.1998 170.1H76.2998L72.7998 164.1L76.2998 158.1H83.1998L86.6998 164.1L83.1998 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M83.1998 185.2H76.2998L72.7998 179.2L76.2998 173.1H83.1998L86.6998 179.2L83.1998 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M83.1998 200.3H76.2998L72.7998 194.2L76.2998 188.2H83.1998L86.6998 194.2L83.1998 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M83.1998 215.3H76.2998L72.7998 209.3L76.2998 203.3H83.1998L86.6998 209.3L83.1998 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1320.7 188.5H1318.7L1317.7 186.7L1318.7 185H1320.7L1321.7 186.7L1320.7 188.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1323.2 177.7H1316.2L1312.7 171.6L1316.2 165.6H1323.2L1326.6 171.6L1323.2 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1323.2 162.6H1316.2L1312.7 156.6L1316.2 150.5H1323.2L1326.6 156.6L1323.2 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1323.2 147.5H1316.2L1312.7 141.5L1316.2 135.5H1323.2L1326.6 141.5L1323.2 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1323.2 132.5H1316.2L1312.7 126.4L1316.2 120.4H1323.2L1326.6 126.4L1323.2 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M69.6998 116.5H63.6998L60.7998 111.4L63.6998 106.2H69.6998L72.5998 111.4L69.6998 116.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M70.2002 132.5H63.2002L59.7002 126.4L63.2002 120.4H70.2002L73.7002 126.4L70.2002 132.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M70.2002 147.5H63.2002L59.7002 141.5L63.2002 135.5H70.2002L73.7002 141.5L70.2002 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M70.2002 162.6H63.2002L59.7002 156.6L63.2002 150.5H70.2002L73.7002 156.6L70.2002 162.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M70.2002 177.7H63.2002L59.7002 171.6L63.2002 165.6H70.2002L73.7002 171.6L70.2002 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M70.2002 192.7H63.2002L59.7002 186.7L63.2002 180.7H70.2002L73.7002 186.7L70.2002 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M70.0998 207.7H63.2998L59.7998 201.8L63.2998 195.8H70.0998L73.5998 201.8L70.0998 207.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M69.8998 222.4H63.4998L60.2998 216.9L63.4998 211.3H69.8998L73.0998 216.9L69.8998 222.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1333.3 753H1332.1L1331.5 751.9L1332.1 750.8H1333.3L1334 751.9L1333.3 753Z'
          fill='#DFDFDF'
        />
        <path
          d='M1336.2 170.1H1329.2L1325.8 164.1L1329.2 158.1H1336.2L1339.7 164.1L1336.2 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1336.2 155.1H1329.2L1325.8 149L1329.2 143H1336.2L1339.7 149L1336.2 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1336.2 140H1329.2L1325.8 134L1329.2 127.9H1336.2L1339.7 134L1336.2 140Z'
          fill='#DFDFDF'
        />
        <path
          d='M1333.5 120.3H1331.9L1331.1 118.9L1331.9 117.5H1333.5L1334.3 118.9L1333.5 120.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M57.1002 124.9H50.2002L46.7002 118.9L50.2002 112.9H57.1002L60.6002 118.9L57.1002 124.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M56.7 139.3H50.6L47.5 134L50.6 128.7H56.7L59.8 134L56.7 139.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M57.1002 155.1H50.2002L46.7002 149L50.2002 143H57.1002L60.6002 149L57.1002 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M57.1002 170.1H50.2002L46.7002 164.1L50.2002 158.1H57.1002L60.6002 164.1L57.1002 170.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M57.1002 185.2H50.2002L46.7002 179.2L50.2002 173.1H57.1002L60.6002 179.2L57.1002 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M57.1002 200.3H50.2002L46.7002 194.2L50.2002 188.2H57.1002L60.6002 194.2L57.1002 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M57.1002 215.3H50.2002L46.7002 209.3L50.2002 203.3H57.1002L60.6002 209.3L57.1002 215.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1349.1 750.1H1342.4L1339.1 744.3L1342.4 738.5H1349.1L1352.5 744.3L1349.1 750.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1346.9 731.2H1344.6L1343.5 729.3L1344.6 727.3H1346.9L1348.1 729.3L1346.9 731.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1346.8 173.3H1344.8L1343.8 171.6L1344.8 169.9H1346.8L1347.7 171.6L1346.8 173.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1347.9 160.2H1343.7L1341.5 156.6L1343.7 152.9H1347.9L1350 156.6L1347.9 160.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1349.3 147.5H1342.3L1338.8 141.5L1342.3 135.5H1349.3L1352.7 141.5L1349.3 147.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1348.6 131.3H1342.9L1340.1 126.4L1342.9 121.5H1348.6L1351.4 126.4L1348.6 131.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M41.2004 112.4H40.0004L39.4004 111.4L40.0004 110.3H41.2004L41.8004 111.4L41.2004 112.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M42.2004 129.2H39.0004L37.4004 126.4L39.0004 123.6H42.2004L43.9004 126.4L42.2004 129.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M43.9998 147.4H37.1998L33.7998 141.5L37.1998 135.6H43.9998L47.4998 141.5L43.9998 147.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M43.2996 161.3H37.8996L35.0996 156.6L37.8996 151.8H43.2996L46.0996 156.6L43.2996 161.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M44.0996 177.7H37.0996L33.5996 171.6L37.0996 165.6H44.0996L47.5996 171.6L44.0996 177.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M44.0996 192.7H37.0996L33.5996 186.7L37.0996 180.7H44.0996L47.5996 186.7L44.0996 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M44.0996 207.8H37.0996L33.5996 201.8L37.0996 195.8H44.0996L47.5996 201.8L44.0996 207.8Z'
          fill='#DFDFDF'
        />
        <path
          d='M44.1002 222.9H37.1002L33.7002 216.9L37.1002 210.8H44.1002L47.5002 216.9L44.1002 222.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1360.9 740.4H1356.8L1354.7 736.8L1356.8 733.2H1360.9L1362.9 736.8L1360.9 740.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M1362 727.3H1355.6L1352.4 721.7L1355.6 716.2H1362L1365.2 721.7L1362 727.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1361.2 153.2H1356.4L1354 149L1356.4 144.9H1361.2L1363.7 149L1361.2 153.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M1361.1 137.9H1356.6L1354.3 134L1356.6 130.1H1361.1L1363.3 134L1361.1 137.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M28.6998 135.9H26.3998L25.2998 134L26.3998 132H28.6998L29.7998 134L28.6998 135.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M30.9996 155.1H24.0996L20.5996 149L24.0996 143H30.9996L34.4996 149L30.9996 155.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M28.1004 165.1H27.0004L26.4004 164.1L27.0004 163.1H28.1004L28.7004 164.1L28.1004 165.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M30.9996 185.2H24.0996L20.5996 179.2L24.0996 173.1H30.9996L34.4996 179.2L30.9996 185.2Z'
          fill='#DFDFDF'
        />
        <path
          d='M30.9996 200.3H24.0996L20.5996 194.2L24.0996 188.2H30.9996L34.4996 194.2L30.9996 200.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M29.1004 212H26.0004L24.4004 209.3L26.0004 206.6H29.1004L30.7004 209.3L29.1004 212Z'
          fill='#DFDFDF'
        />
        <path
          d='M29.5002 227.7H25.6002L23.7002 224.4L25.6002 221.1H29.5002L31.4002 224.4L29.5002 227.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M30.3 244.3H24.8L22 239.5L24.8 234.7H30.3L33.1 239.5L30.3 244.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M1372.6 730.5H1371.2L1370.5 729.3L1371.2 728.1H1372.6L1373.3 729.3L1372.6 730.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1374 717.9H1369.7L1367.6 714.2L1369.7 710.5H1374L1376.2 714.2L1374 717.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1373 701.1H1370.7L1369.6 699.1L1370.7 697.2H1373L1374.1 699.1L1373 701.1Z'
          fill='#DFDFDF'
        />
        <path
          d='M1373.8 159.9H1370L1368 156.6L1370 153.2H1373.8L1375.7 156.6L1373.8 159.9Z'
          fill='#DFDFDF'
        />
        <path
          d='M1373.9 145H1369.9L1367.8 141.5L1369.9 138H1373.9L1375.9 141.5L1373.9 145Z'
          fill='#DFDFDF'
        />
        <path
          d='M16.6996 145.3H12.2996L10.0996 141.5L12.2996 137.7H16.6996L18.8996 141.5L16.6996 145.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M17.2 176.4H11.8L9 171.6L11.8 166.9H17.2L20 171.6L17.2 176.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M18 192.7H11L7.5 186.7L11 180.7H18L21.5 186.7L18 192.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M17.2 206.5H11.8L9 201.8L11.8 197.1H17.2L20 201.8L17.2 206.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M15.3004 233.3H13.7004L12.9004 231.9L13.7004 230.6H15.3004L16.1004 231.9L15.3004 233.3Z'
          fill='#DFDFDF'
        />
        <path
          d='M16.5998 250.6H12.3998L10.2998 247L12.3998 243.4H16.5998L18.6998 247L16.5998 250.6Z'
          fill='#DFDFDF'
        />
        <path
          d='M1387.8 711.7H1382L1379.1 706.7L1382 701.6H1387.8L1390.8 706.7L1387.8 711.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1388.4 697.5H1381.5L1378.1 691.6L1381.5 685.6H1388.4L1391.8 691.6L1388.4 697.5Z'
          fill='#DFDFDF'
        />
        <path
          d='M1385.6 677.7H1384.2L1383.6 676.5L1384.2 675.3H1385.6L1386.3 676.5L1385.6 677.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M2.2 180.4H0.7L0 179.2L0.7 177.9H2.2L2.9 179.2L2.2 180.4Z'
          fill='#DFDFDF'
        />
        <path
          d='M2.2 240.7H0.7L0 239.5L0.7 238.2H2.2L2.9 239.5L2.2 240.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M2.09961 255.7H0.799609L0.0996094 254.5L0.799609 253.4H2.09961L2.79961 254.5L2.09961 255.7Z'
          fill='#DFDFDF'
        />
        <path
          d='M1399 685.8H1397L1396 684L1397 682.3H1399L1400 684L1399 685.8Z'
          fill='#DFDFDF'
        />
        <defs>
          <filter
            id='filter0_d_2053_16922'
            x='1067.8'
            y='205.8'
            width='33.8994'
            height='32.1001'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={5} />
            <feGaussianBlur stdDeviation={5} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.266667 0 0 0 0 0.254902 0 0 0 0 0.811765 0 0 0 1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_2053_16922'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2053_16922'
              result='shape'
            />
          </filter>
          <filter
            id='filter1_d_2053_16922'
            x='975.4'
            y='105.9'
            width='35.9004'
            height={34}
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation='5.5' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.266667 0 0 0 0 0.254902 0 0 0 0 0.811765 0 0 0 1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_2053_16922'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2053_16922'
              result='shape'
            />
          </filter>
          <filter
            id='filter2_d_2053_16922'
            x='705.3'
            y={42}
            width={28}
            height='26.1001'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation='3.5' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.266667 0 0 0 0 0.254902 0 0 0 0 0.811765 0 0 0 1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_2053_16922'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2053_16922'
              result='shape'
            />
          </filter>
          <filter
            id='filter3_d_2053_16922'
            x='493.5'
            y={40}
            width='33.9004'
            height='32.1001'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={5} />
            <feGaussianBlur stdDeviation={5} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.266667 0 0 0 0 0.254902 0 0 0 0 0.811765 0 0 0 1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_2053_16922'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2053_16922'
              result='shape'
            />
          </filter>
          <filter
            id='filter4_d_2053_16922'
            x='1011.6'
            y='381.7'
            width='41.9004'
            height={40}
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={7} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.266667 0 0 0 0 0.254902 0 0 0 0 0.811765 0 0 0 1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_2053_16922'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2053_16922'
              result='shape'
            />
          </filter>
          <filter
            id='filter5_d_2053_16922'
            x={130}
            y='297.9'
            width='30.0996'
            height='28.8'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={5} />
            <feGaussianBlur stdDeviation={5} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.266667 0 0 0 0 0.254902 0 0 0 0 0.811765 0 0 0 1 0'
            />
            <feBlend 
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_2053_16922'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2053_16922'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
  )
}

export default HeroSvg