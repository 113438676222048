/** @format */

import React from "react";

const Features = () => {
  return (
    <section
      aria-labelledby='feature'
      id='feature'
      className='overflow-y-auto  lg:h-screen'>
      <div className='px-8 py-24 mx-auto lg:px-16 max-w-7xl md:px-12 xl:px-26 lg:flex'>
        <div className='lg:w-1/2'>
          <div className='top-0 pt-8 pb-16 lg:sticky w-full'>
            <div>
              <div className='lg:pr-24 md:pr-12'>
                <div>
                  <h3 className='text-2xl font-medium tracking-tight bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text sm:text-4xl font-head'>
                    Y24.io, Liquid ReStaking Protocol
                  </h3>
                  <p className='max-w-xl mt-4 text-lg tracking-tight text-gray-600'>
                    Permissionless Liquid Staking Token share passive income of
                    up to 86% APY!
                  </p>
                </div>
                <div className='flex flex-col items-center justify-center gap-3 mt-10 lg:flex-row lg:justify-start'>
                  <a
                    href='https://app.y24.io/'
                    target='_blank'
                    rel='noreferrer'
                    className='flex  w-48 items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
                    <button className='text-lg text-md font-para'>
                      Launch App
                    </button>
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-5 w-5 rotate-180'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M11 19l-7-7 7-7m8 14l-7-7 7-7'
                        />
                      </svg>
                    </span>
                  </a>
                  <a
                    href='https://y24-io.gitbook.io/y24/protocol-features'
                    rel='noreferrer'
                    target='_blank'
                    className='inline-flex items-center justify-center text-sm font-semibold text-black duration-200 hover:text-blue-500 focus:outline-none focus-visible:outline-gray-600'>
                    Learn more &nbsp; →
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='lg:w-1/2'>
          <div className='flex-shrink-0'>
            <div>
              <ul
                className='grid grid-cols-1 gap-12 mt-6 list-none lg:mt-0 lg:gap-24'
                role='list'>
                <li>
                  <div>
                    <div className='flex items-center justify-center w-12 h-12 text-black bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] rounded-xl'>
                      ❖
                    </div>
                    <h3 className='mt-5 text-lg font-medium leading-6 bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                      Liquid ReStaking Token (LRT)
                    </h3>
                  </div>
                  <div className='mt-2 text-base text-gray-500'>
                    Liquid staking introduces a novel avenue for generating
                    passive income from staked assets without the need for
                    locking them up. It serves as an alternative to traditional
                    staking models, where users are typically required to lock
                    their tokens to contribute to the security of proof-of-stake
                    blockchains.
                  </div>
                </li>
                <li>
                  <div>
                    <div className='flex items-center justify-center w-12 h-12 text-black bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] rounded-xl'>
                      ❖
                    </div>
                    <h3 className='mt-5 text-lg font-medium leading-6 bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                      Real World Assets (RWA’s)
                    </h3>
                  </div>
                  <div className='mt-2 text-base text-gray-500'>
                    Decentralized finance (DeFi) protocols, exemplified by
                    Centrifuge, are emulating the fundamental operations of Real
                    World Assets (RWA’s).
                  </div>
                </li>
                <li>
                  <div>
                    <div className='flex items-center justify-center w-12 h-12 text-black bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] rounded-xl'>
                      ❖
                    </div>
                    <h3 className='mt-5 text-lg font-medium leading-6 bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                      Leverage Staking
                    </h3>
                  </div>
                  <div className='mt-2 text-base text-gray-500'>
                    Y24 is embarking on a strategic partnership with BENQI, with
                    the shared goal of catalyzing the evolution of the sAVAX
                    ecosystem. sAVAX represents AVAX’s staking derivative,
                    delivered by BENQI, the foremost liquid staking and lending
                    protocol on the Avalanche network.
                  </div>
                </li>
                <li>
                  <div>
                    <div className='flex items-center justify-center w-12 h-12 text-black bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] rounded-xl'>
                      ❖
                    </div>
                    <h3 className='mt-5 text-lg font-medium leading-6 bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                      Lending Borrowing
                    </h3>
                  </div>
                  <div className='mt-2 text-base text-gray-500'>
                    Y24.io’s seamless Borrow/Lending utility is set to launch,
                    debuting with Aave as the exclusive provider. While we are
                    actively constructing our Leverage tool to accommodate
                    multiple providers competing for capital efficiency and risk
                    factors transparently on L1/L2, Aave, with its proven track
                    record and outstanding capital efficiency, is expected to
                    remain the primary leverage provider in the foreseeable
                    future. Despite the integration of new leverage providers,
                    Aave’s established excellence positions it prominently.
                  </div>
                </li>

                <li>
                  <div>
                    <div className='flex items-center justify-center w-12 h-12 text-black bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] rounded-xl'>
                      ❖
                    </div>
                    <h3 className='mt-5 text-lg font-medium leading-6 bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                      Bitcoin Staking
                    </h3>
                  </div>
                  <div className='mt-2 text-base text-gray-500'>
                    Bitcoin staking is a concept enabling Bitcoin holders to
                    stake their dormant bitcoins to enhance the security of
                    Proof-of-Stake (PoS) chains, while also earning yields. The
                    proposed Bitcoin staking protocol introduces a trustless
                    mechanism, allowing users to stake bitcoins without directly
                    connecting them to the PoS chain
                  </div>
                </li>

                <li>
                  <div>
                    <div className='flex items-center justify-center w-12 h-12 text-black bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] rounded-xl'>
                      ❖
                    </div>
                    <h3 className='mt-5 text-lg font-medium leading-6 bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                      Trenches Pools
                    </h3>
                  </div>
                  <div className='mt-2 text-base text-gray-500'>
                    In the Y24 proposed model, we constructed tranches featuring
                    diverse risk profiles mirroring the underlying exposure.
                    These tranches span from the least risky (AAA) super senior
                    at the pinnacle of the credit structure to the most
                    lucrative (BB) with higher potential rewards.
                  </div>
                </li>
                <li>
                  <div>
                    <div className='flex items-center justify-center w-12 h-12 text-black bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] rounded-xl'>
                      ❖
                    </div>
                    <h3 className='mt-5 text-lg font-medium leading-6 bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                      Y24 LRT Staking
                    </h3>
                  </div>
                  <div className='mt-2 text-base text-gray-500'>
                    Yield24 enables users to delegate their crypto holdings in
                    one of the staking pools available at Y24 to earn additional
                    rewards. Staking at Y24 is another feature of generating
                    passive income. Y24 holders will be able to participate in
                    rewards in one way.
                  </div>
                </li>
                <li>
                  <div>
                    <div className='flex items-center justify-center w-12 h-12 text-black bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] rounded-xl'>
                      ❖
                    </div>
                    <h3 className='mt-5 text-lg font-medium leading-6 bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                      Y24 Bridge
                    </h3>
                  </div>
                  <div className='mt-2 text-base text-gray-500'>
                    We understand the frustration of users having limited
                    access. Since the crypto community comes with unlimited
                    options for investment and innovation, Therefore, we believe
                    that the users must have no-boundary access to the entire
                    space. To implement this, we have partnered with a reputable
                    bridge to enable access.
                  </div>
                </li>
                <li>
                  <div>
                    <div className='flex items-center justify-center w-12 h-12 text-black bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] rounded-xl'>
                      ❖
                    </div>
                    <h3 className='mt-5 text-lg font-medium leading-6 bg-gradient-to-br from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
                      Governance
                    </h3>
                  </div>
                  <div className='mt-2 text-base text-gray-500'>
                    Yield24 operates under the governance of its community,
                    engaging in forum discussions and voting on proposals
                    through the Y24 Snapshot. Currently, proposals presented to
                    the Snapshot voting system by the CORE carry binding
                    authority if they secure passage with a quorum.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
