/** @format */

import React from "react";

const Section4 = () => {
  return (
    <div className='container section4 py-20 '>
      <div className='row'>
        <div className='col-md-6'>
          <h2 className='font-head bg-gradient-to-br  from-[#22212b] to-[#9d9d9e] text-transparent bg-clip-text'>
            SECURITY & AUDITS
          </h2>
          <p className='font-para'>
            Tranche is committed to high security standards. All smart contracts
            are independently reviewed by leading auditors and consultants.
            Contract code is publicly verifiable, and bug bounty programs are
            available for security professionals to report vulnerabilities.
          </p>
          <a
            href='https://blocksafu.com/audit/0x652000ba3c230d83279AEC84A49d41d706AFB0F1'
            target='_blank'
            rel='noreferrer'
            className='flex mt-10 w-full md:w-96 items-center space-x-3 py-3 px-6 font-bold relative i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center  transition-all duration-400 transform  cursor-pointer hover:shadow-lg   overflow-hidden  hover:scale-x-110 hover:scale-y-105  duration-300 ease-out'>
            <button className='text-lg text-md '>VIEW AUDIT REPORTS</button>
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5 rotate-180'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M11 19l-7-7 7-7m8 14l-7-7 7-7'
                />
              </svg>
            </span>
          </a>
        </div>
        <div className='col-md-6'>
          <img
            src='assets/AuditLanding.e4607022.png'
            className='w-8/12 img-fluid m-auto'
            alt=''
          />
        </div>
      </div>
      
    </div>
  );
};

export default Section4;
