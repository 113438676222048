/** @format */

import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <main className=' h-[70vh] w-full flex flex-col justify-center items-center '>
      <h1 className='text-9xl font-extrabold text-white tracking-widest'>
        404
      </h1>
      <div className='bg-[#000000] px-2 text-sm rounded rotate-12 absolute text-white'>
        Page Not Found
      </div>
      <button className='mt-5'>
        <Link
          to='/'
          className='relative inline-block text-sm font-medium text-[#ffffff] group active:text-orange-500 focus:outline-none focus:ring'>
          <span className='absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#000000] group-hover:translate-y-0 group-hover:translate-x-0' />
          <span className='relative block px-8 py-3 bg-[#1A2238] border border-current'>
            Go Home
          </span>
        </Link>
      </button>
    </main>
  );
};

export default PageNotFound;
