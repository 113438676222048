/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { IoMdArrowRoundForward } from "react-icons/io";

const cardData = [
  {
    id: 1,
    img1: "/assets/MIM.svg",
    title: "MIM",
    percent: "261.26%",
    img2: "assets/protocol.svg",
    name: "Yearn",
    price: "$0.22",
    currency: "(0.22 MIM)",
    button: "MIM",
  },
  {
    id: 2,
    img1: "assets/usd-coin-usdc-logo.svg",
    title: "USDC",
    percent: "24.14%",
    img2: "assets/compound.svg",
    name: "Compound",
    price: "$1,746.39",
    currency: "(1,746.54 USDC)",
    button: "USDC",
  },
  {
    id: 3,
    img1: "assets/DAILanding.d66b3012.svg",
    title: "DAI",
    percent: "20.54%",
    img2: "assets/aave.svg",
    name: "AAVE",
    price: "$29.24",
    currency: "(29.24 DAI)",
    button: "DAI",
  },
  {
    id: 4,
    img1: "assets/usd-coin-usdc-logo.svg",
    title: "USDC",
    percent: "20.50%",
    img2: "assets/protocol.svg",
    name: "Yearn",
    price: "$83.98",
    currency: "(83.99 USDC)",
    button: "USDC",
  },
  {
    id: 5,
    img1: "assets/DAILanding.d66b3012.svg",
    title: "DAI",
    percent: "13.93%",
    img2: "assets/compound.svg",
    name: "Compound",
    price: "$45,181.54",
    currency: "(45,185.48 DAI)",
    button: "DAI",
  },
  {
    id: 6,
    img1: "assets/usd-coin-usdc-logo.svg",
    title: "USDC",
    percent: "6.42%",
    img2: "assets/aave.svg",
    name: "AAVE",
    price: "$197.07",
    currency: "(197.08 USDC)",
    button: "USDC",
  },
];

const Finance = () => {
  return (
    <>
      <div className='finance_Sec'>
        <div className='container'>
          <div className='card_main'>
            <div className='row'>
              <div className='col-md-6 m-auto'>
                <h3 className='font-head'>Liquid Staking</h3>
                <p className='mt-3'>
                  Through BNB Liquid Staking, Yield24 facilitates the staking of
                  your BNB tokens using carefully chosen validator nodes.
                  Simultaneously, the protocol generates new yBNB tokens, which
                  are BEP-20 reward-earning tokens directly sent to your wallet.
                  These tokens operate through a daily rebasing mechanism,
                  ensuring that staking rewards are distributed to your wallet
                  consistently. In essence, your yBNB balance in your wallet
                  will increment daily to mirror the accruing staking rewards.
                </p>
                <div className='mt-2 text-base text-gray-700 font-bold'>
                  Y24 integrated 2 Liquid Staking Platforms and validators on
                  the BNB Chain.
                </div>
                <ul className='mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-700 font-semibold font-para sm:grid-cols-2 sm:gap-6'>
                  <li className='flex gap-x-3'>
                    <svg
                      className='h-6 w-5 flex-none text-black'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'>
                      <path
                        fillRule='evenodd'
                        d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                        clipRule='evenodd'
                      />
                    </svg>
                    Stader
                  </li>
                  <li className='flex gap-x-3'>
                    <svg
                      className='h-6 w-5 flex-none text-black'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      aria-hidden='true'>
                      <path
                        fillRule='evenodd'
                        d='M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z'
                        clipRule='evenodd'
                      />
                    </svg>
                    Ankr Liqudi Staking
                  </li>
                </ul>
              </div>
              <div className='col-md-6'>
                <video
                  className='h-[320px] w-full m-auto border-2  object-cover  rounded-xl'
                  controls={false}
                  autoPlay
                  loop
                  playsInline
                  muted>
                  <source
                    src='https://imgur.com/CuSrYqF.mp4'
                    type='video/mp4'
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            {/* <div className='d-flex justify-content-between tbl_head mt-4 font-head'>
              <div style={{ width: "18%" }}>
                <h4>ASSET</h4>
              </div>
              <div style={{ width: "18%" }}>
                <h4>NET APY</h4>
              </div>
              <div style={{ width: "18%" }}>
                <h4>PROTOCOL</h4>
              </div>
              <div style={{ width: "18%" }}>
                <h4>DEPOSITS</h4>
              </div>
              <div style={{ width: "18%" }}></div>
            </div> */}

            {/* {cardData.map(res => {
              return (
                <div
                  className='d-flex py-4 mt-4 hr laptop flex-wrap'
                  key={res.id}>
                  <div style={{ width: "20%" }}>
                    <div className='d-flex align-items-center gap-3 '>
                      <img src={res.img1} className='img1' alt='' />
                      <div className='row_item'>
                        <h1>{res.title}</h1>
                        <p>Tranche B</p>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: "20%" }}>
                    <div className='row_item'>
                      <h1>{res.percent}</h1>
                      <p>Variable</p>
                    </div>
                  </div>
                  <div style={{ width: "20%" }}>
                    <div className='d-flex items-center protocol'>
                      <span>{res.name}</span>
                    </div>
                  </div>
                  <div style={{ width: "18%" }}>
                    <div className='row_item'>
                      <h1>{res.price}</h1>
                      <p>{res.currency}</p>
                    </div>
                  </div>
                  <div style={{ width: "15%" }}>
                    <div className='btn_main'>
                      <Link to='#'>
                        Deposit {res.button}
                        <IoMdArrowRoundForward
                          color='rgb(0, 0, 0)'
                          className='icon'
                          size={16}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })} */}

            {/* for Mobile screen  */}
            {/* {cardData.map(res => {
              return (
                <div className='hr mobile py-3' key={res.id}>
                  <div className='d-flex justify-content-between  py-1 mt-3'>
                    <div className='d-flex align-items-center gap-3 '>
                      <img src={res.img1} className='img1' alt='' />
                      <div className='row_item'>
                        <h1>{res.title}</h1>
                      </div>
                    </div>
                    <div className='btn_main'>
                      <Link to='#'>
                        Deposit {res.button}
                        <IoMdArrowRoundForward
                          color='rgb(0, 0, 0)'
                          className='icon'
                          size={16}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between py-4'>
                    <div className='d-flex protocol'>
                      <span>{res.name}</span>
                    </div>
                    <div className='row_item'>
                      <h1>{res.percent}</h1>
                      <p>Variable</p>
                    </div>
                  </div>
                </div>
              );
            })} */}
            {/* for Mobile screen  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Finance;
