/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Base from "./components/layout/Base";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc, bscTestnet, mainnet } from "viem/chains";
import Farming from "./pages/Farming";
import PageNotFound from "./pages/PageNotFound";
import ReactGA from "react-ga";
import { useEffect } from "react";
import FarmingNew from "./pages/FarmingNew";
ReactGA.initialize("G-M6PT2CV84K");
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  const chains = [bsc, mainnet];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <BrowserRouter>
      <WagmiConfig config={wagmiConfig}>
        <Base>
          <Routes>
            <Route index path='/' element={<Home />} />
            <Route path='/airdrop' element={<FarmingNew />} />
            <Route path='/*' element={<PageNotFound />} />
          </Routes>
        </Base>
        <div className=' z-3  position-absolute'>
          <Web3Modal
            projectId={projectId}
            ethereumClient={ethereumClient}
            themeMode='light'
            themeVariables={{
              "--w3m-background": "#57546cca",
              "--w3m-font-family": "Inter",
              "--w3m-background-color": "#57546c",
              "--w3m-text-primary": "#57546c",
              "--w3m-logo-image-url":
                "https://y24imgapi.onrender.com/proxy-image/pMpInqA.png",
            }}
          />
        </div>{" "}
        <ToastContainer position='top-right' closeOnClick />
      </WagmiConfig>
    </BrowserRouter>
  );
}

export default App;
