/** @format */

import React, { useState } from "react";

const Faqs = () => {
  const faqData = [
    {
      question: "How do I earn points?",
      answer:
        "Accumulate points by completing required tasks and get 1 spins FREE if you want more SPINS need 0.1 BNB / 0.01 ETH / 32 DAI deposit. Additionally, invite other users to participate in our earning system and earn 20% of the Y24 tokens they accumulate through completing tasks.",
    },
    {
      question:
        "What is referral mechanism and how are the rewards distributed for the 20% earned by users that I invite?",
      answer:
        "If User A, whom you invited, successfully completes all assigned tasks and earns 50 Y24 tokens through spins, you will receive 10 Y24 tokens. However, it's important to note that you will not receive 20% of the 200 Y24 tokens that User A receives for inviting User B. If need more than one time SPIN user B must has to deposit 0.1 BNB for eligibility and user A will get 20% referral bonus on it.",
    },
    {
      question:
        "I deposited BNB and referred users, why did I not get any Y24 tokens?",
      answer:
        "You receive spins every 24 hours based on the amount of BNB or stablecoins you deposit. Spins provide the opportunity to earn Y24 tokens, and you acquire tokens through these spins rather than directly. For stablecoins, the spin calculation is determined by the BNB value at the time of deposit, ensuring a fair distribution. For example, if you deposit $26 in stablecoins when BNB is valued at $260, you earn 1 spin every 24 hours, and this doesn't change with future BNB price fluctuations. If you deposit 0.1 BNB, you will receive a spin 24 hours after the deposit.",
    },
    {
      question: "What is the point range you can earn from a single spin?",
      answer:
        "You can earn Y24 tokens within the range of 1 point to 50 points for each spin.",
    },
    {
      question: "How can you obtain an invitation code?",
      answer:
        "Upon completing the onboarding steps and successfully bridging to Y24, you will receive invite codes. Additionally, as you progress through certain levels, you will unlock more invites. Access your invites in the dashboard under the earning system.",
    },
    {
      question: "How long do I have to claim?",
      answer: "Upon redeeming your invite, you have a 7-day window to claim your airdrop. The redemption of Y24 can be executed in the middle of February.",
    },
    {
      question: "Is it possible to claim points from multiple wallet addresses?",
      answer: "If you are eligible on multiple addresses, you can claim by bridging any amount from each address. Simply disconnect your current address from Y24.io in your wallet and connect your other address. Note that you will not need to connect a new Twitter or Telegram account.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleItem = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className='pb-20'>
      <div className='px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl'>
        <div className='max-w-2xl mx-auto '>
          <h2 className='text-3xl font-bold leading-tight bg-gradient-to-br  from-[#27252e] to-[#9d9d9e] text-transparent bg-clip-text sm:text-xl font-head'>
            Frequently Asked Questions
          </h2>
        </div>
        <div className='max-w-3xl mx-auto mt-8 space-y-4 '>
          {faqData.map((item, index) => (
            <div
              key={index}
              className={`transition-all duration-200 faqs rounded-2xl shadow-lg cursor-pointer ${
                openIndex === index ? "open" : ""
              }`}>
              <button
                type='button'
                className='flex items-center justify-between w-full px-4 py-5 sm:p-6'
                onClick={() => toggleItem(index)}>
                <span className='flex text-lg font-semibold text-black text-start '>
                  {item.question}
                </span>
                <svg
                  className={`w-6 h-6 text-gray-400 ${
                    openIndex === index ? "rotate-180" : ""
                  }`}
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M19 9l-7 7-7-7'
                  />
                </svg>
              </button>
              <div
                className={`px-4 pb-5 sm:px-6 sm:pb-6 ${
                  openIndex === index ? "block" : "hidden"
                }`}>
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
